import React from 'react'
import { Box, Button, Group } from '@mantine/core'
import { Icon } from 'tabler-icons-react'

export interface IAction {
  buttonLabel: string | React.ReactNode
  isDisabled: boolean
  isHidden?: boolean
  callBack: (key?: string, object?: any) => void
  icon?: Icon
  displayOptionalText?: boolean
  optionalText?: React.ReactNode
}

export interface ITextOnly {
  displayWarning: boolean
  warningText: React.ReactNode
}

export interface IOption {
  replaceOption: { index: number; transform: (entry: string) => string }[]
  excludeOption: number[]
}

export interface ExcelDownloadButtonProps {
  currentPageDataUrl?: string
  allPageDataUrl?: string
  fileTitle: string
  getTranslatedHeader?: (rawHeader: string, headerIndex: number, extraOption: IOption) => string
  isPOC?: boolean
}

export interface IExcelDownloadButton extends ExcelDownloadButtonProps {
  excelButtonComponent: React.ComponentType<ExcelDownloadButtonProps>
}

export interface ExcelExportColumn {
  property: string
  headerLabel: string | ((match: string) => string)
  formatter?: (value: string) => string
}

export interface ExcelExportGroupedColumn {
  propertyPrefix: string
  columns: ExcelExportColumn[]
}

export interface ExcelDownloadButton2Props {
  currentPageDataUrl?: string
  allDataUrl?: string
  fileTitle: string
  exportStructure: (ExcelExportColumn | ExcelExportGroupedColumn)[]
  exportAllDataStructure?: (ExcelExportColumn | ExcelExportGroupedColumn)[]
}

export interface IExcelDownloadButton2 extends ExcelDownloadButton2Props {
  excelButtonComponent2: React.ComponentType<ExcelDownloadButton2Props>
}

export interface IActionButton {
  actions: (IAction | ITextOnly | IExcelDownloadButton | IExcelDownloadButton2)[]
}

const ActionButtonInline = ({ actions }: IActionButton) => {
  return (
    <Group spacing="xs">
      {actions.map((action, index: number) => {
        if ('excelButtonComponent' in action) {
          const {
            excelButtonComponent: ExcelButtonComp,
            currentPageDataUrl,
            allPageDataUrl,
            fileTitle,
            getTranslatedHeader,
            isPOC,
          } = action
          return (
            <ExcelButtonComp
              key={`${index.toString()}_button`}
              currentPageDataUrl={currentPageDataUrl}
              allPageDataUrl={allPageDataUrl}
              fileTitle={fileTitle}
              getTranslatedHeader={getTranslatedHeader}
              isPOC={isPOC}
            />
          )
        }

        if ('excelButtonComponent2' in action) {
          const {
            excelButtonComponent2: ExcelButtonComp,
            currentPageDataUrl,
            allDataUrl,
            fileTitle,
            exportStructure,
            exportAllDataStructure,
          } = action
          return (
            <ExcelButtonComp
              key={`${index.toString()}_button`}
              currentPageDataUrl={currentPageDataUrl}
              fileTitle={fileTitle}
              exportStructure={exportStructure}
              allDataUrl={allDataUrl}
              exportAllDataStructure={exportAllDataStructure}
            />
          )
        }

        if ('displayWarning' in action) {
          return (
            <Box
              key={`${index.toString()}_button`}
              {...(action.displayWarning && { sx: { display: 'flex', alignItems: 'center' } })}
            >
              {action.displayWarning && action.warningText}
            </Box>
          )
        }

        if (action.isHidden) {
          return null
        }

        const Icon = action.icon
        return (
          <Box
            key={`${index.toString()}_button`}
            {...(action.displayOptionalText && { sx: { display: 'flex', alignItems: 'center' } })}
          >
            <Button
              key={`${index.toString()}_button`}
              leftIcon={Icon && <Icon size={18} />}
              disabled={action.isDisabled}
              size="xs"
              color="blue"
              onClick={() => action.callBack()}
            >
              {action.buttonLabel}
            </Button>
            {action.displayOptionalText && action.optionalText}
          </Box>
        )
      }, [])}
    </Group>
  )
}

export default ActionButtonInline
