import { TextInput as BaseTextInput } from '@mantine/core'

interface TextInputProps {
  name?: string
  label?: string
  placeholder?: string
  required?: boolean
  error?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

export default function TextInput({
  name,
  label,
  placeholder,
  required,
  error,
  onChange,
}: TextInputProps) {
  return (
    <BaseTextInput
      name={name}
      label={label}
      required={required}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}
