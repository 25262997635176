import { ActiveIngredient } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetActiveIngredientQuery<D extends ActiveIngredient>(
  params?: HydraQueryParams<D>
) {
  const { vars, options, key } = params || {}
  const { activeIngredientId } = vars as { activeIngredientId: string }
  return useHydraQuery<D>(
    key ?? `getActiveIngredient${activeIngredientId}`,
    `/api/active_ingredients/${activeIngredientId}`,
    {
      vars,
      options,
    }
  )
}
