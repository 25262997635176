import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  nav: {
    color: theme.white,
    textDecoration: 'none',
    display: 'block',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    '&:hover': {
      backgroundColor: '#173b58',
    },
  },
  subNav: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 31,
    marginLeft: 41,
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    borderLeft: `1px solid '#173b58'`,
    transition: 'border 100ms ease-in-out',
    '&:hover': {
      borderLeft: `1px solid ${theme.colors.green[1]}`,
      fontWeight: 'bolder',
    },
  },
  subNavActive: {
    borderLeft: `1px solid ${theme.colors.green[1]} !important`,
    fontWeight: 'bold',
  },
  navActive: {
    fontWeight: 'bolder',
    backgroundColor: '#173b58',
  },
  chevron: {
    transition: 'transform 200ms ease',
    marginRight: 18,
  },
}))
