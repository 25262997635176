import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Title, UnstyledButton, Menu, Button, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { FileSpreadsheet, FileDownload } from 'tabler-icons-react'
import { utils, writeFileXLSX, read, writeFile } from 'xlsx'
import {
  ExcelDownloadButtonProps,
  IOption,
} from '../../../ui-molecules/components/ActionButton/Inline'
import { TableContext } from '../../providers/TableProvider'
import { getApi } from '../../../api-client'
import { useIntlLight } from '../../../i18n'

const getRawDataSet = (input: any[]) => {
  const dataSet = []
  const excludedKeys = ['@id', '@type', 'id', 'nameEn', 'isUsed']

  // filter raw dataSet
  if (input.length) {
    dataSet.push(
      Object.keys(input[0]).filter(key => {
        if (excludedKeys.indexOf(key) !== -1) return false
        return input[0][key] === null || typeof input[0][key] !== 'object'
      })
    )

    input.forEach((item: any) => {
      const row: any[] = []
      Object.keys(input[0]).forEach(key => {
        if (excludedKeys.indexOf(key) !== -1) return

        const value = item[key]
        if (value !== null && typeof value === 'object') return

        row.push(value ?? '')
      })

      dataSet.push(row)
    })
  }

  return dataSet
}

const getExcel = (data: string[][], title: string) => {
  const date = new Date()
  const formattedDate = `${date.getFullYear()}${
    date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
  }${date.getDay() < 10 ? `0${date.getDay()}` : date.getDay()}`

  const formattedHour = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`

  const ws = utils.aoa_to_sheet(data)
  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, 'Data')
  writeFileXLSX(wb, `${title}-${formattedDate}_${formattedHour}.xlsx`)
  showNotification({
    title: <FormattedMessage id="excelDownload_notification_title" />,
    message: <FormattedMessage id="excelDownload_notification_message" />,
    color: 'green',
  })
}

const ExcelDownloadButton = ({
  currentPageDataUrl,
  allPageDataUrl,
  fileTitle,
  getTranslatedHeader,
  isPOC,
}: ExcelDownloadButtonProps) => {
  const { state } = useContext(TableContext)
  const { t } = useIntlLight()
  const currentPageFetchUrl = currentPageDataUrl ?? state.path
  const currentPageCsvFetchUrl = `${currentPageFetchUrl.split('?')[0]}/csv/export?${
    currentPageFetchUrl.split('?')[1]
  }`
  const originalSearchParam = currentPageFetchUrl.split('?')[1]
  const sp = new URLSearchParams(originalSearchParam)
  const currentPageNum = sp.get('page') ?? 1
  sp.delete('page')
  sp.delete('itemsPerPage')
  sp.set('pagination', 'false')
  const allPageFetchUrl = allPageDataUrl ?? `${currentPageFetchUrl.split('?')[0]}?${sp.toString()}`
  const allPageCsvFetchUrl = `${currentPageCsvFetchUrl.split('?')[0]}?${sp.toString()}`

  const fetchData = async (url: string) => {
    const response = await getApi()
      .get(url)
      .then(response => response.data)
    return response?.['hydra:member'] ?? []
  }

  const fetchCsv = async (url: string) => {
    const response = await getApi({
      Accept: 'text/csv',
    })
      .get(url)
      .then(response => response.data)
    return response ?? ''
  }

  const getExcelFromCsvStr = (data: string, title: string) => {
    const date = new Date()
    const formattedDate = `${date.getFullYear()}${
      date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
    }${date.getDay() < 10 ? `0${date.getDay()}` : date.getDay()}`

    const formattedHour = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`

    const [headers, ...rows] = data
      .split('\n')
      .map(row => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/))

    const extraOption: IOption = {
      replaceOption: [],
      excludeOption: [],
    }

    const headerRow = headers.map((header, index) =>
      getTranslatedHeader ? getTranslatedHeader(header, index, extraOption) : header
    )

    extraOption.excludeOption
      .sort((a, b) => b - a)
      .forEach(option => {
        headerRow.splice(option, 1)
      })

    const dataSet = []
    dataSet.push(headerRow)

    const dataRow = rows.map(row => {
      extraOption.replaceOption.forEach(option => {
        if (row[option.index]) {
          // eslint-disable-next-line no-param-reassign
          row[option.index] = option.transform(row[option.index])
        }
      })
      extraOption.excludeOption
        .sort((a, b) => b - a)
        .forEach(option => {
          row.splice(option, 1)
        })
      return row.map(item => item.replaceAll(/^"|"$/g, ''))
    })
    dataSet.push(...dataRow)

    const ws = utils.aoa_to_sheet(dataSet)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Data')

    writeFile(wb, `${title}-${formattedDate}_${formattedHour}.xlsx`)
    showNotification({
      title: <FormattedMessage id="excelDownload_notification_title" />,
      message: <FormattedMessage id="excelDownload_notification_message" />,
      color: 'green',
    })
  }

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button size="xs" leftIcon={<FileSpreadsheet size={18} />}>
          {t('excelDownload_btnLabel')}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {!isPOC ? (
          <>
            <Menu.Item
              icon={<FileSpreadsheet size={14} />}
              onClick={async () =>
                getExcel(
                  getRawDataSet(await fetchData(currentPageFetchUrl)),
                  `${fileTitle}-Page${currentPageNum}`
                )
              }
            >
              {t('excelDownload_currentPage_btnLabel')}
            </Menu.Item>
            <Menu.Item
              icon={<FileSpreadsheet size={14} />}
              onClick={async () =>
                getExcel(getRawDataSet(await fetchData(allPageFetchUrl)), `${fileTitle}-PageAll`)
              }
            >
              {t('excelDownload_allPage_btnLabel')}
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item
              icon={<FileDownload size={14} />}
              onClick={async () =>
                getExcelFromCsvStr(
                  await fetchCsv(currentPageCsvFetchUrl),
                  `${fileTitle}-Page${currentPageNum}`
                )
              }
            >
              {t('excelDownload_currentPage_btnLabel')}
            </Menu.Item>
            <Menu.Item
              icon={<FileDownload size={14} />}
              onClick={async () =>
                getExcelFromCsvStr(await fetchCsv(allPageCsvFetchUrl), `${fileTitle}-PageAll`)
              }
            >
              {t('excelDownload_allPage_btnLabel')}
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}

export default ExcelDownloadButton
