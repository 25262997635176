import { useEffect, useState } from 'react'
import { showNotification } from '@mantine/notifications'
import { setMe, getMe } from '../../../services/LocalStorageService'
import {
  useCreateUserConsentMutation,
  useUpdateUserConsentMutation,
} from '../../../libs/api-client'
import { IUser } from '../../../providers/AuthProvider/AuthProvider'
import { useIntlLight } from '../../../libs/i18n'

export function useSwitchUserConsent({
  user,
  userConsent,
}: {
  userConsent: {
    id?: string
    authorized: boolean
    consentType: string
  }
  user?: IUser
}) {
  const { t } = useIntlLight()
  const [currentUserConsent, setCurrentUserConsent] = useState(userConsent)
  useEffect(() => {
    setCurrentUserConsent(userConsent)
  }, [userConsent])
  const { mutate: createUserConsent } = useCreateUserConsentMutation({
    options: {
      onSuccess: response => {
        setCurrentUserConsent({
          ...userConsent,
          authorized: response.authorized,
          id: response.id,
        })

        setMe({
          ...getMe(),
          userConsents: [
            ...(getMe()?.userConsents || []),
            {
              id: response.id,
              consentType: response.consentType,
              authorized: true,
            },
          ],
        })
      },
      onError: (error, variables) => {
        showNotification({
          message: t('userProfilePage_userConsentSwitch_notification_preference_error'),
          color: 'red',
        })

        setCurrentUserConsent({
          ...userConsent,
          authorized: false,
        })

        setMe({
          ...getMe(),
          userConsents: [
            ...(getMe()?.userConsents || []),
            {
              consentType: variables.consentType,
              authorized: false,
            },
          ],
        })
      },
    },
  })

  const { mutate: updateUserConsent } = useUpdateUserConsentMutation({
    options: {
      onSuccess: response => {
        setCurrentUserConsent({
          ...currentUserConsent,
          authorized: response.authorized,
        })

        const userConsents = getMe()?.userConsents?.map((userConsent: any) => {
          const consent = { ...userConsent }

          if (consent?.consentType === response.consentType) {
            consent.authorized = response.authorized
          }

          return consent
        })

        setMe({
          ...getMe(),
          userConsents,
        })
      },
      onError: (error, variables) => {
        showNotification({
          message: t('userProfilePage_userConsentSwitch_notification_preference_error'),
          color: 'red',
        })

        setCurrentUserConsent({
          ...currentUserConsent,
        })

        const userConsents = getMe()?.userConsents?.map((userConsent: any) => {
          const consent = { ...userConsent }

          if (consent?.consentType === variables.consentType) {
            consent.authorized = currentUserConsent.authorized
          }

          return consent
        })

        setMe({
          ...getMe(),
          userConsents,
        })
      },
    },
  })

  const switchConsent = (checked: boolean) => {
    if (checked) {
      showNotification({
        message: t('userProfilePage_userConsentSwitch_notification_preference_confirmActive'),
        color: 'green',
      })
    } else {
      showNotification({
        message: t('userProfilePage_userConsentSwitch_notification_preference_confirmInactive'),
        color: 'green',
      })
    }

    setCurrentUserConsent({
      ...currentUserConsent,
      authorized: checked,
    })

    if (currentUserConsent?.id) {
      updateUserConsent({
        userAccount: user?.id,
        consentType: currentUserConsent.consentType,
        authorized: checked,
        id: currentUserConsent?.id,
      })
    } else {
      createUserConsent({
        userAccount: user?.id,
        consentType: currentUserConsent.consentType,
        authorized: checked,
      })
    }
  }

  return {
    switchConsent,
    authorized: currentUserConsent.authorized,
  }
}
