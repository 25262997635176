import { Textarea as BaseTextarea } from '@mantine/core'

interface TextareaProps {
  name?: string
  placeholder?: string
  label?: string
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
}

export default function Textarea({ name, label, required, placeholder, onChange }: TextareaProps) {
  return (
    <BaseTextarea
      name={name}
      label={label}
      required={required}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}
