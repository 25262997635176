import { useContext, useMemo } from 'react'
import { AppSettingsContext } from '../providers/AppSettingsProvider'

export function useAppSettings() {
  const context = useContext(AppSettingsContext)

  if (!context) {
    throw new Error('useAppSettings must be used with AppSettingsContext')
  }

  const { settings, shouldDisplayNotice, disableNotice, enableNotice, toggleChangeLanguage } =
    context

  return useMemo(() => {
    return {
      settings,
      shouldDisplayNotice,
      disableNotice,
      enableNotice,
      toggleChangeLanguage,
    }
  }, [shouldDisplayNotice, toggleChangeLanguage, disableNotice, enableNotice, settings])
}
