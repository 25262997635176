import { useContext, useMemo } from 'react'
import { GlobalModalContext } from '../providers/GlobalModalProvider'

export default function useGlobalModal() {
  const context = useContext(GlobalModalContext)

  if (!context) {
    throw new Error('useGlobalModal must be used with GlobalModalContext')
  }

  const { open, close } = context

  return useMemo(() => {
    return {
      open,
      close,
    }
  }, [open, close])
}
