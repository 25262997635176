import { useHydraMutation, HydraMutationParams } from './useHydraMutation'

export function useUpdateUserConsentMutation(params?: HydraMutationParams) {
  return useHydraMutation('api/user_consents', {
    ...params,
    options: {
      ...params?.options,
      httpMethod: 'PUT',
    },
  })
}
