import React from 'react'
import { Group, Menu, ActionIcon } from '@mantine/core'
import { IconDots } from '@tabler/icons'
import { useIntl } from 'react-intl'

export interface IDropdownMenuAction {
  buttonLabel: string | React.ReactNode
  isDisabled: boolean
  callBack: (key?: string, object?: any) => void
  group?: string
  icon?: React.ReactNode
}

export interface IDropdownMenuProps {
  actions: IDropdownMenuAction[]
}

const DropdownMenu = ({ actions }: IDropdownMenuProps) => {
  const intl = useIntl()
  const menuGroup = Array.from(new Set(actions.map((item): string | undefined => item.group)))
  return (
    <Group>
      <Menu shadow="md" width={200} position="bottom-start">
        <Menu.Target>
          <ActionIcon
            sx={theme => ({
              color: theme.colorScheme === 'dark' ? theme.colors.blue[4] : theme.colors.blue[6],
            })}
            onClick={e => e.stopPropagation()}
          >
            <IconDots size={16} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {menuGroup.map((myGroup, index) => {
            const subMenu = actions
              .filter(item => item.group === myGroup)
              .map((item, index2) => (
                <Menu.Item
                  key={`${index.toString()}${index2.toString()}_button`}
                  disabled={item.isDisabled}
                  onClick={e => {
                    e.stopPropagation()
                    item.callBack()
                  }}
                  icon={item.icon}
                >
                  {item.buttonLabel}
                </Menu.Item>
              ))

            const label = myGroup ? intl.formatMessage({ id: myGroup }) : ''

            let subMenuGroup: any[] = []
            if (label)
              subMenuGroup = [<Menu.Label key={`${index.toString()}_group`}>{label}</Menu.Label>]
            return subMenuGroup
              .concat(subMenu)
              .concat(
                index !== menuGroup.length - 1
                  ? [<Menu.Divider key={`${index.toString()}_divider`} />]
                  : []
              )
          })}
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}

export default DropdownMenu
