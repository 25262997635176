import { AppShell as MantineAppShell, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Suspense, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Navbar } from '../Navbar'
import useCustomRoutes from '../../hooks/useCustomRoutes'
import useAuth from '../../hooks/useAuth'
import { LoaderAbsolute } from '../../libs/ui-atoms'
import { GlobalModalProvider } from '../../libs/ui-atoms/providers/GlobalModalProvider'
import { ServiceWorkerNotification, ValidUseTermContainer } from '../../libs/ui-molecules'
import useAppShellStyles from './useAppShellStyles'
import { getMe } from '../../services/LocalStorageService'

const AppShell = () => {
  const { loggedIn, hasConsent } = useAuth()
  const theme = useMantineTheme()
  const [opened, handlers] = useDisclosure(false)
  const location = useLocation()
  const navigate = useNavigate()
  const routing = useCustomRoutes()
  const { classes } = useAppShellStyles()

  if (getMe() && !hasConsent('MAPAQ_COMMS') && location.pathname !== '/profile') {
    navigate('/profile')
  }

  return (
    <MantineAppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      className={classes.root}
      layout="alt"
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={<Navbar openedSide={loggedIn} opened={opened} handlers={handlers.close} />}
      footer={<Footer />}
      header={
        <Header
          opened={opened}
          handlers={handlers.toggle}
          disableBurger={!loggedIn}
          showLogo={!loggedIn && !['/', '/login'].includes(location.pathname)}
        />
      }
    >
      <Suspense fallback={<LoaderAbsolute />}>
        <ServiceWorkerNotification />
        {loggedIn && <ValidUseTermContainer allowConsent isFullScreen />}
        <GlobalModalProvider>{routing}</GlobalModalProvider>
      </Suspense>
    </MantineAppShell>
  )
}

export default AppShell
