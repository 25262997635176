import { useEffect, useMemo } from 'react'
import { Input, Select } from '@mantine/core'
import { Controller, Control } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetCountrySubdivisionsQuery } from '../../../api-client'

export interface CountrySelectProps {
  name: string
  label?: string
  defaultValue?: string
  error?: string
  required: boolean
  control?: Control<any, any>
  disabled?: boolean
  placeholder?: string
  mb?: 'sm'
  onChange?: (value: string | null) => void
}

const CountrySelect = ({
  name,
  label,
  defaultValue,
  error,
  required = true,
  control,
  disabled,
  placeholder,
  mb,
  onChange,
}: CountrySelectProps) => {
  const {
    data: { member },
  } = useGetCountrySubdivisionsQuery({
    vars: {
      pagination: 'false',
    },
    key: 'countrySelect',
  })

  const options = useMemo(() => {
    const countries = member
      ?.filter(countrySubdivision => countrySubdivision.active)
      .map(countrySubdivision => {
        return { value: countrySubdivision?.countryCode, label: countrySubdivision?.countryName }
      })
    const uniqueCountries = [
      ...new Map(countries?.map(country => [country.value, country])).values(),
    ]

    return uniqueCountries || []
  }, [member])

  return (
    <Input.Wrapper label={label} error={error} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange: onChangeFromController } }) => {
          return (
            <Select
              placeholder={placeholder}
              data={options}
              defaultValue={defaultValue ?? ''}
              value={value}
              onChange={(value: string | null) => {
                onChangeFromController?.(value)
                onChange?.(value)
              }}
              clearable
              searchable
              disabled={disabled}
              label={label}
              mb={mb}
            />
          )
        }}
      />
    </Input.Wrapper>
  )
}

export default CountrySelect
