import { getApi, IUserAccount, IUserResponse, IArrayResponse } from '../../libs/api-client'

export function getAllUser(url: any): Promise<IArrayResponse<IUserResponse>> {
  return getApi()
    .get<IArrayResponse<IUserResponse>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getUser(iri: string): Promise<IUserResponse> {
  return getApi()
    .get<IUserResponse>(`${iri}`)
    .then(response => response.data)
}

export function createUser(data: IUserAccount) {
  return getApi().post('/api/user_accounts', data)
}

export function createUserFromAdmin(data: IUserAccount) {
  return getApi().post('/api/user_accounts/admin_post', {
    ...data,
    frontEndActivationBaseUrl: `${window.location.origin}/confirm_account_from_email`,
  })
}

export function updateUser(data: any, iri: string) {
  return getApi().put(`${iri}`, data)
}

export function deleteUser(iri: string) {
  return getApi().delete(`${iri}`)
}
