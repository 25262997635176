import dayjs from 'dayjs'
import { Action, IFilter, IInitialState } from '../../types'

const SET_FILTER = 'SET_FILTER'
const SET_SORT = 'SET_SORT'
const REMOVE_ALL_FILTER = 'REMOVE_ALL_FILTER'
const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE'
const SET_PATH = 'SET_PATH'
const SET_NO_PAGINATION = 'SET_NO_PAGINATION'
const RESET_FILTER = 'RESET_FILTER'
const RESET_SORT = 'RESET_SORT'
const SET_CHECK = 'SET_CHECK'
const SET_CHECK_ALL = 'SET_CHECK_ALL'
const RESET_CHECK_TO = 'RESET_CHECK_TO'
const UNCHECK = 'UNCHECK'
const UNCHECK_ALL = 'UNCHECK_ALL'
const CLEAR_SEARCH_PARAM = 'CLEAR_SEARCH_PARAM'

export const actionTypes = {
  SET_FILTER,
  SET_SORT,
  REMOVE_ALL_FILTER,
  SET_ITEMS_PER_PAGE,
  SET_PATH,
  SET_NO_PAGINATION,
  RESET_FILTER,
  RESET_SORT,
  SET_CHECK,
  SET_CHECK_ALL,
  UNCHECK,
  UNCHECK_ALL,
  RESET_CHECK_TO,
  CLEAR_SEARCH_PARAM,
}

function getDateFilterQueryString(filter: IFilter): string {
  if (!filter.value && !filter.value.startDate && filter.value.endDate) return ''

  const filtersStr = []
  if (filter.value.startDate) {
    const startDate = dayjs(filter.value.startDate).set('hour', 0).set('minute', 0).set('second', 0)
    filtersStr.push(`${filter.property}[after]=${startDate.format()}`)
  }

  if (filter.value.endDate) {
    const endDate = dayjs(filter.value.endDate).set('hour', 23).set('minute', 59).set('second', 59)
    filtersStr.push(`${filter.property}[before]=${endDate.format()}`)
  }

  return filtersStr.join('&')
}

export const getFilter = (data: any[]): string => {
  if (data.length > 0) {
    return `&${data
      .map((filter: IFilter) => {
        // @todo Je vais gerer les dates ici... Voir Racine.
        if (filter.value.startDate || filter.value.endDate) {
          return getDateFilterQueryString(filter)
        }
        return `${filter.property}=${filter.value}`
      })
      .join('&')}`
  }
  return ''
}

export default function reducer(state: IInitialState, action: Action): IInitialState {
  switch (action.type) {
    case SET_FILTER: {
      // eslint-disable-next-line no-case-declarations
      const filterFiltered = state.filters.filter(
        (filter: IFilter) => filter.property !== action.payload.property
      )

      const updatedSearchParamFilters = state.searchParamFilters.filter(filter => {
        if (action.payload.property === 'createdAt') {
          return !filter.property.includes('createdAt')
        }
        if (action.payload.property === 'declarationDate') {
          return !filter.property.includes('declarationDate')
        }
        return filter.property !== action.payload.property
      })

      if (action.payload.value === '') {
        return {
          ...state,
          filters: filterFiltered,
          searchParamFilters: updatedSearchParamFilters,
          rowChecked: [],
          path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(
            filterFiltered
          )}${getFilter(state.sorts)}`,
        }
      }
      // eslint-disable-next-line no-case-declarations
      const newFilters = [...filterFiltered, action.payload]
      newFilters.forEach((filter: IFilter) => {
        if (action.payload.property === 'createdAt' && filter.property === 'createdAt') {
          if (filter.value.startDate) {
            updatedSearchParamFilters.push({
              property: 'createdAt[after]',
              value: filter.value.startDate,
            })
          }
          if (filter.value.endDate) {
            updatedSearchParamFilters.push({
              property: 'createdAt[before]',
              value: filter.value.endDate,
            })
          }
        } else if (
          action.payload.property === 'declarationDate' &&
          filter.property === 'declarationDate'
        ) {
          if (filter.value.startDate) {
            updatedSearchParamFilters.push({
              property: 'declarationDate[after]',
              value: filter.value.startDate,
            })
          }
          if (filter.value.endDate) {
            updatedSearchParamFilters.push({
              property: 'declarationDate[before]',
              value: filter.value.endDate,
            })
          }
        } else if (action.payload.property === filter.property) {
          updatedSearchParamFilters.push({ property: filter.property, value: filter.value })
        }
      })
      return {
        ...state,
        filters: newFilters,
        searchParamFilters: updatedSearchParamFilters,
        rowChecked: [],
        path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(
          newFilters
        )}${getFilter(state.sorts)}`,
      }
    }
    case SET_SORT:
      // eslint-disable-next-line no-case-declarations
      const sortFiltered = state.sorts.filter(
        (filter: IFilter) => filter.property !== action.payload.property
      )
      if (action.payload.value === '') {
        return {
          ...state,
          sorts: sortFiltered,
          rowChecked: [],
          path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(
            state.filters
          )}${getFilter(sortFiltered)}`,
        }
      }
      // eslint-disable-next-line no-case-declarations
      const newSorts = [...sortFiltered, action.payload]
      return {
        ...state,
        sorts: newSorts,
        rowChecked: [],
        path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(
          state.filters
        )}${getFilter(newSorts)}`,
      }
    case REMOVE_ALL_FILTER:
      return {
        ...state,
        filters: [],
        rowChecked: [],
        path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}`,
      }
    case SET_ITEMS_PER_PAGE:
      return {
        ...state,
        path: `${state.basePath}&itemsPerPage=${action.payload}${getFilter(
          state.filters
        )}${getFilter(state.sorts)}`,
        itemsPerPage: Number(action.payload),
        rowChecked: [],
      }
    case SET_PATH:
      return {
        ...state,
        rowChecked: [],
        path: action.payload,
      }
    case SET_NO_PAGINATION:
      return {
        ...state,
        isPagination: action.payload,
      }
    case RESET_FILTER:
      return {
        ...state,
        filters: [],
        searchParamFilters: [],
        rowChecked: [],
        path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(state.sorts)}`,
      }
    case RESET_SORT:
      return {
        ...state,
        sorts: [],
        rowChecked: [],
        path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(state.filters)}`,
      }
    case RESET_CHECK_TO:
      return {
        ...state,
        rowChecked: [...action.payload],
      }
    case SET_CHECK:
      return {
        ...state,
        rowChecked: [...state.rowChecked, action.payload],
      }
    case SET_CHECK_ALL:
      return {
        ...state,
        rowChecked: [...new Set([...state.rowChecked, ...action.payload])],
      }
    case UNCHECK_ALL:
      return {
        ...state,
        rowChecked: action.payload
          ? [...new Set([...state.rowChecked.filter((row: any) => !action.payload.includes(row))])]
          : [],
      }
    case UNCHECK:
      return {
        ...state,
        rowChecked: [...state.rowChecked.filter((row: any) => row !== action.payload)],
      }
    case CLEAR_SEARCH_PARAM: {
      const updatedFilters = state.filters.filter(
        (filter: IFilter) =>
          !state.searchParamFilters.some(searchFilter => {
            if (searchFilter.property.includes('createdAt') && filter.property === 'createdAt')
              return true
            if (
              searchFilter.property.includes('declarationDate') &&
              filter.property === 'declarationDate'
            )
              return true
            return searchFilter.property === filter.property
          })
      )
      return {
        ...state,
        filters: updatedFilters,
        searchParamFilters: [],
        rowChecked: [],
        path: `${state.basePath}&itemsPerPage=${state.itemsPerPage}&${getFilter(
          updatedFilters
        )}${getFilter(state.sorts)}`,
      }
    }
    default:
      throw new Error()
  }
}
