import { createStyles } from '@mantine/core'

export default createStyles(() => ({
  card: {
    overflow: 'visible',
  },
  helperToolTip: {
    paddingLeft: '5px',
    display: 'inline-block',
  },
}))
