import React from 'react'
import { TextInput, Group, Checkbox, Box, Paper, Title, Input, Modal } from '@mantine/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { ISystemTypeBase } from '../../../../../services/request/assets'
import { FormGroupEditionButton, useDirtyPrompt } from '../../../../ui-atoms'
import useFormServerErrors from '../../../../../hooks/useFormServerErrors'
import { useIntlLight } from '../../../../i18n'

export interface TypeFormBaseProps {
  onSubmitForm: (data: ISystemTypeBase) => void
  formTitle: string
  entity?: ISystemTypeBase
  serverErrors?: any
  closeModal?: () => void
}

const defaultValues = {
  nameFr: '',
  code: '',
  active: true,
}

const schema = yup.object({
  nameFr: yup
    .string()
    .trim()
    .required('formErrorBase.required')
    .max(255, 'formErrorBase.maxLength255'),
  code: yup.string().trim().required('formErrorBase.required').max(25, 'formErrorBase.maxLength25'),
  active: yup.boolean(),
})

const TypeBaseForm = ({
  onSubmitForm,
  entity,
  formTitle,
  serverErrors,
  closeModal,
}: TypeFormBaseProps) => {
  const { trans } = useIntlLight()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm<ISystemTypeBase>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: entity || defaultValues,
    resolver: yupResolver(schema),
  })

  const { formErrors } = useFormServerErrors({ values: getValues(), errors, serverErrors })

  const { modalCloseHandler, ModalDirty } = useDirtyPrompt(isDirty, closeModal)

  return (
    <Modal opened onClose={modalCloseHandler} padding="sm" size="lg">
      <ModalDirty />
      <Box sx={{ maxWidth: 600 }} p="none" mx="auto">
        <Paper px="xs">
          <Title order={5} align="center">
            <FormattedMessage id={formTitle} />
          </Title>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Group grow>
              <Input.Wrapper
                label={trans('typeBase.labelName')}
                error={formErrors?.nameFr}
                required
              >
                <TextInput placeholder={trans('typeBase.labelName')} {...register('nameFr')} />
              </Input.Wrapper>
            </Group>
            <Group grow>
              <Input.Wrapper label={trans('typeBase.labelCode')} error={formErrors?.code} required>
                <TextInput
                  placeholder={trans('typeBase.labelCode')}
                  {...register('code')}
                  disabled={Boolean(entity)}
                />
              </Input.Wrapper>
            </Group>
            <Group>
              <Checkbox mt="xl" label={trans('typeBase.labelActive')} {...register('active')} />
            </Group>
            <FormGroupEditionButton onCancel={modalCloseHandler} />
          </form>
        </Paper>
      </Box>
    </Modal>
  )
}

export default TypeBaseForm
