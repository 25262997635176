/* eslint-disable */
import axios from 'axios'
import { showNotification } from '@mantine/notifications'
import { IconX } from '@tabler/icons'
import { createIntl, createIntlCache } from 'react-intl'
import LocalStorageService, { getLanguage } from '../../../services/LocalStorageService'
import appLocales from '../../../lang'

export const axiosInstanceWithoutToken = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_BACKEND}`,
  headers: {
    'Content-type': 'application/json',
  },
})

export const getBaseApi = (headers = {}) => {
  const _axios = axios.create({
    baseURL: `${process.env.REACT_APP_HOST_BACKEND}`,
    headers: {
      'Content-type': 'application/json',
      'Accept-language': getLanguage(),
      ...headers,
    },
  })
  _axios.interceptors.request.use(
    config => {
      const token = LocalStorageService.getAccessToken()
      if (token) {
        if (config.headers) {
          config.headers.Authorization = 'Bearer ' + token
        }
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  _axios.interceptors.response.use(undefined, error => {
    const localStorageSettings = localStorage.getItem('settings')
    const language = localStorageSettings
      ? JSON.parse(localStorageSettings).currentLanguage ?? 'fr'
      : 'fr'
    const { locale, messages } = appLocales[language]
    const cache = createIntlCache()
    const intl = createIntl({ locale, messages }, cache)
    if (error && error?.response?.status === 503) {
      showNotification({
        id: 'serviceUnavailable',
        message: intl.formatMessage({ id: 'serviceUnavailable' }),
        color: 'red',
        icon: <IconX size={16} />,
      })
    }
    return Promise.reject(error)
  })

  return _axios
}

export const getApi = (headers = {}) => {
  const _axios = getBaseApi(headers)

  _axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const originalRequest = error.config
      if (error && error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        return axiosInstanceWithoutToken
          .post('/api/token/refresh', {
            refresh_token: LocalStorageService.getRefreshToken(),
          })
          .then(res => {
            if (res.status === 200) {
              LocalStorageService.setToken(res.data)

              _axios.defaults.headers.common.Authorization =
                'Bearer ' + LocalStorageService.getAccessToken()

              return _axios(originalRequest)
            }
          })
          .catch(error => {
            if (
              error.response.data.code === 401 &&
              ['Invalid JWT Refresh Token', 'JWT Refresh Token Not Found'].includes(
                error.response.data.message
              )
            ) {
              LocalStorageService.clearToken()
              window.location.href = '/logout'
            }

            return error
          })
      }

      return Promise.reject(error)
    }
  )

  return _axios
}
