import { createContext, ReactNode, useState, useCallback, useMemo, useEffect } from 'react'

interface AppSettings {
  currentLanguage: string
  notices?: {
    [key: string]: {
      unsubscribed: string[]
    }
  }
}

export type IAppSettingsContext = {
  settings: AppSettings
  shouldDisplayNotice: (noticeName: string, username: string) => boolean
  disableNotice: (noticeName: string, username: string) => void
  enableNotice: (noticeName: string, username: string) => void
  toggleChangeLanguage: () => void
}

interface AppSettingsProviderProps {
  defaultSettings?: AppSettings
  children: ReactNode
}

export const AppSettingsContext = createContext<IAppSettingsContext | null>(null)
AppSettingsContext.displayName = 'AppSettings'

export default function AppSettingsProvider({
  children,
  defaultSettings,
}: AppSettingsProviderProps) {
  const currentSettings = localStorage.getItem('settings')
  const [settings, setSettings] = useState<AppSettings>(
    currentSettings ? JSON.parse(currentSettings) : defaultSettings
  )

  useEffect(() => {
    if (settings) {
      localStorage.setItem('settings', JSON.stringify(settings))
    }
  }, [settings])

  const shouldDisplayNotice = useCallback(
    (noticeName: string, username: string) => {
      return !settings?.notices?.[noticeName]?.unsubscribed?.includes(username)
    },
    [settings?.notices]
  )

  const disableNotice = useCallback(
    (noticeName: string, username: string) => {
      const notices = { ...settings?.notices }
      if (!notices[noticeName]) {
        notices[noticeName] = {
          unsubscribed: [],
        }
      }

      notices[noticeName].unsubscribed.push(username)
      setSettings({
        ...settings,
        notices,
      })
    },
    [settings]
  )

  const enableNotice = useCallback(
    (noticeName: string, username: string) => {
      const notices = { ...settings?.notices }
      if (notices[noticeName]) {
        const unsubscribed = notices[noticeName].unsubscribed?.filter(e => e !== username)
        notices[noticeName].unsubscribed = unsubscribed
      }

      setSettings({
        ...settings,
        notices,
      })
    },
    [settings]
  )

  const toggleChangeLanguage = useCallback(() => {
    setSettings({
      ...settings,
      currentLanguage: settings.currentLanguage === 'fr' ? 'en' : 'fr',
    })
  }, [settings])

  const value = useMemo(() => {
    return {
      settings,
      shouldDisplayNotice,
      disableNotice,
      enableNotice,
      toggleChangeLanguage,
    }
  }, [settings, shouldDisplayNotice, disableNotice, enableNotice, toggleChangeLanguage])

  return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>
}
