import React from 'react'
import { Button, createStyles, Divider, Group, Select } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { IPaginationProps, IPaginationState } from '../../../types'
import { actionTypes } from '../../../reducers/TableContext'
import { pageSizeOptions } from '../../../../../global'

const useStyles = createStyles(theme => ({
  textColor: {
    color: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.black,
  },
}))

const PageOffset = ({ itemsPerPage = 1, items = 1, page = 1 }: IPaginationState) => {
  const numberPage = Math.ceil(items / itemsPerPage)
  return (
    <>
      {' '}
      {page} <FormattedMessage id="pagination.pageOf" /> {numberPage}{' '}
    </>
  )
}

const Pagination = React.memo<any>(({ dispatch, navigation, state }: IPaginationProps) => {
  return (
    <Group position="center" className={useStyles().classes.textColor}>
      <Group grow={false} position="left">
        <FormattedMessage id="pagination.rows_per_page" defaultMessage="Rows per page" />
        <Select
          style={{ width: '80px' }}
          variant="filled"
          data={
            state?.pageSizeOptions?.map(i => i.toString()) ?? pageSizeOptions.map(i => i.toString())
          }
          value={state?.itemsPerPage?.toString() || '5'}
          onChange={val => dispatch({ type: actionTypes.SET_ITEMS_PER_PAGE, payload: val })}
        />
      </Group>
      <Divider orientation="vertical" />
      <Group position="center" spacing="xs" grow={false}>
        <Button
          p="xs"
          radius="md"
          size="xs"
          variant="outline"
          disabled={!navigation.previous}
          onClick={() => dispatch({ type: actionTypes.SET_PATH, payload: navigation.first })}
        >
          {'<<'}
        </Button>
        <Button
          p="xs"
          radius="md"
          size="xs"
          variant="outline"
          disabled={!navigation.previous}
          onClick={() => dispatch({ type: actionTypes.SET_PATH, payload: navigation.previous })}
        >
          {'<'}
        </Button>
        <Button
          p="xs"
          radius="md"
          size="xs"
          variant="outline"
          disabled={!navigation.next}
          onClick={() => dispatch({ type: actionTypes.SET_PATH, payload: navigation.next })}
        >
          {'>'}
        </Button>
        <Button
          p="xs"
          radius="md"
          size="xs"
          variant="outline"
          disabled={!navigation.next}
          onClick={() => dispatch({ type: actionTypes.SET_PATH, payload: navigation.last })}
        >
          {'>>'}
        </Button>
      </Group>
      <Divider orientation="vertical" />
      <Group grow={false} position="left">
        <FormattedMessage id="pagination.page" defaultMessage="Page" />
        <PageOffset
          itemsPerPage={state?.itemsPerPage || 5}
          items={navigation.totalItems}
          page={navigation.currentPage}
        />
      </Group>
      <Divider orientation="vertical" />
      <Group grow={false} position="left">
        <FormattedMessage id="pagination.total_items" defaultMessage="Total items" />
        {': '}
        {navigation.totalItems}
      </Group>
    </Group>
  )
})

export default Pagination
