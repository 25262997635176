import { Medication } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetMedicationQuery<D extends Medication>(params?: HydraQueryParams<D>) {
  const { vars, options, key } = params || {}
  const { medicationId } = vars as { medicationId: string }
  return useHydraQuery<D>(
    key ?? `getMedication${medicationId}`,
    `/api/medications/${medicationId}`,
    {
      vars,
      options,
    }
  )
}
