import { Navigate, useRoutes } from 'react-router-dom'
import { lazy } from 'react'
import useAuth from '../useAuth'
import { ROLE } from '../../types'
import { UserProfilePage } from '../../pages/UserProfilePage'
import { DebugPage } from '../../pages/DebugPage'
import JumbotronProd from '../../pages/HomePage/Jumbotron/JumbotronProd'

const HomePage = lazy(() => import('../../pages/HomePage/HomePage'))
const ConfirmAccount = lazy(() => import('../../pages/ConfirmAccount'))
const ConfirmAccountFromEmailPage = lazy(
  () => import('../../pages/ConfirmAccountFromEmailPage/ConfirmAccountFromEmailPage')
)
const NotFoundPage = lazy(() => import('../../pages/NotFoundPage/NotFoundPage'))
const UserAccountLayout = lazy(() => import('../../layouts/UserAccountLayout'))
const UserListPage = lazy(() => import('../../pages/UserListPage/UserListPage'))
const RequireAuth = lazy(() => import('../../libs/routing/components/RequireAuth/RequireAuth'))
const DashboardNavigate = lazy(
  () => import('../../libs/routing/components/DashboardNavigate/DashboardNavigate')
)
const DashboardLayout = lazy(() => import('../../layouts/DashboardLayout'))
const DashboardAdmin = lazy(() => import('../../pages/DashboardAdminPage/DashboardAdminPage'))
const DashboardVeterinarian = lazy(
  () => import('../../pages/DashboardVeterinarianPage/DashboardVeterinarianPage')
)
const Dashboard = lazy(() => import('../../pages/DashboardPage/DashboardPage'))
const PasswordResetPage = lazy(() => import('../../pages/PasswordResetPage/PasswordResetPage'))
const PasswordForgotRequestPage = lazy(
  () => import('../../pages/PasswordForgotRequestPage/PasswordForgotRequestPage')
)
const LoginPage = lazy(() => import('../../pages/LoginPage/LoginPage'))
const SignUpPage = lazy(() => import('../../pages/SignUpPage/SignUpPage'))
const LogoutPage = lazy(() => import('../../pages/LogoutPage/LogoutPage'))

const ProductTypeLister = lazy(() => import('../../pages/Type/ProductType/List'))

const ProductListPage = lazy(() => import('../../pages/ProductListPage/ProductListPage'))

const MedicationTypeListPage = lazy(
  () => import('../../pages/MedicationTypeListPage/MedicationTypeListPage')
)

const SpeciesListPage = lazy(() => import('../../pages/SpeciesListPage/SpeciesListPage'))

const Medication = lazy(() => import('../../pages/MedicationListPage/MedicationListPage'))

const ActiveIngredientListPage = lazy(
  () => import('../../pages/ActiveIngredientListPage/ActiveIngredientListPage')
)

const ProductionStagesListPage = lazy(
  () => import('../../pages/ProductionStageListPage/ProductionStageListPage')
)

const PermitListPage = lazy(() => import('../../pages/PermitListPage/PermitListPage'))

const PermitCodeAdd = lazy(() => import('../../pages/PermitCode/Add'))
const PermitCodeEdit = lazy(() => import('../../pages/PermitCode/Edit'))
const PermitCodeLister = lazy(() => import('../../pages/PermitCode/List'))

const SystemTypeLayout = lazy(() => import('../../layouts/SystemTypeLayout'))
const PermitLayout = lazy(() => import('../../layouts/PermitLayout'))
const DeclarationLayout = lazy(() => import('../../layouts/DeclarationLayout/DeclarationLayout'))

const DeclarationListPage = lazy(() => import('../../pages/DeclarationListPage'))
const DeclarationTemplateListPage = lazy(() => import('../../pages/DeclarationTemplateListPage'))
const DeclarationTemplateLotListPage = lazy(
  () => import('../../pages/DeclarationTemplateLotListPage')
)
const DeclarationEditPage = lazy(
  () => import('../../pages/DeclarationEditPage/DeclarationEditPage')
)
const DeclarationDuplicatePage = lazy(
  () => import('../../pages/DeclarationDuplicatePage/DeclarationDuplicatePage')
)
const PendingDeclarationPage = lazy(
  () => import('../../pages/PendingDeclarationPage/PendingDeclarationPage')
)
const PendingDeclarationEdit = lazy(
  () => import('../../pages/PendingDeclarationEditPage/PendingDeclarationEditPage')
)
const DeclarationSiteUpdateListPage = lazy(
  () => import('../../pages/DeclarationSiteUpdateListPage')
)
const UserApiKeyLister = lazy(() => import('../../pages/UserApiKeyListPage/UserApiKeyListPage'))
const UserApiKeyAdminListPage = lazy(
  () => import('../../pages/UserApiKeyAdminListPage/UserApiKeyAdminListPage')
)

const DeclarationMultistepCreatePage = lazy(
  () => import('../../pages/DeclarationMultistepCreatePage/DeclarationMultistepCreatePage')
)
const DeclarationMultistepDuplicatePage = lazy(
  () => import('../../pages/DeclarationMultistepDuplicatePage/DeclarationMultistepDuplicatePage')
)
const DeclarationCreatePage = lazy(
  () => import('../../pages/DeclarationCreatePage/DeclarationCreatePage')
)

const DeclarationCreateFromTemplateLotPage = lazy(
  () =>
    import('../../pages/DeclarationCreateFromTemplateLotPage/DeclarationCreateFromTemplateLotPage')
)

const OmvqVeterinarianListPage = lazy(
  () => import('../../pages/OmvqVeterinarianListPage/OmvqVeterinarianListPage')
)

const OmvqEstablishmentListPage = lazy(
  () => import('../../pages/OmvqEstablishmentListPage/OmvqEstablishmentListPage')
)

const OmvqEstablishmentCodeListPage = lazy(
  () => import('../../pages/OmvqEstablishmentCodeListPage/OmvqEstablishmentCodeListPage')
)

const UseTermListPage = lazy(
  () => import('../../modules/settings/views/UseTermListView/UseTermListView')
)

const RegulationDateLimitListPage = lazy(
  () =>
    import('../../modules/date-limit/views/RegulationDateLimitListView/RegulationDateLimitListView')
)

const RegulationDateLimitLayout = lazy(() => import('../../layouts/RegulationDateLimitLayout'))

const VetDeclarationLayout = lazy(() => import('../../layouts/VetDeclarationLayout'))

const VetDeclarationListPage = lazy(() => import('../../pages/VetDeclarationListPage'))

const mainRoutes = {
  path: '/',
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: '/', element: <Navigate to="/dashboard" /> },
    { path: 'reset_password', element: <PasswordResetPage /> },
    { path: 'confirm_account/api/user_accounts/:userId', element: <ConfirmAccount /> },
    { path: 'logout', element: <LogoutPage /> },
    { path: 'login', element: <Navigate to="/dashboard" /> },
    { path: '404', element: <NotFoundPage /> },
  ],
}

const debugHomeProdRoute = {
  path: 'debug-home-prod',
  element: <JumbotronProd />,
}

const offLineRoutes = {
  path: '/',
  children: [
    { path: '*', element: <Navigate to="/" /> },
    { path: '/', element: <HomePage /> },
    { path: '/home', element: <HomePage /> },
    { path: '/login', element: <LoginPage /> },
    { path: '/logout', element: <LogoutPage /> },
    { path: '/signup/:profile', element: <SignUpPage /> },
    { path: '/reset_password', element: <PasswordResetPage /> },
    { path: '/forgot_password_request', element: <PasswordForgotRequestPage /> },
    { path: '/confirm_account/api/user_accounts/:userId', element: <ConfirmAccount /> },
    { path: '/confirm_account_from_email', element: <ConfirmAccountFromEmailPage /> },
    { path: '/404', element: <NotFoundPage /> },
    debugHomeProdRoute,
  ],
}

const dashboardRoutes = {
  path: 'dashboard',
  element: <DashboardNavigate />,
  children: [
    { path: '', element: <Navigate to="/dashboard" /> },
    {
      element: (
        <RequireAuth allowedRoles={[ROLE.ROLE_DETENTEUR_PERMIS]}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [{ path: 'permitholder', element: <Dashboard /> }],
    },
    {
      element: (
        <RequireAuth
          allowedRoles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MAPAQ, ROLE.ROLE_MAPAQ_RO, ROLE.ROLE_SUPPORT]}
        >
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [{ path: 'admin', element: <DashboardAdmin /> }],
    },
    {
      element: (
        <RequireAuth allowedRoles={[ROLE.ROLE_VET]}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [{ path: 'veterinarian', element: <DashboardVeterinarian /> }],
    },
  ],
}

const userAccountRoutes = {
  path: 'users',
  element: (
    <RequireAuth>
      <UserAccountLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <UserListPage /> },
  ],
}

const userProfileRoute = {
  path: 'profile',
  element: (
    <RequireAuth>
      <UserProfilePage />
    </RequireAuth>
  ),
}

const productTypeRoutes = {
  path: 'product_type',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <ProductTypeLister /> },
  ],
}

const ProductRoutes = {
  path: 'product',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <ProductListPage /> },
  ],
}

const medicationTypeRoutes = {
  path: 'medication_type',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <MedicationTypeListPage /> },
  ],
}

const speciesRoutes = {
  path: 'species',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <SpeciesListPage /> },
  ],
}

const medicationRoutes = {
  path: 'medication',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <Medication /> },
  ],
}

const activeIngredientRoutes = {
  path: 'active_ingredient',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <ActiveIngredientListPage /> },
  ],
}

const productionStageRoutes = {
  path: 'production_stage',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <ProductionStagesListPage /> },
  ],
}

const permitRoutesRoleAdmin = {
  path: 'permit',
  element: (
    <RequireAuth>
      <PermitLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <PermitListPage /> },
  ],
}

const permitRoutesRoleUser = { ...permitRoutesRoleAdmin }

const permitCodeRoutes = {
  path: 'permit_code',
  element: (
    <RequireAuth>
      <PermitLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <PermitCodeLister /> },
    { path: 'add', element: <PermitCodeAdd /> },
    { path: ':id/edit', element: <PermitCodeEdit /> },
  ],
}

const userApiKeyCreatorsRoutes = {
  path: 'user_api_key_creator',
  element: (
    <RequireAuth>
      <UserAccountLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <UserApiKeyLister /> },
    { path: 'add', element: <PermitCodeAdd /> },
    { path: ':id/edit', element: <PermitCodeEdit /> },
  ],
}

const userApiKeyAdminRoutes = {
  path: 'user_api_key',
  element: <UserApiKeyAdminListPage />,
}

const declarationRoutesRoleAdmin = {
  path: 'declaration',
  element: (
    <RequireAuth>
      <DeclarationLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <DeclarationListPage /> },
    { path: ':id/edit', element: <DeclarationEditPage /> },
    { path: ':id/duplicate', element: <DeclarationDuplicatePage /> },
  ],
}

const declarationTemplateRoutesRoleAdmin = {
  path: 'declaration_template',
  element: (
    <RequireAuth>
      <DeclarationLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <DeclarationTemplateListPage /> },
  ],
}

const declarationTemplateLotRoutes = {
  path: 'declaration_template_lot',
  element: (
    <RequireAuth>
      <DeclarationLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <DeclarationTemplateLotListPage /> },
  ],
}

const declarationRoutesRoleUser = {
  ...declarationRoutesRoleAdmin,
  children: [
    ...declarationRoutesRoleAdmin.children,
    {
      path: 'add',
      element: <DeclarationMultistepCreatePage />,
    },
    {
      path: 'duplicate',
      element: <DeclarationMultistepDuplicatePage />,
    },
    {
      path: 'from_template/:templateId/add',
      element: <DeclarationCreatePage />,
    },
    {
      path: 'from_template_lot/:templateLotId/add',
      element: <DeclarationCreateFromTemplateLotPage />,
    },
  ],
}

const pendingDeclarationRoutes = {
  path: 'pending_declaration',
  element: (
    <RequireAuth>
      <DeclarationLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <PendingDeclarationPage /> },
    { path: ':id/edit', element: <PendingDeclarationEdit /> },
  ],
}

const declarationSiteUpdateRoutes = {
  path: 'declaration_site_update',
  element: (
    <RequireAuth>
      <DeclarationLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <DeclarationSiteUpdateListPage /> },
  ],
}

const veterinarianRoutes = {
  path: 'omvq_veterinarian',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <OmvqVeterinarianListPage /> },
  ],
}

const establishmentRoutes = {
  path: 'omvq_establishment',
  element: (
    <RequireAuth>
      <SystemTypeLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <OmvqEstablishmentListPage /> },
  ],
}

const establishmentCodeRoutes = {
  path: 'omvq_establishment_code',
  element: (
    <RequireAuth>
      <UserAccountLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <OmvqEstablishmentCodeListPage /> },
  ],
}

const useTermRoutes = {
  path: 'use_term',
  element: (
    <RequireAuth>
      <UserAccountLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <UseTermListPage /> },
  ],
}

const regulationDateLimitRoutes = {
  path: 'regulation_date_limit',
  element: (
    <RequireAuth>
      <RegulationDateLimitLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <RegulationDateLimitListPage /> },
  ],
}

const debugRoute = {
  path: 'debug',
  element: (
    <RequireAuth>
      <DebugPage />
    </RequireAuth>
  ),
}

const vetDeclarationRoutes = {
  path: 'vet_declaration',
  element: (
    <RequireAuth>
      <VetDeclarationLayout />
    </RequireAuth>
  ),
  children: [
    { path: '*', element: <Navigate to="/404" /> },
    { path: 'list', element: <VetDeclarationListPage /> },
  ],
}

const roleUserRoutes = [
  mainRoutes,
  dashboardRoutes,
  ProductRoutes,
  medicationRoutes,
  activeIngredientRoutes,
  productionStageRoutes,
  permitRoutesRoleUser,
  permitCodeRoutes,
  declarationRoutesRoleUser,
  declarationTemplateLotRoutes,
  pendingDeclarationRoutes,
  declarationSiteUpdateRoutes,
  userApiKeyCreatorsRoutes,
  userProfileRoute,
  useTermRoutes,
  establishmentCodeRoutes,
  vetDeclarationRoutes,
]

const roleAdminRoutes = [
  mainRoutes,
  dashboardRoutes,
  productTypeRoutes,
  ProductRoutes,
  medicationTypeRoutes,
  medicationRoutes,
  activeIngredientRoutes,
  speciesRoutes,
  productionStageRoutes,
  userAccountRoutes,
  permitRoutesRoleAdmin,
  permitCodeRoutes,
  declarationRoutesRoleAdmin,
  userApiKeyAdminRoutes,
  userProfileRoute,
  veterinarianRoutes,
  establishmentRoutes,
  useTermRoutes,
  regulationDateLimitRoutes,
  establishmentCodeRoutes,
  debugRoute,
  vetDeclarationRoutes,
]

export default function useCustomRoutes() {
  const { user, loggedIn, isRoleMapaqRoOrAbove } = useAuth()

  let routes: any[] = []
  if (loggedIn) {
    routes = routes.concat(
      isRoleMapaqRoOrAbove(user?.role as ROLE) ? roleAdminRoutes : roleUserRoutes
    )
  } else {
    routes = routes.concat([offLineRoutes])
  }

  return useRoutes(routes)
}

export { mainRoutes, userAccountRoutes, offLineRoutes, roleUserRoutes, roleAdminRoutes }
