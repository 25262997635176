/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Group, Modal, Title } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useBlocker } from './useBlocker'

export default function useDirtyPrompt(
  when: boolean,
  onModalClose?: () => void
): {
  ModalDirty: React.FC
  showPrompt: boolean // to control your own Modal
  confirmNavigation: () => void // to control your own Modal confirm
  cancelNavigation: () => void // to control your own Modal cancel
  modalCloseHandler: () => void // if you are already in a Modal, useful for onClose.
} {
  const navigate = useNavigate()
  const location = useLocation()
  const [showPrompt, setShowPrompt] = useState(false)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false)
    setLastLocation(null)
  }, [])

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true)
        setLastLocation(nextLocation)
        return false
      }
      return true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  )

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
    if (onModalClose) onModalClose()
  }, [])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location?.pathname)

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation])

  useBlocker(handleBlockedNavigation, when)

  const modalCloseHandler = () => {
    if (!when && onModalClose) {
      onModalClose()
    } else {
      setShowPrompt(true)
    }
  }

  const ModalDirty = React.useMemo(() => {
    const MenuComponent: React.FC = () => {
      return (
        <Modal
          opened={showPrompt}
          onClose={cancelNavigation}
          title={<FormattedMessage id="dirtyPrompt_title" />}
          centered
          zIndex="500"
        >
          <Title order={5}>
            <FormattedMessage id="dirtyPrompt_message" />
          </Title>
          <Group position="center" mt="xl">
            <Button color="red" type="submit" onClick={confirmNavigation}>
              <FormattedMessage id="dirtyPrompt_btnConfirm" defaultMessage="Quitter" />
            </Button>
            <Button color="blue" type="button" onClick={cancelNavigation}>
              <FormattedMessage id="dirtyPrompt_btnCancel" defaultMessage="Rester" />
            </Button>
          </Group>
        </Modal>
      )
    }
    return MenuComponent
  }, [cancelNavigation, confirmNavigation, showPrompt])

  return { ModalDirty, showPrompt, confirmNavigation, cancelNavigation, modalCloseHandler }
}
