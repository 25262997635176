import React, { useEffect, useReducer } from 'react'
import { useSearchParams, createSearchParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import useCrud from '../../hooks/useCrud'
import { IArrayResponse } from '../../../api-client'
import reducer from '../../reducers/TableContext'
import { ITableContext, ITableContextProps, INavigationPagination } from '../../types'
import useMultiDelete from '../../hooks/useMultiDelete'
import useMultiUpdate from '../../hooks/useMultiUpdate'
import { pageSizeOptions } from '../../../../global'

const defaultNavigationView = {
  itemsPerPage: 10,
  totalItems: 0,
  currentPage: 1,
  first: null,
  previous: null,
  next: null,
  last: null,
}

const extractedHydraView = (data?: IArrayResponse<any>): INavigationPagination => {
  if (!data) {
    return defaultNavigationView
  }

  let currentPage
  try {
    const page = data['hydra:view']['@id'].split('=').pop()
    if (data['hydra:view']['@id']?.search(/page=/) === -1) {
      throw new Error('no page')
    }
    currentPage = Number.isInteger(Number(page)) ? Number(page) : 1
  } catch (e) {
    currentPage = 1
  }
  return {
    currentPage,
    totalItems: data['hydra:totalItems'] || 0,
    first: data['hydra:view']['hydra:first'] || null,
    previous: data['hydra:view']['hydra:previous'] || null,
    next: data['hydra:view']['hydra:next'] || null,
    last: data['hydra:view']['hydra:last'] || null,
  }
}

const TableContext: React.Context<any | null> = React.createContext<any | null>(null)

export const TableProvider = ({
  children,
  defaultValues,
  enableSearchParams = false,
}: ITableContextProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamFilters: any[] = []
  const internalSearchParamFilters: any[] = []
  let searchParamStr = ''
  if (enableSearchParams) {
    [...searchParams].forEach(([property, value]) => {
      if (
        !(
          property === 'createdAt[after]' ||
          property === 'createdAt[before]' ||
          property === 'declarationDate[after]' ||
          property === 'declarationDate[before]'
        )
      ) {
        internalSearchParamFilters.push({ property, value })
      }
      searchParamFilters.push({ property, value })
    })
    searchParamStr = `&${createSearchParams(Object.fromEntries([...searchParams]))}`

    const createdAtFilterValue: Record<string, any> = {}
    const createdAfter = searchParamFilters.find(filter => filter.property === 'createdAt[after]')
    const createdBefore = searchParamFilters.find(filter => filter.property === 'createdAt[before]')

    const declarationDateFilterValue: Record<string, any> = {}
    const declarationDateAfter = searchParamFilters.find(
      filter => filter.property === 'declarationDate[after]'
    )
    const declarationDateBefore = searchParamFilters.find(
      filter => filter.property === 'declarationDate[before]'
    )

    if (createdAfter) {
      createdAtFilterValue.startDate = createdAfter.value
    }
    if (createdBefore) {
      createdAtFilterValue.endDate = createdBefore.value
    }
    if (declarationDateAfter) {
      declarationDateFilterValue.startDate = declarationDateAfter.value
    }
    if (declarationDateBefore) {
      declarationDateFilterValue.endDate = declarationDateBefore.value
    }
    if (Object.keys(createdAtFilterValue).length) {
      internalSearchParamFilters.push({ property: 'createdAt', value: createdAtFilterValue })
    }
    if (Object.keys(declarationDateFilterValue).length) {
      internalSearchParamFilters.push({
        property: 'declarationDate',
        value: declarationDateFilterValue,
      })
    }
  }

  const queryClient = useQueryClient()
  const [state, dispatch] = useReducer(reducer, {
    path: `${defaultValues.basePath}&itemsPerPage=${defaultValues.itemsPerPage}${
      defaultValues.defaultSort ? `&${defaultValues.defaultSort}` : ''
    }${searchParamStr}`,
    filters: internalSearchParamFilters,
    searchParamFilters,
    sorts: [],
    basePath: defaultValues?.basePath,
    isPagination: defaultValues?.isPagination ?? true,
    itemsPerPage: defaultValues?.itemsPerPage ?? 10,
    pageSizeOptions: defaultValues?.pageSizeOptions ?? pageSizeOptions,
    rowChecked: [],
  })

  const response = useQuery<IArrayResponse<any>, Error>(
    [defaultValues.queryKey, `${state.path}`],
    defaultValues.queryFunc,
    {
      refetchOnWindowFocus: false,
    }
  )

  const { handlerNavigate } = useCrud({
    queryKey: defaultValues.queryKey,
    pathPrefix: defaultValues.pathPrefix,
    deleteAction: defaultValues.deleteAction,
    updateAction: defaultValues.updateAction,
  })

  const { handlerDelete } = useMultiDelete({
    queryKey: defaultValues.queryKey,
    queryClient,
  })

  const { handlerUpdate } = useMultiUpdate({
    queryClient,
    queryClientKey: defaultValues.queryKey,
  })

  useEffect(() => {
    const updatedSearchParams = state.searchParamFilters.reduce(
      (obj, item) => ({ ...obj, [item.property]: item.value }),
      {}
    )
    setSearchParams(updatedSearchParams)
  }, [state.searchParamFilters, setSearchParams])

  const value: ITableContext = React.useMemo(() => {
    const data =
      response?.data && response?.data['hydra:member'] ? response?.data['hydra:member'] : []
    const navigation = extractedHydraView(response?.data)

    return {
      data,
      state,
      isLoading: response.isLoading,
      navigation,
      dispatch,
      handlerNavigate,
      handlerDelete,
      handlerUpdate,
    }
  }, [response?.data, response.isLoading, handlerNavigate, state, handlerDelete, handlerUpdate])

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export default TableContext
