// To manage 422 form violations
import { UseFormSetError } from 'react-hook-form'

export interface IFormViolations {
  code: string
  message: string
  propertyPath: string
}

export function extractViolations(error: any): IFormViolations[] {
  return error?.response?.data?.violations || []
}

export function translatedChoicesViolation(
  possiblesValues: { label: string; value: string }[],
  message?: string | null
): string {
  if (!message) return ''

  let translated: string = message
  possiblesValues.forEach(pv => {
    translated = translated.replace(pv.value, pv.label)
  })

  return translated
}

export function extractViolationsWithTranslatedChoices(
  error: any,
  choicesPossiblesValues: { [propertyPath: string]: { label: string; value: string }[] }
): IFormViolations[] {
  const violations = extractViolations(error)

  violations.forEach(violation => {
    if (choicesPossiblesValues[violation.propertyPath]) {
      // eslint-disable-next-line no-param-reassign
      violation.message = translatedChoicesViolation(
        choicesPossiblesValues[violation.propertyPath],
        violation.message
      )
    }
  })

  return violations
}

export function findViolationByProperty(
  violations: IFormViolations[],
  property: string
): IFormViolations | undefined {
  return violations.find((item: IFormViolations) => item?.propertyPath === property)
}

function appliedErrorToFormInternal<T>(
  values: any,
  violations: IFormViolations[],
  setError: UseFormSetError<T | any>,
  path = ''
): void {
  Object.keys(values).forEach(key => {
    if (values[key] instanceof Object)
      appliedErrorToFormInternal(values[key], violations, setError, `${key}.`)
    else {
      const err = findViolationByProperty(violations, `${path}${key}`)
      if (err) {
        // @ts-ignore
        setError(`${path}${key}`, {
          type: 'custom',
          message: err.message,
        })
      }
    }
  })
}

export function appliedErrorToForm<T>(
  values: any,
  error: any,
  setError: UseFormSetError<T | any>
): void {
  const violations = extractViolations(error)
  appliedErrorToFormInternal<T>(values, violations, setError)
}

export function extractServerErrors(values: any, violations: IFormViolations[], path = ''): any {
  const newObj: any = {}
  Object.keys(values).forEach(key => {
    if (
      values[key] instanceof Object &&
      values[key].constructor !== Array &&
      values[key].constructor !== Date
    )
      newObj[key] = extractServerErrors(values[key], violations, path)
    else {
      const err = findViolationByProperty(violations, `${path}${key}`)
      if (err) {
        newObj[key] = err.message
      }
    }
  })

  return newObj
}
