import { Declaration } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetDeclarationsQuery<D extends Declaration[]>(params?: HydraQueryParams<D>) {
  const { vars, options, key } = params || {}
  return useHydraQuery<D>(key ?? 'declarations', '/api/declarations', {
    vars,
    options,
  })
}
