import { IDeclarationTemplate } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetDeclarationTemplateQuery<D extends IDeclarationTemplate>(
  params?: HydraQueryParams<D>
) {
  const { vars, options, key } = params || {}
  const { id } = vars as { id: string }
  return useHydraQuery<D>(
    key ?? `getDeclarationTemplate${id}`,
    `/api/declaration_templates/${id}`,
    {
      vars,
      options,
    }
  )
}
