import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  root: {
    position: 'relative',
    button: {
      '&:disabled': {
        color: theme.colorScheme === 'dark' ? '#b8bdd5' : '#7a8188',
      },
    },
  },
}))
