import { ISpecies } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetSpeciesQuery<D extends ISpecies[]>(params?: HydraQueryParams<D>) {
  const { vars, options } = params || {}
  return useHydraQuery<D>('species', '/api/species', {
    vars,
    options,
  })
}
