import { Title, Container, Card, Group, Text, Button } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons'
import { FormattedMessage } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useIntlLight } from '../../libs/i18n'
import useAuth from '../../hooks/useAuth'
import { UserConsentSwitch } from '../../modules/user'
import useUserProfilePageStyles from './useUserProfilePageStyles'
import { getMe } from '../../services/LocalStorageService'
import { getUser } from '../../services/request/users'

const UserProfilePage = () => {
  const { t } = useIntlLight()
  const { user, forgetPassword, hasConsent, setUser } = useAuth()
  const { classes } = useUserProfilePageStyles()
  const [showConsentError, setShowConsentError] = useState<boolean>(false)
  const { data: currentUser } = useQuery(['getUser', user?.id], () => getUser(user?.id ?? ''))

  useEffect(() => {
    if (currentUser && currentUser.userConsents) {
      setUser(prevState => prevState && { ...prevState, userConsents: currentUser.userConsents })
    }
  }, [currentUser, setUser])

  useEffect(() => {
    setShowConsentError(Boolean(user) && !hasConsent('MAPAQ_COMMS'))
  }, [hasConsent, user])

  const handleChangePasswrod = () => {
    if (user?.email) {
      showNotification({
        id: 'reset-password',
        loading: true,
        message: t('forgotPasswordRequest_resetAttempt'),
        autoClose: false,
        disallowClose: true,
      })
      forgetPassword(user?.email)
        .then(response => {
          if (response.status === 202) {
            updateNotification({
              id: 'reset-password',
              color: 'teal',
              message: t('forgotPasswordRequest_emailSent'),
              icon: <IconCheck size={16} />,
              autoClose: true,
            })
          }
        })
        .catch((error: any) => {
          updateNotification({
            id: 'reset-password',
            message: t('forgotPasswordRequest_resetAttemptFailed'),
            color: 'red',
            icon: <IconX size={16} />,
          })
        })
    }
  }

  const renderUserConsents = useMemo(() => {
    const userConsentTypes = ['MAPAQ_COMMS']
    return userConsentTypes.map(userConsentType => {
      const userConsent = (user?.userConsents as any)?.find(
        (userConsent: any) => userConsent?.consentType === userConsentType
      ) || {
        consentType: userConsentType,
        authorized: undefined,
      }

      return (
        <Group key={userConsentType} position="apart">
          <UserConsentSwitch
            userConsent={userConsent}
            user={user}
            onChange={() => {
              setShowConsentError(false)
            }}
          />
        </Group>
      )
    })
  }, [user])

  return (
    <Container size="xs">
      <Title
        m="sm"
        order={4}
        sx={theme => ({
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
          textAlign: 'center',
        })}
      >
        <FormattedMessage id="userProfile_section_title" />
      </Title>
      <Card className={classes.card} withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text>{t('userProfile_section_fullName')} :</Text>
            <Text>{user?.name}</Text>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text>{t('userProfile_section_userName')} :</Text>
            <Text>{user?.email}</Text>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text>{t('userProfile_section_resetPassword')} :</Text>
            <Button radius="md" onClick={handleChangePasswrod}>
              {t('userProfile_section_send')}
            </Button>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          <Text>{t('all_authorization')} :</Text>
          {showConsentError && <Text color="red">{t('userProfile_authorization_required')}</Text>}
          <br />
          {renderUserConsents}
        </Card.Section>
      </Card>
    </Container>
  )
}

export default UserProfilePage
