import { Button, Divider, Group, Modal, Text, TextInput, Title } from '@mantine/core'
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { randomId } from '@mantine/hooks'
import { useIntlLight } from '../../../i18n'

interface Options {
  onClose?: () => void
  onConfirm?: () => void
  secureConfirm?: boolean
  title?: string
  size?: 'lg' | 'xl' | '' | string
  isFullScreen?: boolean
}

export type IGlobalModalContext = {
  open?: (content: ReactNode, options?: Options) => void
  close?: () => void
}

interface GlobalModalProviderProps {
  children?: ReactNode
}

export const GlobalModalContext = createContext<IGlobalModalContext | null>(null)
GlobalModalContext.displayName = 'GlobalModal'

export default function GlobalModalProvider({ children }: GlobalModalProviderProps) {
  const [content, setContent] = useState<ReactNode | string>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<Options>()
  const [hash, setHash] = useState<string>()
  const [hashConfirm, setHashConfirm] = useState<string>()
  const { t } = useIntlLight()

  const open = useCallback((content: ReactNode, options?: Options) => {
    setIsOpen(true)
    setContent(content)
    setOptions({ ...options })

    if (options?.secureConfirm) {
      const tmpHash = randomId().replace('mantine-', '')
      setHash(tmpHash)
    }
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const contextValue = useMemo(() => {
    return {
      open,
      close,
    }
  }, [open, close])

  return (
    <GlobalModalContext.Provider value={contextValue}>
      {children}
      <Modal
        fullScreen={options?.isFullScreen}
        size={options?.size}
        opened={isOpen}
        onClose={() => {
          options?.onClose?.()
          close()
        }}
        zIndex={1000}
        centered
        title={options?.title && <Title order={5}>{options?.title}</Title>}
      >
        {content}
        {options?.onConfirm && (
          <>
            {options.secureConfirm && (
              <>
                <Divider />
                <Text>{t('dialogDeleteWithHash')}</Text>
                <Text weight="bold">{hash}</Text>
                <TextInput onChange={e => setHashConfirm(e.target.value)} />
              </>
            )}
            <Group position="center" mt="xl">
              <Button
                color="red"
                type="button"
                onClick={() => {
                  options?.onClose?.()
                  close()
                }}
                data-testid="dialog-confirm-cancel"
              >
                {t('no')}
              </Button>
              <Button
                data-testid="dialog-confirm-submit"
                color="blue"
                type="submit"
                onClick={() => {
                  options?.onConfirm?.()
                  close()
                }}
                disabled={options?.secureConfirm ? !(hash === hashConfirm) : false}
              >
                {t('yes')}
              </Button>
            </Group>
          </>
        )}
      </Modal>
    </GlobalModalContext.Provider>
  )
}
