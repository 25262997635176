import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  loader: {
    color: theme.colorScheme === 'dark' ? 'white' : theme.colors.gray[6],
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))
