import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  UnstyledButton,
  Group,
  Text,
  createStyles,
  Menu,
  useMantineColorScheme,
} from '@mantine/core'
import { IconTrash, IconChevronDown, IconUser } from '@tabler/icons'
import { MoonStars, Sun, Logout } from 'tabler-icons-react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const useStyles = createStyles(theme => ({
  user: {
    display: 'block',
    width: '100%',
    padding: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}))

const AuthMenu = () => {
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { loggedIn, user, logout } = useAuth()
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()

  if (!loggedIn) {
    return <div />
  }
  return (
    <Menu shadow="md" width={200} position="bottom-end">
      <Menu.Target>
        <UnstyledButton className={classes.user}>
          <Group>
            <div style={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {
                  // @ts-ignore
                  user?.name
                }
              </Text>
              <Text color="dimmed" size="xs">
                {
                  // @ts-ignore
                  user?.email
                }
              </Text>
            </div>
            <IconChevronDown size={14} stroke={1.5} />
          </Group>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <FormattedMessage id="userAuthCard_profile" />
        </Menu.Label>
        <Menu.Item icon={<IconUser size={16} />} onClick={() => navigate('/profile')}>
          <FormattedMessage id="userAuthCard_viewProfile" />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>
          <FormattedMessage id="userAuthCard.settings" />
        </Menu.Label>
        <Menu.Item
          icon={colorScheme === 'dark' ? <Sun size={16} /> : <MoonStars size={16} />}
          onClick={() => toggleColorScheme()}
        >
          <FormattedMessage
            id={colorScheme === 'dark' ? 'userAuthCard.lightMode' : 'userAuthCard.darkMode'}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item icon={<Logout size={16} />} onClick={() => logout()}>
          <FormattedMessage id="userAuthCard.signOut" />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default AuthMenu
