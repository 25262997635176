import { Radio, Text } from '@mantine/core'
import { HelpTooltip } from '../../../../libs/ui-atoms'
import { IUser } from '../../../../providers/AuthProvider/AuthProvider'
import { useIntlLight } from '../../../../libs/i18n'
import { useSwitchUserConsent } from '../../hooks/useSwitchUserConsent'
import useUserConsentSwitchStyles from './useUserConsentSwitchStyles'

interface UserConsentSwitch {
  userConsent: {
    id?: string
    authorized: boolean
    consentType: string
  }
  user?: IUser
  onChange: () => void
}

export default function UserConsentSwitch({ userConsent, user, onChange }: UserConsentSwitch) {
  const { t } = useIntlLight()
  const { classes } = useUserConsentSwitchStyles()
  const { switchConsent, authorized } = useSwitchUserConsent({
    user,
    userConsent,
  })

  const getValue = (authorized?: boolean) => {
    if (authorized !== undefined) {
      return authorized ? 'yes' : 'no'
    }

    return undefined
  }

  return (
    <Radio.Group
      name="userConsents[mapaqComms]"
      label={
        <Text>
          {t(`signup_signupPage_register_userConsent_MAPAQ_COMMS`)}
          <HelpTooltip
            className={classes.helperToolTip}
            text={t(`signup_signupPage_register_userConsent_helpTooltip_MAPAQ_COMMS`)}
          />
        </Text>
      }
      value={getValue(authorized)}
      onChange={value => {
        onChange?.()
        switchConsent(value === 'yes')
      }}
    >
      <Radio value="yes" label={t('all_yes')} />
      <Radio value="no" label={t('all_no')} />
    </Radio.Group>
  )
}
