import React from 'react'
import { Anchor, Box } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import useStyles from './LogoCenteredNotLogged.styles'

const LogoCenteredNotLogged = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  return (
    <Box className={classes.logoWrapper}>
      <Anchor onClick={() => navigate('/')}>
        <img className={classes.logo} alt="logo" src="/image/Logo_AntibiotiQC.png" />
      </Anchor>
    </Box>
  )
}

export default LogoCenteredNotLogged
