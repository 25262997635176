import React, { useEffect, useState } from 'react'
import { Menu, ActionIcon, Card, Text, Group, Indicator, Title } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { IconBellRinging2, IconTrash } from '@tabler/icons'
import useAuth from '../../../../hooks/useAuth'
import { useIntlLight } from '../../../i18n'
import notificationMessageList, { INotification } from './NotificationMessageList'
import useNotificationMessageStyles from './useNotificationMessageStyles'

interface INotificationMenuProps {
  notificationList?: INotification[]
}

const NotificationMenu = ({ notificationList }: INotificationMenuProps) => {
  const { t } = useIntlLight()
  const { classes } = useNotificationMessageStyles()
  const { loggedIn } = useAuth()
  const [opened, setOpened] = useState(false)
  const initNotifications = notificationList || notificationMessageList
  const [notifications, setNotifications] = useLocalStorage<INotification[]>({
    key: 'notification_messages',
    defaultValue: initNotifications,
  })

  notifications.forEach(localNotification => {
    const existingNotification = initNotifications.find(
      item => item.messageId === localNotification.messageId
    )
    if (existingNotification) {
      existingNotification.isRead = localNotification.isRead
    }
  })

  const handleNotificationRead = (notification: INotification) => {
    const updatedNotifications = initNotifications.map(item => {
      if (item.messageId === notification.messageId) {
        return { ...item, isRead: true }
      }
      return item
    })
    setNotifications(updatedNotifications)
  }

  const unReadNotifications = initNotifications.filter(({ isRead }) => !isRead)

  const messageItems = unReadNotifications.map(item => (
    <Card.Section
      key={item.messageId}
      // withBorder
      inheritPadding
      py="xs"
      sx={{
        border: '1px solid #ccc !important;',
        borderRadius: '4px',
        marginBottom: '6px',
        boxShadow:
          '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;',
      }}
    >
      <Group noWrap sx={{ justifyContent: 'space-between' }}>
        <Title size={18}>{item.title ? item.title : t(item.titleId)}</Title>
        <ActionIcon
          sx={theme => ({
            color: theme.colorScheme === 'dark' ? theme.colors.blue[4] : theme.colors.blue[6],
          })}
          onClick={() => handleNotificationRead(item)}
        >
          <IconTrash size={22} />
        </ActionIcon>
      </Group>
      <Group noWrap sx={{ justifyContent: 'space-between' }}>
        <Text size={16}>{item.message ? item.message : t(item.messageId)}</Text>
      </Group>
    </Card.Section>
  ))

  useEffect(() => {
    setOpened(!!unReadNotifications.length)
  }, [unReadNotifications.length])

  if (!loggedIn) {
    return null
  }

  return (
    <Menu shadow="md" width={500} position="bottom-end" opened={opened} onChange={setOpened}>
      <Menu.Target>
        <Indicator disabled={!unReadNotifications.length}>
          <ActionIcon
            sx={theme => ({
              color: theme.colorScheme === 'dark' ? theme.colors.blue[4] : theme.colors.blue[6],
            })}
          >
            <IconBellRinging2 size={28} />
          </ActionIcon>
        </Indicator>
      </Menu.Target>
      <Menu.Dropdown>
        <Card className={classes.card} radius="md" sx={{ borderRadius: 'inherit' }}>
          {unReadNotifications.length ? messageItems : t('notificationMessage.emptyMessage')}
        </Card>
      </Menu.Dropdown>
    </Menu>
  )
}

export default NotificationMenu
