import { Declaration } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetPendingDeclarationsQuery<D extends Declaration[]>(
  params?: HydraQueryParams<D>
) {
  const { vars, options } = params || {}
  return useHydraQuery<D>('pendingDeclarations', '/api/pending_declarations', {
    vars,
    options,
  })
}
