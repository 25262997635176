import { ActionIcon, Popover, Text } from '@mantine/core'
import { IconQuestionMark } from '@tabler/icons'
import useHelpTooltipStyles from './useHelpTooltipStyles'

interface HelpTooltipProps {
  text: string
  size?: number
  style?: React.CSSProperties
  className?: string
  width?: number
}
export default function HelpTooltip({ text, size, style, width, className }: HelpTooltipProps) {
  const { classes } = useHelpTooltipStyles()

  return (
    <div className={className} style={style}>
      <Popover width={width || 300} withArrow shadow="md">
        <Popover.Target>
          <ActionIcon size={size || 20} radius="xl" color="blue" variant="filled">
            <IconQuestionMark size="1rem" />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm" dangerouslySetInnerHTML={{ __html: text }} />
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}
