import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

export default function useIntlLight() {
  const intl = useIntl()

  const trans = useMemo(() => {
    return (key: string | undefined, params?: Record<string, any>) => {
      if (!key) return ''
      return intl.formatMessage({ id: key }, params)
    }
  }, [intl])

  return { trans, locale: intl.locale, t: trans }
}
