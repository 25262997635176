export interface InlineAddress {
  firstLine: string
  secondLine?: string
  city: string
  postalCode: string
  countrySubdivision: string
  ADDRESS_TYPE: 'INLINE'
}

export interface DetailedAddress {
  civicNumber: string
  unitNumber: string
  streetName: string
  ruralRoute?: string
  poBox?: string
  stationInformation?: string
  city: string
  province: string
  postalCode: string
  countrySubdivision: string
  ADDRESS_TYPE: 'DETAILED'
}

export interface GeoAddress {
  addressFirst: string
  city: string
  province: string
  country: string
  postalCode: string
}

export enum Notices {
  TRANSACTIONS_DECLARATION_NOTICE = 'TRANSACTIONS_DECLARATION_NOTICE',
  ADD_SITE_NOTICE = 'ADD_SITE_NOTICE',
}

export enum ROLE {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MAPAQ = 'ROLE_MAPAQ',
  ROLE_MAPAQ_RO = 'ROLE_MAPAQ_RO',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_DETENTEUR_PERMIS = 'ROLE_DETENTEUR_PERMIS',
  ROLE_VET = 'ROLE_VET',
}

export enum DeclarationType {
  SALE_SUPPLY = 'SALE_SUPPLY',
  RETURN = 'RETURN',
}

export enum UseTermType {
  UTILISATEUR = 'UTILISATEUR',
}

export enum RegulationDateLimitType {
  GLOBAL = 'GLOBAL',
  ANNUAL = 'ANNUAL',
  INDIVIDUAL = 'INDIVIDUAL',
}
