import { createStyles, UnstyledButton, Text, Center, Group } from '@mantine/core'
import { upperFirst } from '@mantine/hooks'
import { Language } from 'tabler-icons-react'
import { useAppSettings } from '../../../../hooks/useAppSettings'

const useStyles = createStyles(theme => ({
  control: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 1000,
    paddingLeft: theme.spacing.sm,
    paddingRight: 4,
    width: 116,
    height: 0,
  },

  iconWrapper: {
    height: 28,
    width: 28,
    borderRadius: 28,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.dark[4],
    color: theme.colorScheme === 'dark' ? theme.black : theme.colors.blue[2],
  },

  value: {
    lineHeight: 1,
  },
}))

const LanguageToggle = () => {
  const { classes } = useStyles()
  const {
    settings: { currentLanguage },
    toggleChangeLanguage,
  } = useAppSettings()
  const Icon = currentLanguage === 'fr' ? Language : Language

  return (
    <Group position="center" my="xl">
      <UnstyledButton
        aria-label="Toggle theme"
        className={classes.control}
        onClick={() => toggleChangeLanguage?.()}
        title="Ctrl + J"
      >
        <Text size="sm" className={classes.value}>
          {upperFirst(currentLanguage === 'fr' ? 'english' : 'français')}
        </Text>

        <Center className={classes.iconWrapper}>
          <Icon size={18} />
        </Center>
      </UnstyledButton>
    </Group>
  )
}

export default LanguageToggle
