import React from 'react'
import { QueryClient, useMutation } from 'react-query'
import { ISystemTypeBase, update } from '../../../services/request/assets'

export interface IMutationMulti {
  messageKey: string
}

export interface IUseCrudMulti {
  queryClient: QueryClient
  queryClientKey: string
}

const useMultiUpdate = ({ queryClient, queryClientKey }: IUseCrudMulti) => {
  const updateMultiMutation = useMutation(update)

  const handlerUpdate = async (data: any[], rowChecked: string[], payload: any) => {
    const promises: Promise<any>[] = []
    data.forEach((entity: ISystemTypeBase): void => {
      if (rowChecked.indexOf(entity['@id']) !== -1) {
        promises.push(
          updateMultiMutation.mutateAsync({
            data: { entity, ...payload },
            iri: entity['@id'],
          })
        )
      }
    })
    const res = await Promise.all(promises)

    queryClient.invalidateQueries(queryClientKey).then(() => null)

    return res
  }

  return { handlerUpdate }
}

export default useMultiUpdate
