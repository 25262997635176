export interface INotification {
  titleId: string
  title?: string
  messageId: string
  message?: string
  isRead?: boolean
}

const notificationMessageList: INotification[] = [
  {
    titleId: 'notificationMessage.title_0',
    messageId: 'notificationMessage.message_0',
  },
  {
    titleId: 'notificationMessage.title_1',
    messageId: 'notificationMessage.message_1',
  },
  {
    titleId: 'notificationMessage.title_2',
    messageId: 'notificationMessage.message_2',
  },
]

export default notificationMessageList
