import React from 'react'
import { actionTypes } from '../../../../reducers/TableContext'

interface ICheckBoxProps {
  dispatch: React.Dispatch<any>
  state: any
  iri: string
}

const RowCheckBox = ({ dispatch, state, iri }: ICheckBoxProps) => {
  if (iri === '') {
    return <input type="checkbox" disabled />
  }

  return (
    <input
      type="checkbox"
      checked={state.rowChecked.includes(iri)}
      onChange={e => {
        const { checked } = e.target
        if (checked) {
          dispatch({ type: actionTypes.SET_CHECK, payload: iri })
        } else {
          dispatch({ type: actionTypes.UNCHECK, payload: iri })
        }
      }}
      onClick={e => {
        // ensure not clicking on parent element
        e.stopPropagation()
      }}
    />
  )
}

export default RowCheckBox
