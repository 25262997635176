import { Button as BaseButton, DefaultMantineColor } from '@mantine/core'
import { ReactNode } from 'react'

interface ButtonProps {
  children: ReactNode
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  color?: DefaultMantineColor
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function Button({ children, type, loading, color, onClick }: ButtonProps) {
  return (
    <BaseButton type={type || 'submit'} loading={loading} color={color} onClick={onClick}>
      {children}
    </BaseButton>
  )
}
