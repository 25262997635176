import { QueryClient, useMutation } from 'react-query'
import { showNotification } from '@mantine/notifications'
import { deleteEntity } from '../../../services/request/assets'
import { useIntlLight } from '../../i18n'

export interface IMutationMulti {
  messageKey: string
}

export interface IUseCrudMulti {
  queryKey: string
  queryClient: QueryClient
}

const useMultiDelete = ({ queryKey, queryClient }: IUseCrudMulti) => {
  const deleteMultiMutation = useMutation(deleteEntity)
  const { t } = useIntlLight()

  const handlerDelete = async (rowChecked: string[]): Promise<any> => {
    const promises: Promise<any>[] = []
    let countSuccess = 0
    const newRowChecked: string[] = []

    rowChecked.forEach((item: string): void => {
      const ret = deleteMultiMutation
        .mutateAsync(item)
        .then(resp => {
          countSuccess += 1
          return resp
        })
        .catch(error => {
          newRowChecked.push(item)
          return error
        })
      promises.push(ret)
    })

    await Promise.all(promises)

    if (countSuccess > 0) {
      showNotification({
        title: t('systemBase.showNotification.systemMessageLabel'),
        message: t('all_notification_batchDelete', { count: countSuccess }),
        color: 'green',
      })
      queryClient.invalidateQueries(queryKey).then(() => null)
    }

    if (newRowChecked.length > 0) {
      showNotification({
        title: t('systemBase.showNotification.systemMessageLabel'),
        message: t('all_notification_batchDeleteFailure', { count: newRowChecked.length }),
        color: 'red',
      })
    }

    return newRowChecked
  }

  return { handlerDelete }
}

export default useMultiDelete
