import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getApi } from '../utils/request'
import { normalizeMemberI18nFields } from '../utils/normalizeMemberI18nFields'

export interface HydraQueryOptions<D> {
  onSuccess?: (result: { member: D; totalItems: number }) => void
  enabled?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnVarsChange?: boolean
}

export interface HydraQueryParams<D> {
  key?: string
  vars?: Record<string, any>
  options?: HydraQueryOptions<D>
  httpHeaders?: {
    Accept: 'text/csv' | 'text/json'
  }
}

interface HydraQueryReturn<D> extends Omit<ReturnType<typeof useQuery>, 'data'> {
  data: {
    member: D
    totalItems: number
  }
}

interface VarsCache {
  [key: string]: any
}

export function useHydraQuery<D>(
  queryKey: string,
  url: string,
  params?: HydraQueryParams<D>
): HydraQueryReturn<D> {
  const [varsCache, setVarsCache] = useState<VarsCache>({})
  const { vars, options, httpHeaders } = params || {}
  const finalKey = params?.key || queryKey

  const { data, refetch, ...rest } = useQuery(
    [finalKey],
    () => {
      return getApi()
        .get(url, {
          params: {
            ...vars,
          },
          headers: {
            ...httpHeaders,
          },
        })
        .then(response => response.data)
        .catch(error => Promise.reject(error))
    },
    {
      ...options,
      onSuccess: (data: any) => {
        const member = data?.['hydra:member'] || data
        const normalizedMember = member ? normalizeMemberI18nFields(member, 'fr') : undefined

        const preparedData = {
          member: normalizedMember as D,
          totalItems: member && !Array.isArray(member) ? 1 : data?.['hydra:totalItems'],
        }

        options?.onSuccess?.(preparedData)
      },
      retry: false,
    }
  )

  useEffect(() => {
    if (!options?.enabled && !(finalKey in varsCache)) {
      setVarsCache({
        ...varsCache,
        [finalKey]: vars,
      })

      return
    }

    if (
      options?.refetchOnVarsChange &&
      JSON.stringify(varsCache?.[finalKey]) !== JSON.stringify(vars)
    ) {
      setVarsCache({
        ...varsCache,
        [finalKey]: vars,
      })
      refetch()
    }
  }, [options, refetch, varsCache, vars, finalKey])

  const member = data?.['hydra:member'] || data
  const normalizedMember = member ? normalizeMemberI18nFields(member, 'fr') : undefined

  return {
    ...rest,
    refetch,
    data: {
      member: normalizedMember as D,
      totalItems: member && !Array.isArray(member) ? 1 : data?.['hydra:totalItems'],
    },
  }
}
