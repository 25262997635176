import { HydraQueriesOptions, useHydraQueries } from './useHydraQueries'

interface Statistic {
  keys: string[]
  headers: string[][]
  values: number[][]
}

export function useGetStatisticsMultiQueries<D extends Statistic[]>(
  queriesOptions: HydraQueriesOptions<D>[]
) {
  return useHydraQueries<D>('statistics', '/api/statistics', queriesOptions)
}
