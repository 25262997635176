import React, { useEffect, useState } from 'react'
import { Button, Group, Text } from '@mantine/core'
import { hideNotification, showNotification } from '@mantine/notifications'
import { register } from '../../../../serviceWorkerRegistration'
import { useIntlLight } from '../../../i18n'

const ServiceWorkerNotification = () => {
  const [showReload, setShowReload] = useState(false)
  const [installingWorker, setInstallingWorker] = useState<ServiceWorker | null>(null)
  const { t } = useIntlLight()

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true)
    setInstallingWorker(registration.installing || registration.waiting)
  }

  useEffect(() => {
    register({ onUpdate: onSWUpdate })
  }, [])

  useEffect(() => {
    const reloadPage = () => {
      installingWorker?.postMessage({ type: 'SKIP_WAITING' })
      setShowReload(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }

    if (showReload && installingWorker) {
      showNotification({
        id: 'versionUpdate',
        message: (
          <Group>
            <Text>{t('sw_notification_versionUpdate')}</Text>
            <Button onClick={reloadPage}>{t('sw_notification_reload')}</Button>
          </Group>
        ),
        color: 'green',
        autoClose: false,
        onClose: () => setShowReload(false),
      })
    } else {
      hideNotification('versionUpdate')
    }
  }, [showReload, installingWorker, t])

  return null
}

export default ServiceWorkerNotification
