import React, { useState } from 'react'
import { ActionIcon, Anchor, Button, Select, Group, Popover } from '@mantine/core'
import { BiFilterAlt as FilterIcon } from 'react-icons/bi'
import useSelectFilterStyles from './useSelectFilterStyles'
import { useIntlLight } from '../../../../../i18n'

const SelectFilter = ({ columnName, state, dispatch, data, label, placeholder }: any) => {
  const [opened, setOpened] = useState(false)

  const filter = state.filters.find((_filter: any) => _filter.property === columnName) || {
    value: '',
  }

  const [stateFilter, setStateFilter] = useState(filter.value)

  const handleClose = () => {
    setOpened(false)
  }

  const handleClear = () => {
    dispatch({ type: 'SET_FILTER', payload: { property: columnName, value: '' } })
    setOpened(false)
  }

  const handleApply = () => {
    dispatch({
      type: 'SET_FILTER',
      payload: { property: columnName, value: stateFilter },
    })
    setOpened(false)
  }
  const isAppliedDisable = !stateFilter

  const { classes } = useSelectFilterStyles({
    isFilterEnabled: filter?.value,
  })

  const { t } = useIntlLight()

  return (
    <Popover opened={opened} onClose={handleClose} position="bottom" transition="scale-y">
      <Popover.Target>
        <ActionIcon
          className={classes.filterIconButton}
          color={filter?.value ? 'blue' : 'gray'}
          onClick={() => setOpened(o => !o)}
          variant="filled"
        >
          <FilterIcon />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Select
          label={label}
          placeholder={placeholder}
          data={data}
          value={stateFilter}
          onChange={setStateFilter}
        />
        <Group position="apart" mt="sm">
          <Button color="red" onClick={handleClear} disabled={!filter.value}>
            {t('all_clear')}
          </Button>
          <Button onClick={handleApply} disabled={isAppliedDisable}>
            {t('all_apply')}
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
}

export default SelectFilter
