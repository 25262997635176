import { ReactNode, forwardRef } from 'react'
import { MantineColor, Tooltip } from '@mantine/core'

export interface IconTooltipProps {
  label: string
  children: ReactNode
  withArrow?: boolean
  color?: MantineColor
}

const IconTooltip = forwardRef<HTMLDivElement, IconTooltipProps>(
  ({ label, children, withArrow, color }, ref) => (
    <Tooltip label={label} {...(withArrow && { withArrow })} {...(color && { color })}>
      <div ref={ref}>{children}</div>
    </Tooltip>
  )
)

export default IconTooltip
