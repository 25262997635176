import { Product } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetProductQuery<D extends Product>(params?: HydraQueryParams<D>) {
  const { vars, options, key } = params || {}
  const { productId } = vars as { productId: string }
  return useHydraQuery<D>(key ?? `getProduct${productId}`, `/api/products/${productId}`, {
    vars,
    options,
  })
}
