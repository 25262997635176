import React, { useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Button, Container, Group, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useIntlLight } from '../../../i18n'
import { IUseTerm } from '../../../../services/request/useTerm'
import { useHydraQuery } from '../../../api-client'
import { createUseTermHistory } from '../../../../services/request/useTermHistory'
import useAuth from '../../../../hooks/useAuth'
import useGlobalModal from '../../../ui-atoms/hooks/useGlobalModal'

interface ValidUseTermContainerProps {
  useTermType?: string
  allowConsent?: boolean
  isFullScreen?: boolean
  showModal?: boolean
  closeModal?: () => void
}

const ValidUseTermContainer = ({
  useTermType,
  closeModal,
  allowConsent = false,
  isFullScreen = false,
  showModal = false,
}: ValidUseTermContainerProps) => {
  const { t } = useIntlLight()
  const { open, close } = useGlobalModal()
  const { user, setUser, logout, isRoleDetenteurPermis, isRoleVeterinarian } = useAuth()
  const termType = 'UTILISATEUR'

  const iri = `/api/use_terms/validate?termType=${termType}`

  const {
    data: { member: useTerms },
    isLoading,
  } = useHydraQuery<IUseTerm[]>(`getValidUseTerm${termType}`, iri)
  const { mutate } = useMutation(createUseTermHistory, {
    onSuccess: () => {
      setUser(
        user && {
          ...user,
          acceptedUseTerm: `/api/use_terms/${useTerms[0]?.id}`,
        }
      )
      closeModal?.()
      close?.()
      showNotification({
        title: t('systemBase.showNotification.systemMessageLabel'),
        message: t('useTerm_accept_notification'),
        color: 'green',
      })
    },
  })

  const handleConsent = useCallback(() => {
    const payload = {
      useTerm: `/api/use_terms/${useTerms[0]?.id}`,
      userAccount: user?.id || '',
    }
    mutate(payload)
  }, [useTerms, user, mutate])

  useEffect(() => {
    if ((showModal || (termType && user && !user?.acceptedUseTerm)) && !isLoading) {
      open?.(
        <Container size="sm">
          <Title size={20} align="center">
            {t('useTerm_titleLister')}
          </Title>
          <Group position="center">
            <p
              style={{
                padding: '40px',
                minHeight: '80vh',
                maxHeight: '80vh',
                minWidth: '70vw',
                maxWidth: '70vw',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{
                __html: useTerms[0]?.descriptionFr,
              }}
            />
          </Group>
          <Group position={termType && user && !user?.acceptedUseTerm ? 'apart' : 'right'}>
            {termType && user && !user?.acceptedUseTerm && (
              <Button onClick={handleConsent}>{t('useTerm_accept_button')}</Button>
            )}
            <Button
              onClick={() => {
                closeModal?.()
                close?.()
                if (termType && user && !user?.acceptedUseTerm) {
                  showNotification({
                    title: t('systemBase.showNotification.systemMessageLabel'),
                    message: t('useTerm_cancel_notification'),
                    color: 'green',
                  })
                  logout()
                }
              }}
            >
              {t('useTerm_cancel_button')}
            </Button>
          </Group>
        </Container>,
        {
          onClose: () => {
            closeModal?.()
            if (termType && user && !user?.acceptedUseTerm) {
              showNotification({
                title: t('systemBase.showNotification.systemMessageLabel'),
                message: t('useTerm_cancel_notification'),
                color: 'green',
              })
              logout()
            }
          },
          isFullScreen,
          size: '70vw',
        }
      )
    }
  }, [
    open,
    close,
    closeModal,
    logout,
    user,
    useTerms,
    termType,
    isLoading,
    allowConsent,
    isFullScreen,
    showModal,
    handleConsent,
    t,
  ])

  return null
}

export default ValidUseTermContainer
