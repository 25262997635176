/* eslint-disable */
/* https://tanstack.com/table/v8/docs/examples/react/pagination?from=reactTableV7&original=https://react-table-v7.tanstack.com/docs/examples/pagination */
import React, { useContext, useMemo, useState } from 'react'
import { Row, useRowSelect, useTable } from 'react-table'
import { Center, Divider, Group, Table, Text, UnstyledButton } from '@mantine/core'
import Pagination from '../Pagination'
import { TableContext } from '../../../providers/TableProvider'
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons'
import { ITablePropsExpandable, ThProps } from '../../../types'
import { DateFilter, SelectFilter, StringFilter } from '../Filters'
import useTableExpendableStyles from './useTableExpendableStyles'
import { FormattedMessage } from 'react-intl'

const getNewIcon = (key: any) => {
  switch (key?.value) {
    case 'asc':
      return IconChevronUp
    case 'desc':
      return IconChevronDown
    default:
      return IconSelector
  }
}

function resolveFilterComponent(componentType: string) {
  let Filter = StringFilter

  if (componentType === 'DateFilter') {
    Filter = DateFilter
  } else if (componentType === 'SelectFilter') {
    Filter = SelectFilter
  }

  return Filter
}

const ThContent = ({ children, column, state, dispatch }: ThProps) => {
  const { classes } = useTableExpendableStyles()
  const filter = state.sorts.find((_filter: any) => _filter.property === column.orderBy)

  const Icon = column?.orderBy ? getNewIcon(filter) : () => null
  const handleClick = () => {
    if (filter?.value === 'desc') {
      dispatch({ type: 'SET_SORT', payload: { property: column.orderBy, value: '' } })
    } else {
      dispatch({
        type: 'SET_SORT',
        payload: { property: column.orderBy, value: filter?.value === 'asc' ? 'desc' : 'asc' },
      })
    }
  }

  const Filter = column.filterComponent || resolveFilterComponent(column.filterType)
  return (
    <div className={classes.thContent}>
      {!column?.noSort ? (
        <div style={{ flexGrow: 0 }}>
          {column.filterValue ? (
            <Filter
              columnName={column.filterValue}
              state={state}
              dispatch={dispatch}
              {...(column.filterType === 'SelectFilter' ? column.filterProps : {})}
            />
          ) : null}
        </div>
      ) : null}
      <div style={{ flexGrow: 0 }}>
        <Text weight={500} size="sm" p="none" align="left">
          {children}
        </Text>
      </div>
      {column?.orderBy ? (
        <div style={{ flexGrow: 0 }}>
          <UnstyledButton onClick={handleClick}>
            <Center className={classes.icon}>
              <Icon size={14} stroke={1.5} />
            </Center>
          </UnstyledButton>
        </div>
      ) : null}
    </div>
  )
}

interface IRowPropsExpandable {
  expandElement?: (row: any) => React.ReactNode
  row: any
  prepareRow: (row: Row<any>) => void
  provideContext?: React.Context<any>
}

const ExpandableRow: React.FC<IRowPropsExpandable> = ({
  row,
  expandElement,
  prepareRow,
  provideContext,
}) => {
  const context = useContext(provideContext || TableContext)
  const [state, setState] = useState({ isOpen: false, action: 'list' })

  prepareRow(row)
  const className = Object.keys(context).length
    ? context?.state?.rowChecked.indexOf(row?.original['@id']) !== -1
      ? 'row-checked'
      : ''
    : ''
  const expandedRowContent = expandElement && row?.original ? expandElement(row?.original) : null

  return (
    <>
      <tr
        {...row.getRowProps()}
        className={className}
        style={{ cursor: expandElement ? 'pointer' : '' }}
        onClick={() => {
          setState({ isOpen: !state.isOpen, action: state.action })
        }}
      >
        {row.cells.map((cell: any) => {
          return (
            <td align={cell.column.align || 'left'} {...cell.getCellProps()}>
              {cell.render('Cell', { rowState: state })}
            </td>
          )
        })}
      </tr>
      {state.isOpen && expandedRowContent && (
        <tr>
          <td colSpan={row.cells.length}>{expandedRowContent}</td>
        </tr>
      )}
    </>
  )
}

const TableExpendable: React.FC<ITablePropsExpandable> = ({
  columns,
  data,
  expandElement,
  isDataFiltered,
  provideContext,
}) => {
  const context = useContext(provideContext || TableContext)
  const { classes } = useTableExpendableStyles()

  const hiddenColumns = columns?.filter(c => c?.isVisible === false)?.map(c => c?.accessor)
  const tableOptions = useTable(
    {
      data: data || [],
      columns,
      initialState: { hiddenColumns },
    },
    useRowSelect
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableOptions

  const renderedHeaders = useMemo<any>(
    () =>
      headerGroups.map(hg => (
        <tr {...hg.getHeaderGroupProps()}>
          {hg.headers.map(column => {
            // @ts-ignore
            if (column?.key === 'checkbox') {
              return (
                <th {...column.getHeaderProps()}>
                  <Group position="center" className={classes.control}>
                    {column.render('Header', { isDataFiltered })}
                  </Group>
                </th>
              )
            }

            return (
              <th {...column.getHeaderProps()} align="left">
                {Object.keys(context).length ? (
                  <ThContent column={column} {...context}>
                    {column.render('Header')}
                  </ThContent>
                ) : (
                  <div style={{ flexGrow: 0 }}>
                    <Text weight={500} size="sm" p="none" align="left">
                      {column.render('Header')}
                    </Text>
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      )),
    [context.state, context.dispatch, headerGroups]
  )

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        {context?.state?.isPagination && data?.length > 0 && (
          <Group mb="sm" align="center" grow>
            <Pagination {...context} itemsPerPage={context.state.itemsPerPage} />
          </Group>
        )}
        <Table {...getTableProps()}>
          <thead>{renderedHeaders}</thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              return (
                <ExpandableRow
                  key={`tr-expandable${row?.original['@id']}`}
                  row={row}
                  prepareRow={prepareRow}
                  expandElement={expandElement}
                  provideContext={provideContext}
                />
              )
            })}
            {rows.length === 0 && (
              <tr>
                <td colSpan={100} align="center" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="table_noResult" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Divider mb="md" variant="dotted" />
      {context?.state?.isPagination && data?.length > 0 && (
        <Group mt="sm" align="center" grow>
          <Pagination {...context} itemsPerPage={context.state.itemsPerPage} />
        </Group>
      )}
    </div>
  )
}

export default TableExpendable
