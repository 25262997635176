import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { useIntlLight } from '../../i18n'

export const TABLE_NAVIGATE_ACTION = {
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
  UPDATE: 'update',
}

export interface IMutation {
  title?: string
  message?: string
}

export interface IUpdate extends IMutation {
  queryFunc: (entity: any, url: any) => any
}

export interface IDelete extends IMutation {
  queryFunc: (url: any) => any
}

export interface IUseCrud {
  queryKey: string
  deleteAction: IDelete
  updateAction: IUpdate
  pathPrefix: string
}

export interface IHandleNavigate {
  action: string
  row: any | null
}

const useCrud = ({ queryKey, deleteAction, updateAction, pathPrefix }: IUseCrud) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { t } = useIntlLight()

  const updateMutation = useMutation(
    (entity: any) => updateAction.queryFunc(entity, entity['@id']),
    {
      onSuccess: dataMutation => {
        queryClient.invalidateQueries([queryKey]).then(() => null)
      },
    }
  )

  const deleteMutation = useMutation((iri: string) => deleteAction.queryFunc(iri), {
    onSuccess: dataMutation => {
      if (deleteAction.title && deleteAction.message) {
        showNotification({
          title: deleteAction.title,
          message: deleteAction.message,
          color: 'green',
        })
      }
      queryClient.invalidateQueries([queryKey]).then(() => null)
    },
    onError: (ee: any) => {
      showNotification({
        title: t('systemBase.showNotification.systemMessageLabel'),
        message: t('delete_fk_409_error_msg'),
        color: 'red',
      })
    },
  })

  const handlerNavigate = ({ action, row = null }: IHandleNavigate): any => {
    if (action === TABLE_NAVIGATE_ACTION.EDIT) {
      navigate(`${pathPrefix}/${row.id}/edit`)
    }
    if (action === TABLE_NAVIGATE_ACTION.DELETE) {
      return deleteMutation.mutateAsync(row['@id'])
    }
    if (action === TABLE_NAVIGATE_ACTION.VIEW) {
      navigate(`${pathPrefix}/${row.id}`)
    }
    if (action === TABLE_NAVIGATE_ACTION.UPDATE) {
      return updateMutation.mutateAsync(row)
    }
    return null
  }

  return { handlerNavigate }
}

export default useCrud
