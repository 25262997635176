import React from 'react'
import { Text, Box, Progress } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons'
import { useIntlLight } from '../../../i18n'

export interface PasswordRequirementProps {
  value: string | null
}

const requirements = [
  { regex: /.{14,}/, label: 'passwordRequirement_minChar' },
  { regex: /\d/, label: 'passwordRequirement_number' },
  { regex: /[a-z]/, label: 'passwordRequirement_lowercase' },
  { regex: /[A-Z]/, label: 'passwordRequirement_uppercase' },
  { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'passwordRequirement_specialChar' },
]

const getStrength = (password: string) => {
  let multiplier = password.length > 5 ? 0 : 1

  requirements.forEach(requirement => {
    if (!requirement.regex.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const CheckList = ({ value }: PasswordRequirementProps) => {
  const { t } = useIntlLight()
  const strength = getStrength(value ?? '')
  // eslint-disable-next-line no-nested-ternary
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red'

  return (
    <>
      {requirements.map((requirement, index) => {
        const key = `PasswordRequirement${index}`
        const meets = requirement.regex.test(value ?? '')
        return (
          <Text
            key={key}
            color={meets ? 'teal' : 'red'}
            sx={{ display: 'flex', alignItems: 'center' }}
            mt={7}
            size="sm"
          >
            {meets ? <IconCheck size="0.9rem" /> : <IconX size="0.9rem" />}{' '}
            <Box ml={10}>{t(requirement.label)}</Box>
          </Text>
        )
      })}
      <Progress color={color} value={strength} size={5} mt="sm" />
    </>
  )
}

export default CheckList
