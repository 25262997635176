import { DatePicker as BaseDatePicker } from '@mantine/dates'

interface DatePicker {
  className?: string
  name?: string
  label?: string
  placeholder?: string
  required?: boolean
  inputFormat?: string
  error?: any
  value?: Date | null
  onChange?: ((value: Date | null) => void) | undefined
}

export default function DatePicker({
  className,
  name,
  label,
  placeholder,
  required,
  inputFormat,
  error,
  value,
  onChange,
}: DatePicker) {
  return (
    <BaseDatePicker
      className={className}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      inputFormat={inputFormat}
      error={error}
      value={value}
      onChange={onChange}
    />
  )
}
