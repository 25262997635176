import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ActionIcon, Anchor, Button, Group, Popover, TextInput } from '@mantine/core'
import { BiFilterAlt as FilterIcon } from 'react-icons/bi'
import useStringFilterStyles from './useStringFilterStyles'
import { useIntlLight } from '../../../../../i18n'

const StringFilter = ({ columnName, state, dispatch }: any) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [opened, setOpened] = useState(false)
  const filter = state.filters.find((_filter: any) => _filter.property === columnName) || {
    value: '',
  }
  const handleClose = () => {
    setOpened(false)
  }

  const handleClear = () => {
    dispatch({ type: 'SET_FILTER', payload: { property: columnName, value: '' } })
    setOpened(false)
  }

  const handleApply = useCallback(() => {
    dispatch({
      type: 'SET_FILTER',
      payload: { property: columnName, value: inputRef?.current?.value || '' },
    })
    setOpened(false)
  }, [dispatch, columnName])

  const { classes } = useStringFilterStyles({
    isFilterEnabled: Boolean(filter?.value),
  })

  const { t } = useIntlLight()

  useEffect(() => {
    if (opened) {
      inputRef?.current?.focus()
      inputRef?.current?.addEventListener('keyup', event => {
        if (event.key !== 'Enter') return
        handleApply()
      })
    }
  }, [opened, inputRef, handleApply])

  return (
    <Popover opened={opened} onClose={handleClose} position="bottom" transition="scale-y">
      <Popover.Target>
        <ActionIcon
          className={classes.filterIconButton}
          onClick={() => setOpened(o => !o)}
          mr="xs"
          color={filter?.value ? 'blue' : 'gray'}
          variant="filled"
        >
          <FilterIcon />
        </ActionIcon>
      </Popover.Target>
      {opened && (
        <Popover.Dropdown>
          <TextInput
            ref={inputRef}
            placeholder={t('all_enter_text')}
            mb="sm"
            defaultValue={filter.value}
            autoComplete="off"
          />
          <Group position="apart">
            <Button color="red" onClick={handleClear} disabled={!filter.value}>
              {t('all_clear')}
            </Button>

            <Button onClick={handleApply}>{t('all_apply')}</Button>
          </Group>
        </Popover.Dropdown>
      )}
    </Popover>
  )
}

export default StringFilter
