import { IPermit } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetPermitsQuery<D extends IPermit[]>(params?: HydraQueryParams<D>) {
  const { vars, options } = params || {}
  return useHydraQuery<D>('permits', '/api/permits', {
    vars,
    options,
  })
}
