import { ICountrySubdivision } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetCountrySubdivisionsQuery<D extends ICountrySubdivision[]>(
  params?: HydraQueryParams<D>
) {
  const { vars, options, key } = params || {}
  return useHydraQuery<D>('country_subdivisions', '/api/country_subdivisions', {
    vars,
    options,
    key,
  })
}
