import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Menu } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons'
import { FileDownload, FileSpreadsheet } from 'tabler-icons-react'
import { utils, writeFile } from 'xlsx'
import fileDownload from 'js-file-download'
import { TableContext } from '../../providers/TableProvider'
import { getApi } from '../../../api-client'
import { useIntlLight } from '../../../i18n'
import {
  ExcelDownloadButton2Props,
  ExcelExportColumn,
  ExcelExportGroupedColumn,
} from '../../../ui-molecules/components/ActionButton/Inline'

export const ExcelValueFormatter = {
  standard: (value: string) => value,
  date: (value: string) => {
    if (!value || value.length === 0) return ''
    const date = new Date(value)
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return date.toLocaleDateString('fr-CA')
  },
  datetime: (value: string) => {
    if (!value || value.length === 0) return ''
    const date = new Date(value)
    return `${date.toLocaleDateString('fr-CA')} ${date.toLocaleTimeString('fr-CA', {
      hour: '2-digit',
      minute: '2-digit',
      second: undefined,
    })}`
  },
  yesNo: (t: (key: string, params?: Record<string, any>) => string) => {
    return (value: string) => (value === '1' ? t('yes') : t('no'))
  },
  activeInactive: (t: (key: string, params?: Record<string, any>) => string) => {
    return (value: string) =>
      value === '1' ? t('typeBase_badgeLabelActive') : t('typeBase_badgeLabelInactive')
  },
}

const ExcelDownloadButton2 = ({
  currentPageDataUrl,
  fileTitle,
  exportStructure,
  allDataUrl,
}: ExcelDownloadButton2Props) => {
  const { state } = useContext(TableContext)
  const { t } = useIntlLight()
  const currentPageFetchUrl = currentPageDataUrl ?? state.path
  const currentPageCsvFetchUrl = `${currentPageFetchUrl.split('?')[0]}/csv/export?${
    currentPageFetchUrl.split('?')[1]
  }`
  const originalSearchParam = currentPageFetchUrl.split('?')[1]
  const sp = new URLSearchParams(originalSearchParam)
  const currentPageNum = sp.get('page') ?? 1
  sp.delete('page')
  sp.delete('itemsPerPage')
  sp.set('pagination', 'false')
  const allPageCsvFetchUrl = `${currentPageCsvFetchUrl.split('?')[0]}?${sp.toString()}`

  const fetchCsv = async (url: string) => {
    showNotification({
      id: 'fetch-csv-request',
      loading: true,
      message: <FormattedMessage id="excelDownload_notification_inProgress" />,
      autoClose: false,
      disallowClose: true,
    })
    const response = await getApi({
      Accept: 'text/csv',
    })
      .get(url)
      .then(response => response.data)
      .catch(error => {
        if (error && error?.response?.status) {
          updateNotification({
            id: 'fetch-csv-request',
            message: <FormattedMessage id="serviceGenericError" />,
            color: 'red',
            icon: <IconX size={16} />,
          })
        } else {
          updateNotification({
            id: 'fetch-csv-request',
            message: <FormattedMessage id="serviceGenericError" />,
            color: 'red',
            icon: <IconX size={16} />,
          })
        }
      })

    return response ?? null
  }

  const getExcelFromCsvStr = (
    data: string | null,
    title: string,
    structure: (ExcelExportColumn | ExcelExportGroupedColumn)[]
  ) => {
    if (data === null) return
    const date = new Date()
    const formattedDate = `${date.getFullYear()}${
      date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
    }${date.getDay() < 10 ? `0${date.getDay()}` : date.getDay()}`

    const formattedHour = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`

    const [headers, ...rows] = data
      .split('\n')
      .map(row => row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/))

    // Je ne sais pas pq on a toujours une ligne vide
    rows.pop()

    const headerRows: string[] = []
    const rowMapping: { index: number; formatter: (value: string) => string }[] = []

    structure.forEach(item => {
      if ('propertyPrefix' in item) {
        let i = 0
        let notFound = false
        while (!notFound) {
          // eslint-disable-next-line no-loop-func
          item.columns.forEach(subItem => {
            const property = `${item.propertyPrefix}.${i}.${subItem.property}`
            const index = headers.indexOf(property)
            if (index !== -1) {
              const label =
                subItem.headerLabel instanceof Function
                  ? subItem.headerLabel((i + 1).toString())
                  : subItem.headerLabel
              headerRows.push(label)
              rowMapping.push({
                index,
                formatter: subItem.formatter ?? ExcelValueFormatter.standard,
              })
            } else {
              notFound = true
            }
          })
          i += 1
        }
        return
      }

      const index = headers.indexOf(item.property)
      if (index !== -1 && !(item.headerLabel instanceof Function)) {
        headerRows.push(item.headerLabel)
        rowMapping.push({ index, formatter: item.formatter ?? ExcelValueFormatter.standard })
      }
    })

    const dataSet = []
    dataSet.push(headerRows)

    const dataRow = rows.map(row => {
      return rowMapping.map(item =>
        item.formatter(
          row[item.index]
            ?.replaceAll(/^"|"$/g, '')
            .replace(/(?<!\\)\\n/g, '\n')
            .replace(/\\\\n/g, '\\n')
        )
      )
    })
    dataSet.push(...dataRow)

    const ws = utils.aoa_to_sheet(dataSet)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Data')

    writeFile(wb, `${title}-${formattedDate}_${formattedHour}.xlsx`)
    updateNotification({
      id: 'fetch-csv-request',
      title: <FormattedMessage id="excelDownload_notification_title" />,
      message: <FormattedMessage id="excelDownload_notification_message" />,
      color: 'teal',
      icon: <IconCheck size={16} />,
    })
  }

  const getExcelFromJson = (data: any | null, title: string) => {
    if (data === null) return
    const date = new Date()
    const formattedDate = `${date.getFullYear()}${
      date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
    }${date.getDay() < 10 ? `0${date.getDay()}` : date.getDay()}`

    const formattedHour = `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`

    // On skip les headers, car c'est un feature qui retourne le json simplifié :
    // [["header1","header2"],["value1","value2"],["value1","value2"]]
    const ws = utils.json_to_sheet(data, { skipHeader: true })
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Data')

    writeFile(wb, `${title}-${formattedDate}_${formattedHour}.xlsx`)
    updateNotification({
      id: 'fetch-json-request',
      title: <FormattedMessage id="excelDownload_notification_title" />,
      message: <FormattedMessage id="excelDownload_notification_message" />,
      color: 'teal',
      icon: <IconCheck size={16} />,
    })
  }

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button size="xs" leftIcon={<FileSpreadsheet size={18} />}>
          {t('excelDownload_btnLabel')}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <>
          <Menu.Item
            icon={<FileDownload size={14} />}
            onClick={async () =>
              getExcelFromCsvStr(
                await fetchCsv(currentPageCsvFetchUrl),
                `${fileTitle}-Page${currentPageNum}`,
                exportStructure
              )
            }
          >
            {t('excelDownload_currentPage_btnLabel')}
          </Menu.Item>
          <Menu.Item
            icon={<FileDownload size={14} />}
            onClick={async () =>
              getExcelFromCsvStr(
                await fetchCsv(allPageCsvFetchUrl),
                `${fileTitle}-PageAll`,
                exportStructure
              )
            }
          >
            {t('excelDownload_allPage_btnLabel')}
          </Menu.Item>
          {allDataUrl && (
            <Menu.Item
              icon={<FileDownload size={14} />}
              onClick={() => {
                showNotification({
                  id: 'fetch-csv-request',
                  loading: true,
                  message: <FormattedMessage id="excelDownload_notification_inProgress" />,
                  autoClose: false,
                  disallowClose: true,
                })

                getApi({
                  Accept: 'text/csv',
                })
                  .get(allDataUrl)
                  .then(response => {
                    fileDownload(response.data, `${fileTitle}-AllData.csv`)
                    updateNotification({
                      id: 'fetch-csv-request',
                      title: <FormattedMessage id="excelDownload_notification_title" />,
                      message: <FormattedMessage id="excelDownload_notification_message" />,
                      color: 'teal',
                      icon: <IconCheck size={16} />,
                    })
                  })
                  .catch(error => {
                    if (error && error?.response?.status === 500) {
                      updateNotification({
                        id: 'fetch-csv-request',
                        message: <FormattedMessage id="serviceGenericError" />,
                        color: 'red',
                        icon: <IconX size={16} />,
                      })
                    }
                  })
              }}
            >
              {t('excelDownload_allData_btnLabel')}
            </Menu.Item>
          )}
        </>
      </Menu.Dropdown>
    </Menu>
  )
}

export default ExcelDownloadButton2
