import React from 'react'
import { Anchor, Box, Navbar as BaseNavbar, ScrollArea } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
// https://tabler-icons-react.vercel.app/ Pour trouver les icône
import {
  Building,
  Calendar,
  Category,
  Dashboard,
  Database,
  Id,
  IdBadge2,
  Key,
  LayoutDashboard,
  LayoutGrid,
  LayoutList,
  License,
  Users,
  Writing,
} from 'tabler-icons-react'
import { LinksGroup } from '../../libs/ui-molecules'
import useAuth from '../../hooks/useAuth'
import useNavbarStyles from './useNavbarStyles'
import { ROLE } from '../../types'
import { isRoleMapaqRoOrAbove } from '../../providers/AuthProvider/AuthProvider'

const subMenuDashboard = (role: string) => ({
  label: 'dashboard_title',
  icon: Dashboard,
  link: '#',
  initiallyOpened: false,
  links: [
    {
      ...(role === ROLE.ROLE_DETENTEUR_PERMIS && {
        label: 'dashboard_roleDetenteurPermisTitle',
        link: '/dashboard/permitholder',
        icon: LayoutDashboard,
      }),
    },
    {
      ...(isRoleMapaqRoOrAbove(role as ROLE) && {
        label: 'dashboard_roleAdminMapaqTitle',
        link: '/dashboard/admin',
        icon: LayoutDashboard,
      }),
    },
    {
      ...(role === ROLE.ROLE_VET && {
        label: 'dashboard_roleVeterinarianTitle',
        link: '/dashboard/veterinarian',
        icon: LayoutDashboard,
      }),
    },
  ].filter(item => Object.keys(item).length),
})

const subMenuAdminDeclaration = {
  label: 'declaration.titleLister',
  icon: Writing,
  link: '/declaration/list',
}

const subMenuDeclaration = {
  label: 'declaration.titleLister',
  icon: Writing,
  link: '#',
  initiallyOpened: false,
  links: [
    { label: 'declaration.titleLister', link: '/declaration/list', icon: LayoutList },
    {
      label: 'pendingDeclaration.titleLister',
      link: '/pending_declaration/list',
      icon: LayoutList,
    },
    {
      label: 'declarationSiteUpdateTitleLister',
      link: '/declaration_site_update/list',
      icon: LayoutList,
    },
    {
      label: 'menu_menuItem_declarationModels_label',
      link: '/declaration_template_lot/list',
      icon: LayoutGrid,
    },
  ],
}

const subMenuPermitRoleAdmin = {
  label: 'permit.title',
  icon: Id,
  link: '/permit/list',
}

const subMenuPermitRoleUser = {
  label: 'permit.title',
  icon: Id,
  link: '/permit/list',
}

const subMenuApiKey = {
  label: 'navbar_applicationKeyManagement',
  icon: Key,
  link: '/user_api_key_creator/list',
}

const subMenuApiKeyAdmin = {
  label: 'navbar_applicationKeyManagement',
  icon: Key,
  link: '/user_api_key',
}

const subMenuOmvqEstablishmentCode = {
  label: 'omvqEstablishmentCode_subMenuTitle',
  icon: Building,
  link: '/omvq_establishment_code/list',
}

const subMenuVetDeclaration = {
  label: 'vetDeclaration_titleLister',
  icon: Writing,
  link: '/vet_declaration/list',
}

// const subMenuSecurity = {
//   label: 'navLink.securityLabel',
//   icon: Lock,
//   link: '#',
//   initiallyOpened: false,
//   links: [
//     { label: 'navLink.change_password', link: '/' },
//     { label: 'navLink.recovery_codes', link: '/' },
//   ],
// }

const subMenuUser = { label: 'navLink.userLabel', icon: Users, link: '/users/list' }
const subMenuSystemType = {
  label: 'navLink.manageTypeLabel',
  icon: Category,
  link: '#',
  initiallyOpened: false,
  links: [
    { label: 'product.titleLister', link: '/product/list', icon: LayoutList },
    { label: 'productType.titleLister', link: '/product_type/list', icon: LayoutList },
    { label: 'medicationType.titleLister', link: '/medication_type/list', icon: LayoutList },
    { label: 'species.titleLister', link: '/species/list', icon: LayoutList },
    { label: 'medication.titleLister', link: '/medication/list', icon: LayoutList },
    { label: 'activeIngredient.titleLister', link: '/active_ingredient/list', icon: LayoutList },
    { label: 'productionStage.titleLister', link: '/production_stage/list', icon: LayoutList },
    { label: 'omvqVeterinarian_subMenuLabel', link: '/omvq_veterinarian/list', icon: LayoutList },
    { label: 'omvqEstablishment_subMenuLabel', link: '/omvq_establishment/list', icon: LayoutList },
  ],
}

const subMenuSystemTypeDetenteurPermis = {
  label: 'navLink.manageTypeLabel',
  icon: Category,
  link: '#',
  initiallyOpened: false,
  links: [
    { label: 'product.titleLister', link: '/product/list', icon: LayoutList },
    { label: 'medication.titleLister', link: '/medication/list', icon: LayoutList },
    { label: 'activeIngredient.titleLister', link: '/active_ingredient/list', icon: LayoutList },
    { label: 'productionStage.titleLister', link: '/production_stage/list', icon: LayoutList },
  ],
}

const subMenuSystem = {
  label: 'navbar_system',
  icon: Database,
  link: '#',
  initiallyOpened: false,
  links: [
    { label: 'navLink.userLabel', link: '/users/list', icon: Users },
    { label: 'useTerm_subMenuLabel', link: '/use_term/list', icon: License },
    {
      label: 'regulationDateLimit_subMenuLabel',
      link: '/regulation_date_limit/list',
      icon: Calendar,
    },
  ],
}

const subMenuDetenteurPermis = {
  label: 'navbar_permitHolderSection',
  icon: Id,
  link: '#',
  initiallyOpened: false,
  links: [
    { label: 'declaration.titleLister', link: '/declaration/list', icon: Writing },
    { label: 'permit.title', link: '/permit/list', icon: IdBadge2 },
    { label: 'navbar_applicationKeyManagement', link: '/user_api_key', icon: Key },
  ],
  isSubMenuExpandable: false,
}

const subMenuVeterinarian = {
  label: 'navbar_veterinarianSection',
  icon: Building,
  link: '#',
  initiallyOpened: false,
  links: [
    { label: 'declaration.titleLister', link: '/vet_declaration/list', icon: Writing },
    {
      label: 'omvqEstablishmentCode_subMenuTitle',
      link: '/omvq_establishment_code/list',
      icon: Building,
    },
    { label: 'navbar_applicationKeyManagement', link: '/404', icon: Key },
  ],
  isSubMenuExpandable: false,
}

const menuRoleAdmin = [
  subMenuDetenteurPermis,
  subMenuVeterinarian,
  subMenuSystemType,
  subMenuSystem,
]

const menuRoleMapaq = [
  subMenuAdminDeclaration,
  subMenuPermitRoleAdmin,
  subMenuApiKeyAdmin,
  subMenuSystemType,
  subMenuSystem,
]

const menuRoleDetenteurPermis = [
  subMenuDeclaration,
  subMenuPermitRoleUser,
  subMenuApiKey,
  subMenuSystemTypeDetenteurPermis,
]

const menuRoleVeterinarian = [
  {
    label: 'declaration.titleLister',
    icon: Writing,
    link: '/vet_declaration/list',
  },
  {
    label: 'navbar_applicationKeyManagement',
    icon: Key,
    link: '#',
  },
  subMenuOmvqEstablishmentCode,
  subMenuSystemTypeDetenteurPermis,
]

const menuRoleUser = menuRoleDetenteurPermis

export interface NavbarProps {
  openedSide: boolean
  opened: boolean
  handlers(): any
}

const getMenu = (key: any) => {
  switch (key?.role) {
    case ROLE.ROLE_ADMIN:
      return [subMenuDashboard(ROLE.ROLE_ADMIN), ...menuRoleAdmin]
    case ROLE.ROLE_SUPPORT:
      return [subMenuDashboard(ROLE.ROLE_SUPPORT), ...menuRoleAdmin]
    case ROLE.ROLE_MAPAQ:
    case ROLE.ROLE_MAPAQ_RO:
      return [subMenuDashboard(ROLE.ROLE_MAPAQ), ...menuRoleMapaq]
    case ROLE.ROLE_VET:
      return [subMenuDashboard(ROLE.ROLE_VET), ...menuRoleVeterinarian]
    case ROLE.ROLE_DETENTEUR_PERMIS:
      return [subMenuDashboard(ROLE.ROLE_DETENTEUR_PERMIS), ...menuRoleDetenteurPermis]
    default:
      return [subMenuDashboard(ROLE.ROLE_DETENTEUR_PERMIS), ...menuRoleUser]
  }
}

const Navbar: React.FC<NavbarProps> = ({ openedSide, opened, handlers }) => {
  const { classes } = useNavbarStyles()
  const { user } = useAuth()
  const navigate = useNavigate()
  if (!openedSide) {
    return null
  }

  const menu = getMenu(user)
  const links = menu.map(item => <LinksGroup {...item} key={item.label} handlers={handlers} />)

  return (
    <BaseNavbar
      width={{ sm: 300 }}
      hidden={!opened}
      hiddenBreakpoint="md"
      p="md"
      className={classes.navbar}
      withBorder={false}
    >
      <BaseNavbar.Section grow className={classes.links} component={ScrollArea}>
        <Box className={classes.logoWrapper}>
          <Anchor onClick={() => navigate('/')}>
            <img className={classes.logo} alt="logo" src="/image/Logo_AntibiotiQC.png" />
          </Anchor>
        </Box>
        <div className={classes.linksInner}>{links}</div>
      </BaseNavbar.Section>
    </BaseNavbar>
  )
}
export default Navbar
