import { Text } from '@mantine/core'
import { IPermit, useGetCountrySubdivisionsQuery } from '../../../api-client'

const PermitAddressFormat = ({ permit }: { permit?: IPermit }) => {
  const {
    data: { member: countrySubdivision },
  } = useGetCountrySubdivisionsQuery()

  if (!permit || !countrySubdivision) return <div />

  const province = countrySubdivision.find(c => c['@id'] === permit.countrySubdivision)

  return (
    <>
      <Text>
        {permit.entityCivicNumber}
        {permit.entityUnitNumber && ` #${permit.entityUnitNumber}`}, {permit.entityStreetName}
      </Text>
      <Text>{permit.entityCityName}</Text>
      <Text>
        {province && province.name}, {province && province.countryName}
      </Text>
      <Text>{permit.entityPostalCode?.toUpperCase()}</Text>
    </>
  )
}

export default PermitAddressFormat
