import { getApi } from '../../libs/api-client'

export interface IUseTermHistory {
  userAccount: string
  useTerm: string
}

export function createUseTermHistory(data: IUseTermHistory): Promise<any> {
  return getApi().post<IUseTermHistory>('/api/use_term_histories', data)
}
