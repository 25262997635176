import { Paper, Text, TextInput, Button, Container, Group, Input } from '@mantine/core'
import { FieldError, useForm } from 'react-hook-form'
import { showNotification, updateNotification } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { appliedErrorToForm } from '../../../../services/FormViolations'
import { useIntlLight } from '../../../../libs/i18n'
import schema from './validationSchema'
import useAuth from '../../../../hooks/useAuth'

interface PasswordForgotRequestFormProps {
  onSubmit(): void
}

interface PasswordForgotRequestFormFormProps {
  email: string
}

const PasswordForgotRequestForm = ({ onSubmit }: PasswordForgotRequestFormProps) => {
  const navigate = useNavigate()
  const { trans } = useIntlLight()
  const { forgetPassword } = useAuth()

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<PasswordForgotRequestFormFormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  const translateErrors = (err: FieldError | undefined): string => {
    if (!err || !err.message) return ''
    if (err.type === 'custom') return err.message
    return trans(err.message)
  }

  const myOnSubmit = (value: any) => {
    showNotification({
      id: 'reset-password',
      loading: true,
      message: trans('forgotPasswordRequest_resetAttempt'),
      autoClose: false,
      disallowClose: true,
    })
    forgetPassword(value.email)
      .then(response => {
        if (response.status === 202) {
          updateNotification({
            id: 'reset-password',
            color: 'teal',
            message: trans('forgotPasswordRequest_emailSent'),
            icon: <IconCheck size={16} />,
            autoClose: true,
          })
          onSubmit()
        }
      })
      .catch((error: any) => {
        appliedErrorToForm(getValues(), error, setError)
        updateNotification({
          id: 'reset-password',
          message: trans('forgotPasswordRequest_resetAttemptFailed'),
          color: 'red',
          icon: <IconX size={16} />,
        })
      })
  }

  return (
    <Container size="xs" my={30}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <Text size="lg" weight={500} mb={12} align="center">
          <FormattedMessage id="forgotPasswordRequest_title" />
        </Text>
        <Text color="dimmed" size="sm" align="left">
          <FormattedMessage id="forgotPasswordRequest_instructions" />
        </Text>
        <form onSubmit={handleSubmit(myOnSubmit)}>
          <Input.Wrapper
            label={trans('forgotPasswordRequest_emailLabel')}
            error={translateErrors(errors?.email)}
            required
          >
            <TextInput
              id="not-necessary"
              placeholder={trans('forgotPasswordRequest_emailLabel')}
              {...register('email')}
            />
          </Input.Wrapper>
          <Group position="apart" mt="lg">
            <Button type="button" color="red" onClick={() => navigate('/login')}>
              <FormattedMessage id="systemBase.back" />
            </Button>
            <Button type="submit">
              <FormattedMessage id="forgotPasswordRequest_submitRequest" />
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  )
}

export default PasswordForgotRequestForm
