import React from 'react'
import { Card, Group, Title } from '@mantine/core'
import { ISystemTypeBase } from '../../../../services/request/assets'
import { BadgeActive } from '../BadgeActive'

export interface ICardTypeBaseProps {
  entity: ISystemTypeBase
}

const CardTypeBase = ({ entity }: ICardTypeBaseProps) => {
  return (
    <Card shadow="sm" p="xs" radius="md" m="sm" withBorder key={entity.code}>
      <Group position="apart" mt="md" mb="xs">
        <div>
          <Title order={6}>
            {entity.nameFr} ({entity.code})
          </Title>
          <BadgeActive active={entity.active} />
        </div>
      </Group>
    </Card>
  )
}

export default CardTypeBase
