export function normalizeMemberI18nFields(
  member: Record<string, any> | Record<string, any>[],
  currentLanguage: string
) {
  const pascaledLanguage = currentLanguage
    .toLocaleLowerCase()
    .replace(/(\w)(\w*)/g, (_g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())

  const isArray = Array.isArray(member)
  const preNormalizedMember = !isArray ? [member] : member
  const postNormalizedMember = preNormalizedMember.map(member => {
    if (typeof member === 'string') {
      return member
    }

    const memberFields = Object.keys(member)
    const normalizedMember = { ...member }
    memberFields.forEach(memberField => {
      const regex = new RegExp(`.*${pascaledLanguage}$`)
      if (!regex.test(memberField)) {
        return
      }

      const value = normalizedMember[memberField]
      const normalizedField = memberField.replace(pascaledLanguage, '')
      normalizedMember[normalizedField] = value
    })

    return normalizedMember
  })

  return isArray ? postNormalizedMember : postNormalizedMember[0]
}
