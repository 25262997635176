import { Text, Footer as BaseFooter, useMantineColorScheme, Anchor } from '@mantine/core'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import useStyles from './Footer.styles'
import { ValidUseTermContainer } from '../../libs/ui-molecules'

export default function Footer() {
  const { colorScheme } = useMantineColorScheme()
  const isDarkMode = colorScheme === 'dark'
  const { classes } = useStyles()
  const { formatMessage } = useIntl()
  const [showUseTerm, setShowUseTerm] = useState(false)

  return (
    <BaseFooter className={classes.footer} height={170} p="md" withBorder>
      <Text>
        <ul className={classes.copyRightUl}>
          <li className={classes.copyRightLi}>
            <Anchor
              href="https://www.mapaq.gouv.qc.ca/fr/Avis/Pages/Politique.aspx"
              target="_blank"
              rel="noreferrer"
            >
              {formatMessage({ id: 'footer_politique' })}
            </Anchor>
          </li>
          <li className={classes.copyRightLi}>
            <Anchor onClick={() => setShowUseTerm(true)}>
              {formatMessage({ id: 'footer_condition' })}
            </Anchor>
          </li>
        </ul>
      </Text>
      <ValidUseTermContainer
        useTermType="UTILISATEUR"
        showModal={showUseTerm}
        closeModal={() => setShowUseTerm(false)}
      />
      <a className="" href="https://www.quebec.ca/" target="_blank" rel="noreferrer">
        <img
          className={classes.logoQC}
          src={isDarkMode ? '/image/QUEBEC_blanc.svg' : '/image/QUEBEC_couleur.svg'}
          alt=""
        />
      </a>
      <Text
        color="dimmed"
        size="sm"
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            { id: 'home.copyright' },
            {
              year: `${new Date().getFullYear()}`,
            }
          ),
        }}
      />
      <Text color="dimmed" size="sm">
        Version : {process.env.REACT_APP_VERSION}
      </Text>
    </BaseFooter>
  )
}
