import { IDeclarationRead } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetDeclarationQuery<D extends IDeclarationRead>(params?: HydraQueryParams<D>) {
  const { vars, options, key } = params || {}
  const { id } = vars as { id: string }
  return useHydraQuery<D>(key ?? `getDeclaration${id}`, `/api/declarations/${id}`, {
    vars,
    options,
  })
}
