import { Paper, Text, Button, Container, Group } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const PasswordForgotRequestInstructions = () => {
  const navigate = useNavigate()

  return (
    <Container size="xs" my={30}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <Text size="lg" weight={500} mb={12} align="center">
          <FormattedMessage id="passwordForgotRequestInstructions_title" />
        </Text>
        <Text color="dimmed" size="sm" align="left">
          <FormattedMessage id="passwordForgotRequestInstructions_instructions" />
        </Text>
        <Group position="apart" mt="lg">
          <Button type="button" onClick={() => navigate('/')}>
            <FormattedMessage id="passwordForgotRequestInstructions_goHome" />
          </Button>
          <Button type="button" onClick={() => navigate('/login')}>
            <FormattedMessage id="passwordForgotRequestInstructions_goLogin" />
          </Button>
        </Group>
      </Paper>
    </Container>
  )
}

export default PasswordForgotRequestInstructions
