import { useEffect, useMemo } from 'react'
import { Input, Select } from '@mantine/core'
import { Controller, Control } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetCountrySubdivisionsQuery } from '../../../api-client'

export interface ProductTypeSelectProps {
  name: string
  label?: string
  defaultValue?: string
  error?: string
  required: boolean
  control?: Control<any, any>
  disabled?: boolean
  placeholder?: string
  countryCode?: 'US' | 'CA'
  mb?: 'sm'
}

const CountrySubdivisionSelect = ({
  name,
  label,
  defaultValue,
  error,
  required = true,
  control,
  disabled,
  placeholder,
  countryCode = 'CA',
  mb,
}: ProductTypeSelectProps) => {
  const {
    data: { member },
    refetch,
  } = useGetCountrySubdivisionsQuery({
    vars: {
      pagination: 'false',
      countryCode,
    },
    options: {
      enabled: false,
    },
  })

  const options = useMemo(() => {
    const data = member
      ?.filter(countrySubdivision => countrySubdivision.active)
      .map(countrySubdivision => {
        return { value: countrySubdivision?.code, label: countrySubdivision?.name }
      })

    return data || []
  }, [member])

  useEffect(() => {
    refetch()
  }, [countryCode, refetch])

  return (
    <Input.Wrapper label={label} error={error} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <Select
              placeholder={placeholder}
              data={options}
              defaultValue={defaultValue ?? ''}
              value={value}
              onChange={onChange}
              clearable
              searchable
              disabled={disabled}
              label={label}
              mb={mb}
            />
          )
        }}
      />
    </Input.Wrapper>
  )
}

export default CountrySubdivisionSelect
