// en_CA.ts

const messages = {
  'default.welcome': 'Welcome to my app!',
  Unknown: 'Unknow',
  chooseSelectLabel: 'Choose',
  loading: 'loading',
  yes: 'Yes',
  no: 'No',
  or: 'or',
  debug_section_title: 'Section de Debug',
  debug_create_fe_error: 'Créer une erreur Front End',
  debug_create_be_error: 'Créer une erreur Back End',
  deletion: 'Deletion',
  deletionGenericMessage: 'Are you sure you want to delete the item?',
  deletionGenericMessageMultiple: 'Are you sure you want to delete these items?',
  pageLoaderLabel: 'Loading...',
  clickHere: 'cliquez ici',
  dialogDeleteWithHash: 'Please paste this code in the following field',
  search: 'Search',
  declarations_productionSite_sitePermitNumber: 'N° {sitePermitNumber}',
  declarations_productionSite_siteNumber: 'Permis {siteNumber}',
  declarations_productionSite_verification_start:
    'You have already entered coordinates for the {prefix} :',
  declarations_productionSite_verification_end: 'Do you want to use these coordinates?',
  declarations_veterinaire_licence_notValid: 'The veterinary license field must be 4 numerics',
  declarationEmbeddedTable_createdBy: 'Created by',
  declarations_dropdownMenu_duplicate: 'Clone a declaration',
  declarations_menu_button_duplicate: 'Clone',
  declarations_dropdownMenu_delete: 'Delete',
  declarations_dropdownMenu_saveModel: 'Save as template',
  declaration_stepper_toolTip_stepSite_siteNumber:
    'La plateforme AntibiotiQC est configurée pour valider le numéro de traçabilité du site pour les bovins, les ovins et les cervidés qui sont visés par le Règlement sur l’identification et la traçabilité de certains animaux, découlant de la Loi sur la protection sanitaire des animaux. Dans l’environnement de test préproduction, pour ces espèces seuls les numéros suivants sont acceptés : QC0000000, QC1111111, QC2222222, QC3333333, QC4444444, QC5555555, QC6666666, QC7777777, QC8888888 et QC9999999. ',
  all_save: 'Save',
  all_close: 'Close',
  address: {
    lineOne: 'Address Line 1',
    lineTwo: 'Address Line  2',
    address: 'Address',
    city: 'City',
    province: 'Province',
    state: 'State',
    postalCode: 'Postal Code',
    country: 'Country',
    unitNumber: 'App.',
    civicNumber: 'Civic N°',
    streetName: 'Street Name',
    poBox: 'PO box',
    stationInformation: 'Succursale',
    ruralRoute: 'Rural Route',
    dividerOthersFields: 'Other address fields',
    globalValidation:
      'Vous devez remplir une de ces conditions:  ligne adresse 1, Boîte postale, Route rurale ou numéro et nom de rue',
  },
  systemBase: {
    back: 'Cancel',
    languages: {
      fr: 'French',
      en: 'Anglais',
    },
    prevStep: 'Previous',
    showNotification: {
      systemMessageLabel: 'Message du système',
      serverValidationFailed: 'Server validation error',
    },
    tableLister: {
      deleteSelection: 'Delete',
      deleteSelectionMessage: 'Are you sure you want to delete selected items?',
      labelActions: 'Actions',
      labelDesactiver: 'Deactivated',
      labelDesactiverConfirmed: 'Are you sure you want to deactivated?',
      labelActiver: 'Activated',
      labelActiverConfirmed: 'Are you sure you want to activated?',
      labelMenuPopupSearch: 'Search',
      labelMenuPopupApp: 'Application',
      labelMenuPopupItemSelected: 'Selected items',
      filterDateSelect: 'Enter date',
      filterDateMax: 'Maximum Date',
      filterDateMin: 'Minimum Date',
    },
  },
  home: {
    title: 'AntibiotiQC',
    introduction: {
      one: `Bienvenue sur la version bêta d'<b>AntibiotiQC</b>, la plateforme de monitorage de l'utilisation des antibiotiques
      en santé animale au Québec, une initiative du ministère de l'Agriculture, des Pêcheries et de l'Alimentation (MAPAQ).
      La mise en service de la plateforme commencera au début de l'année 2024. Dans un premier temps, elle servira aux détenteurs de
      permis relatif aux prémélanges médicamenteux et aliments médicamenteux à transmettre les registres des ventes et des fournitures des prémélanges et aliments
      médicamenteux. Des modifications aux articles 14 et 15 du Règlement sur les prémélanges médicamenteux et les aliments médicamenteux
      destinés aux animaux, découlant de la Loi sur la protection sanitaire des animaux, obligent la transmission annuelle des registres.`,
      two: "La transmission des registres devra se faire en utilisant l'une des méthodes suivantes :",
      three:
        "Par le biais d'une interface API (en anglais, Application Programming Interface), ou;",
      four: `Au moyen d'un formulaire Web de saisie manuelle.`,
      five: `Vous pouvez consulter la documentation pour la transmission par API en cliquant sur <b>Documentation API</b>. Au cours de l'année 2023, un
      environnement de test préproduction sera offert. À partir de ce moment, vous pourrez utiliser les fonctions <b>Inscrivez-vous</b> et <b>Connectez-vous</b>.
      Le MAPAQ vous informera le moment venu.`,
    },
    section_one: `Bienvenue sur la version bêta d'<b>AntibiotiQC</b>, la plateforme de monitorage de l'utilisation des antibiotiques en santé animale au Québec, une initiative du ministère de l'Agriculture, des Pêcheries et de l'Alimentation (MAPAQ). La mise en service de la plateforme débutera au début de l'année 2024. Dans un premier temps, elle permettra aux détenteurs de permis relatif aux prémélanges médicamenteux et aliments médicamenteux de transmettre les registres des ventes et des fournitures des prémélanges et aliments médicamenteux. Les modifications apportées aux articles 14 et 15 du <em>Règlement sur les prémélanges médicamenteux et les aliments médicamenteux destinés aux animaux</em>, en vertu de la <em>Loi sur la protection sanitaire des animaux</em>, rendent obligatoire la transmission annuelle de ces registres.`,
    section_three_dev: `Un <b>programme d'aide financière</b> pour les détenteurs de permis souhaitant procéder aux ajustements requis de leur système informatique pour la transmission de leurs registres entrera en vigueur le 14 août 2023. Il est recommandé aux détenteurs de permis intéressés à soumettre une demande d'aide financière de consulter les modalités du programme avant de débuter les travaux.`,
    section_four_dev: `Vous pouvez consulter la documentation pour la transmission par API en cliquant sur <b>Documentation API</b>. Au cours du mois de septembre 2023, un environnement de test préproduction sera mis à disposition. À partir de ce moment, vous pourrez {url} pour obtenir les informations nécessaires à la création d'un compte utilisateur dans l'environnement de test préproduction. Vous pourrez ensuite utiliser les fonctions <b>Inscrivez-vous</b> et <b>Connectez-vous</b>.`,
    section_four_dev_linkText: `contacter le Soutien technique d'Attestra`,
    section_four_prod: `Vous pouvez consulter la documentation pour la transmission par API en cliquant sur <b>Documentation API</b>.`,
    section_five_dev: `Le développement de la plateforme se poursuivra jusqu'à sa mise en service, prévue pour le début de l'année 2024. Par conséquent, nous vous recommandons de rafraîchir les pages Web en appuyant simultanément sur les touches Ctrl et F5 lors de vos visites.`,
    moreInfo: {
      intro: "Pour plus d'informations :",
      one: 'En savoir plus sur le Règlement et ses nouvelles exigences',
      two: 'Document informatif sur les données à transmettre au MAPAQ',
      three: `Programme d'aide financière destinés aux détenteurs de permis relatif aux prémélanges médicamenteux et aliments médicamenteux`,
    },
    support: {
      intro: 'Soutien aux utilisateurs :',
      one: `Pour les questions d'ordre technique, {url}.`,
      two: `Pour les questions relatives à l'application réglementaire ou pour toute autre question, {url}.`,
      three: `Pour les questions relatives au programme d'aide financière destiné aux détenteurs de permis, {url}.`,
      linkOneText: `écrivez à l'équipe du Soutien technique d'Attestra`,
      linkTwoText: `communiquez avec l'équipe responsable du monitorage des antibiotiques au ministère de l'Agriculture, des Pêcheries et de l'Alimentation`,
      linkThreeText: `communiquez avec l'équipe d'Attestra responsable de la mise en œuvre du programme`,
    },
    copyright: '© Gouvernement du Québec ({year}) - Reproduit sous licence',
    login_button: 'Log In',
    register_button: 'Sign Up',
    doc_url: 'API Documentation',
    builtBy: '<b>AntibiotiQC</b> is developed by Attestra',
  },
  signup: {
    title: 'Sign up!',
  },
  signupConfirm: {
    welcome:
      "*Bienvenue sur AntibiotiQC, vous venez de recevoir un code à l'adresse courriel d'inscription, veuillez confirmer votre identité en saisissant le code dans la boîte ici bas.",
    enterCode: 'Enter the code',
    btnNext: 'Go to AntibiotiQC',
  },
  signup_recaptchaFailed: "*L'inscription a échouée",
  login: {
    title: 'Welcome to AntibiotiQC',
    divider_label: 'Déjà inscrit',
    name_label: 'Name',
    name_placeholder: 'your name',
    email_label: 'Email',
    password_label: 'Password',
    password_placeholder: 'your password',
    password_confirmation_label: 'Confirm password',
    password_noMatch: 'password does not match',
    email_placeholder: 'your@email.com',
    forget_password_label: 'Forgot your password?',
    button: 'login',
    dont_have_account_label: 'Create a new account',
    or: 'or',
    loginAttempt: 'Login attempt',
    loginSuccess: 'Login successfully',
    wrongLoginCredentials: 'Invalid username/password',
  },
  register: {
    title: 'Sign In',
    dividerPersonalInfos: 'Account infos',
    dividerPermitInfos: 'Permit access code',
    dividerTerms: 'You must accept terms and conditions',
    button: 'Sign In',
    terms_label: 'Accept terms and conditions',
  },
  formErrorBase: {
    required: 'This field is required',
    validNumber: 'Enter a number',
    positiveNumber: 'Enter a positive number',
    integer: 'Enter an integer',
    maxLength255: '255 characters maximum',
    maxLength50: '50 characters maximum',
    maxLength25: '25 characters maximum',
    maxLength15: '15 characters maximum',
    postalCode: '*Postal code format: X0X 0X0',
    postalCode_CA: 'The code format must be: X0X 0X0',
    postalCode_US: 'The code format must be: 12345 or 12345-6789',
    email: 'Email must be valid',
    phone: 'Phone must be valid',
    passwordMin: 'The password must contains at least 14 characters',
    acceptTerms: 'You have to check to accept terms and conditions',
  },
  formErrorBase_endDateGreatherThanStartDate: 'End date must be greather than start date',
  typeBase: {
    labelName: 'Name',
    labelNameFr: 'French name',
    labelNameEn: 'English name',
    labelCode: 'Code',
    labelActive: 'Active',
    labelBtnSave: 'Save',
    allTransmit: 'Transmettre',
    labelBtnAdd: 'Add',
    productType: 'Product Type',
    medicationType: 'Medication Type',
    species: 'Species',
    product: 'Products',
    badgeLabelActive: 'Active',
    badgeLabelInactive: 'Inactive',
    cancelConfirmDirtyForm: 'The unsaved infomartions will be lost',
    labelBtnCancel: 'Cancel',
    labelBtnCancelConfirm: 'Are you sure you want to delete? ',
    labelBtnConfirm: 'Confirm',
    LabelBtnResetFilter: 'Reset Filters',
    LabelBtnResetSort: 'Reset Ordering',
    LabelBtnResetDashboardFilter: 'Reset Dashbord Filters',
    messageActivatedSuccess: 'Items has been succesfully activated',
    messageDeactivatedSuccess: 'Items has been succesfully deactivated',
    messageEditSuccess: 'Items has been succesfully modified',
  },
  typeBase_labelActive: 'Status',
  typeBase_badgeLabelActive: 'Active',
  typeBase_badgeLabelInactive: 'Inactive',
  typeBase_statusDate: 'Status Date',
  typeBase_createdAt: 'Created Date',
  productType: {
    titleLister: 'Product Types',
    titleFormAdd: 'Add a product type',
    titleFormEdit: 'Edit a product type',
    messageAddSuccess: 'Product type successfully added',
    messageEditSuccess: 'Product type successfully modified',
    messageDeleteSuccess: 'Product types successfully deleted',
    messageActivatedSuccess: 'Product types successfully activated',
    messageDeactivatedSuccess: 'Product types successfully deactivated',
  },
  product: {
    titleLister: 'Products',
    titleFormAdd: 'Add a product',
    titleFormEdit: 'Edit a product',
    messageAddSuccess: 'Product successfully added',
    messageEditSuccess: 'Product successfully modified',
    messageDeleteSuccess: 'Product successfully deleted',
    messageActivatedSuccess: 'Products successfully activated',
    messageDeactivatedSuccess: 'Products successfully deactivated',
  },
  medicationType: {
    labelAtcVetCode: 'Actual Code',
    titleLister: 'Medication Type',
    titleFormAdd: 'Add a medication type',
    titleFormEdit: 'Edit a medication type',
    messageAddSuccess: 'Medication type successfully added',
    messageEditSuccess: 'Medication type successfully modified',
    messageDeleteSuccess: 'Medication type successfully deleted',
    messageActivatedSuccess: 'Medications types successfully activated',
    messageDeactivatedSuccess: 'Medications types successfully deactivated',
    tableListerListIngredient: 'Ingredients List',
    tableListerAddIngredient: 'Add an ingredient',
    tableListerHeaderTypeIngredient: 'Ingredient type',
    tableListerHeaderConcentration: 'Concentration',
    tableListerHeaderUnit: 'Unit',
    tableListerRemoveIngredient: 'Remove ingredient',
  },
  medicationType_tableListerHeaderDinNumber: 'Numéro DIN',
  medicationType_tableListerHeaderStartDate: 'Date de début',
  medicationType_tableListerHeaderEndDate: 'Date de fin',
  medicationType_tableListerRemoveDin: '*Retirer un numéro DIN',
  medicationType_tableListerAddDin: "*Ajouter un numéro d'identification de médicament (DIN)",
  medicationType_excelName: 'MedicationTypes',
  species: {
    labelOfficialSite: '*Numéro de site règlementé',
    labelCanHaveSite: 'Can have site',
    titleLister: 'Species',
    titleFormAdd: 'Add a species',
    titleFormEdit: 'Edit a species',
    messageAddSuccess: 'Species successfully added',
    messageEditSuccess: 'Species successfully modified',
    messageDeleteSuccess: 'Species successfully deleted',
    messageActivatedSuccess: 'Species successfully activated',
    messageDeactivatedSuccess: 'Species successfully deactivated',
  },
  medication: {
    labelShortName: 'Short name',
    labelShortNameFr: 'French short name',
    labelShortNameEn: 'English short name',
    titleLister: '*Médicaments commerciaux',
    titleFormAdd: '*Ajouter un médicament commercial',
    titleFormEdit: '*Modifier un médicament commercial',
    medicationActiveIngredientsUnitPpm: 'PPM',
    medicationActiveIngredientsUnitG_Kg: 'g/kg',
    messageAddSuccess: '*Le médicament commercial a été ajouté avec succès',
    messageEditSuccess: '*Le médicament commercial a été modifié avec succès',
    messageDeleteSuccess: '*Le médicament commercial a été supprimé avec succès',
    messageActivatedSuccess: '*Les médicaments commerciaux ont été activés avec succès',
    messageDeactivatedSuccess: '*Les médicaments commerciaux ont été désactivés avec succès',
    labelOrdonnanceQc: '*Ordonnance QC',
  },
  medicationFormError_dimNumberFormat: 'Din number must contains digits only',
  productionStage: {
    labelUnitLimit: 'Unit limit',
    labelPrecision: 'Precision mandatory',
    titleLister: 'Production Stages',
    titleFormAdd: 'Add a production stage',
    titleFormEdit: 'Edit a production stage',
    messageAddSuccess: 'Production stage successfully added',
    messageEditSuccess: 'Production stage successfully modified',
    messageDeleteSuccess: 'Production stage successfully deleted',
    messageActivatedSuccess: 'Productions stage successfully activated',
    messageDeactivatedSuccess: 'Productions stage successfully deactivated',
    unitLimit: {
      kg: 'kg',
      days: 'Days',
    },
  },
  productionStage_excelName: 'ProductionStage',
  productionStage_canHaveSite_formLabel:
    "Peut avoir un site (si ce champ est laissé vide, c'est la valeur de l'espèce qui sera utilisée)",
  productionStage_canHaveSite_listerLabel: '*Peut avoir un site',
  productionStage_officialSite_formLabel:
    "N° de site règlementé (si ce champ est laissé vide, c'est la valeur de l'espèce qui sera utilisée)",
  productionStage_officialSite_listerLabel: '*N° de site règlementé',
  activeIngredient: {
    titleLister: 'Active Ingredients',
    titleFormAdd: 'Add an active ingredients',
    titleFormEdit: 'Edit an active ingredients',
    messageAddSuccess: 'Active ingredient successfully added',
    messageEditSuccess: 'Active ingredient successfully modified',
    messageDeleteSuccess: 'Active ingredient successfully deleted',
    messageActivatedSuccess: 'Active ingredients successfully activated',
    messageDeactivatedSuccess: 'Active ingredients successfully deactivated',
    labelOrdonnanceQc: '*Ordonnance QC',
  },
  activeIngredient_excelName: 'ActiveIngredients',
  permitNumber_validation_badFormat:
    'Wrong permit format, should be [1 to 10 digits]-[1 to 5 digits]-[1 to 3 digits]',
  permit: {
    title: 'Permits',
    titleLister: 'Permit list',
    titleFormAdd: 'Add a permit',
    titleFormEdit: 'Edit a permit',
    messageAddSuccess: 'Permit successfully added',
    messageEditSuccess: 'Permit successfully modified',
    messageDeleteSuccess: 'Permit successfully deleted',
    messageActivatedSuccess: 'Permits successfully activated',
    messageDeactivatedSuccess: 'Permits successfully deactivated',
    labelEmail: 'Email',
    labelPhone: 'Telephone',
    labelSocialReason: 'Social Reason',
    labelSiteName: 'Site Name',
    labelPermitNumber: 'Permit Number',
    systemMessageTitleSearchNoResult: 'No result',
    systemMessageTitleSearchResultFound: 'Results founds',
    systemMessageSearchNoResult: 'No permit found',
    autocompletePlaceholder: 'Search permit',
  },
  permit_permitTypeLabel: 'Permit Type',
  permit_importFormCsv_formTitle: 'Import Permit',
  permit_importFormCsv_importTypeLabel: 'Import type',
  permit_importFormCsv_importTypePublic: 'Public list',
  permit_importFormCsv_importFileLabel: 'File (csv)',
  permit_importFormCsv_btnLabel: 'Import (csv)',
  permit_importFormCsv_sendingFile: '*Importation en cours',
  permit_importFormCsv_fileTreated: '*Importation terminée',
  permit_importFormCsv_serverError: "*L'importation a échouée",
  permit_importFormCsv_permitInserted:
    '{count} {count,plural,one{permis inséré}other{permis insérés}}',
  permit_importFormCsv_permitUpdated:
    '{count} {count,plural,one{permis modifié}other{permis modifiés}}',
  permit_importFormCsv_permitError: '{count} permis en erreur',
  permit_importFormCsv_permitEmptyEntry:
    'Le fichier CSV contient des lignes vides, ce qui signifie que seules certaines données ont été importées',
  permitCode_titleLister: 'Access codes',
  permitCode_titleFormAdd: 'add an access code',
  permitCode_titleFormEdit: 'Edit an access code',
  permitCode_messageAddSuccess: 'Access code successfully added',
  permitCode_messageEditSuccess: 'Access code successfully modified',
  permitCode_messageDeleteSuccess: 'Access code successfully deleted',
  permitCode_messageDeleteConfirmation:
    'Are you sure you want to delete this code from your permit?',
  permitCode_messageActivatedSuccess: 'Access codes successfully activated',
  permitCode_messageDeactivatedSuccess: 'Access codes successfully deactivated',
  permitCode_labelCodeType: 'Type',
  permitCode_labelCode: "Code d'accès",
  permitCode_labelPermit: 'Permit',
  permitCode_labelUsedDate: 'Activation date',
  permitCode_labelCreatedDate: 'Created date',
  permitCode_labelUserAccount: 'User',
  permitCode_codeType_ADMIN: 'Administrator',
  permitCode_codeType_USER: 'Delegate',
  permitCode_codeType_API_KEY: 'API key',
  permitCode_chooseCodeType: 'Choose type of code',
  permitCode_chooseApiKey: 'Choose API Key',
  permitCodeActivation: {
    navTitle: 'Link a premit to my profile',
    labelPermitNumber: 'Permit number',
    labelCode: 'Access code',
    formTitle: 'Enter the received informations',
    messageActivationError: 'This code is already accesed',
    messageActivationSuccess: 'Acces to permit has been granted',
  },
  declaration: {
    titleLister: 'Declarations',
    labelDeclarationDate: 'Transaction',
    labelDeclarationCreatedAt: 'Transmission',
    labelDeclarationRecipientName: 'Recipient',
    labelPermit: 'Declarant Permit',
    siteProduction: 'Production site',
    messageDeleteSuccess: 'The declaration successfully deleted',
    labelDeclarationProduct: 'Product',
    editTitle: 'Edit a declaration',
  },
  declaration_labelDeclarationType: 'Type de déclaration',
  declaration_labelDeclarationType_SALE_SUPPLY: 'Vente ou fourniture',
  declaration_labelDeclarationType_RETURN: 'Retour',
  declaration_createdWebForm_web: 'Formulaire Web',
  declaration_createdWebForm_api: 'API',
  pendingDeclaration: {
    titleLister: 'Pernding déclaration',
    labelDeclarationLastModified: 'Saved Date',
    labelDeclarationRecipientName: 'Client',
    labelPermit: 'Permit',
    messageAddSuccess: 'The state has added successfully',
    messageEditSuccess: 'The state has modified successfully',
    messageDeleteSuccess: 'The state has deleted successfully',
  },
  dashboard_title: 'Dashboard',
  dashboard_roleDetenteurPermisTitle: 'Permit holder',
  dashboard_roleAdminMapaqTitle: 'Administration',
  dashboard_roleVeterinarianTitle: 'Veterinarian',
  navLink: {
    userLabel: 'Users',
    securityLabel: 'Security',
    pagesLabel: 'Pages',
    listerLabel: 'List',
    addLabel: 'Add',
    manageTypeLabel: 'Base Datas',
    change_password: 'Change password',
    recovery_codes: 'Retreive your codes',
    label_404: '404',
  },
  table: {
    reset_filter_button: 'Reset filters',
    header: {
      active: 'Active',
      action: 'Actions',
    },
  },
  table_noResult: 'No result',
  user: {
    titleLister: 'Users',
    titleFormAdd: 'Add a user',
    titleFormEdit: 'Edit a user',
    messageAddSuccess: 'User successfully added',
    messageEditSuccess: 'User successfully modified',
    messageDeleteSuccess: 'User successfully deleted',
    messageActivatedSuccess: 'Users successfully activated',
    messageDeactivatedSuccess: 'Users successfully deactivated',
    labelName: 'Name',
    labelEmail: 'Email',
    labelPhone: 'Telephone',
    labelLanguage: 'Language',
    labelRole: 'Role',
    errorRole: 'You must entrer  at least 1 role',
    roles: {
      roleUser: 'User',
      roleAdmin: 'Administrator',
      roleSupport: 'Technical support',
      roleMapaq: 'MAPAQ',
      roleDetenteurPermis: '*Détenteur de permis',
      ROLE_VET: 'Veterinarian',
    },
    labelConsentCommunication: 'Attestra/MAPAQ Communication',
    consentCommunication: {
      authorized: 'Authorized',
      nonAuthorized: 'Unauthorized',
    },
    labelStatus: 'Status',
    status: {
      INACTIVE: 'Inactive',
      ACTIVE: 'Active',
      PENDING_ACTIVATION: 'Pending',
    },
    table: {
      titleLister: 'Users',
      header: {
        email: 'Email',
        name: 'Name',
      },
    },
  },
  pagination: {
    rows_per_page: 'Rows per page',
    page: 'Page',
    pageOf: 'of',
    total_items: 'Total items',
  },
  forgotPasswordRequest_title: 'Reset password request',
  forgotPasswordRequest_instructions:
    '*Veuillez saisir votre courriel pour réinitialiser votre mot de passe ou compléter votre inscription si vous n’avez pas eu le temps de saisir votre code d’activation reçu par courriel. Vous recevrez un courriel et pour confirmer votre identité, il faudra suivre le lien qui expire dans 10 minutes. Vous pourrez réinitialiser votre mot de passe et activer votre accès à AntibiotiQC.',
  forgotPasswordRequest_emailLabel: 'Email',
  forgotPasswordRequest_submitRequest: 'Send',
  forgotPasswordRequest_emailSent: 'An email has been sent',
  forgotPasswordRequest_resetAttempt: '*Mot de passe en cours de réinitialisation',
  forgotPasswordRequest_resetAttemptFailed: 'The request failed',
  passwordForgotRequestInstructions_instructions:
    '*Vous allez recevoir un courriel de réinitialisation de mot de passe. Veuillez suivre les instructions du courriel.',
  passwordForgotRequestInstructions_goHome: 'Go back to home',
  passwordForgotRequestInstructions_goLogin: 'Go back to login',
  passwordForgotRequestInstructions_title: '*Demande de réinitialisation de mot de passe envoyée',
  passwordReset_title: 'Reset your password',
  passwordReset_attempt: '*Mot de passe en cours de réinitialisation',
  passwordReset_success: '*Mot de passe réinitialisé',
  passwordReset_failed: '*Échec de la réinitialisation',
  forgotPassword_passwordLabel: 'New password',
  forgotPassword_submitReset: 'Reset',
  declarationStepper: {
    recipientStepLabel: 'Recipient',
    recipientStepDesc: 'Recipient',
    siteStepLabel: 'Site',
    siteStepDesc: 'Production site',
    productStepLabel: 'Product',
    productStepDesc: 'Product type',
    medicationStepLabel: 'Medications',
    medicationStepDesc: 'Medications',
    speciesStepLabel: 'Species',
    speciesStepDesc: 'Species',
    declarationStepLabel: 'Decalaration',
    declarationStepDesc: 'Decalaration',
    veterinaireStepLabel: 'Veterinarian',
    veterinaireStepDesc: 'Veterinarian',
    nextStepBtn: 'Next',
    btnEdit: 'Edit',
    btnDelete: 'Delete',
    recentLabel: 'Recents',
    mostUsedLabel: 'Most Used',
    declarationDateLabel: 'Sale or Supply Date',
    declarationPermitLabel: 'Declaration Permit',
    quantity: 'Quantity',
    concentration: 'Concentration',
    lastDateUsed: 'Last date used',
    nbUsed: 'Used time',
    stepperStateSaved: 'État sauvegardé',
    concentrationUnit: 'Unit',
    concentrationUnitPPM: 'ppm',
    concentrationUnitMg_Kg: 'mg/kg',
    concentrationUnitG_T: 'g/ton',
    concentrationUnitKG_T: 'kg/ton',
    animalQtySourceType: {
      PRESCRIPTION: 'Prescription',
      CLIENT_REGISTRY: 'Client Registry',
      OTHER: 'Other',
    },
    providerSection: '*Information supplémentaire sur la vente ou fourniture',
    providerNumber: 'Provider Number',
    providerNote: 'Provider Note',
    stepRecipient: {
      title: 'Client',
      recipientSelected: 'Client selected',
      recipients: 'Clients',
      addRecipient: '*Ajouter un client pas encore mémorisé',
      editRecipient: 'Edit an existing client',
      recipientNameLabel: 'Name',
      emptyRecentListLabel: 'No client found',
    },
    stepSite: {
      title: '*Site receveur du produit médicamenteux',
      sitesSelected: '*Sites selected',
      sites: 'Sites',
      addSite: '*Ajouter un site pas encore mémorisé',
      editSite: 'Edit site',
      siteNumberLabel: 'Site number',
      emptyRecentMostUsedListLabel: 'No site found',
      sitePermitNumberLabel: 'Site permit number',
      sitePermitNumberFormatError: 'Wrong permit format',
    },
    stepProduct: {
      title: '*Détails des produits médicamenteux',
      qtyKgLabel: '*Quantité (kg)',
      productLabel: '*Produit',
      addProduct: '*Ajouter un produit',
      productionStageLabel: '*Espèce',
      animalQtyLabel: "*Nombre d'animaux",
      animalQtyLabelShort: 'Number',
      animalQtySourceLabel: 'Source',
      sourceInfos: "*Source pour le nombre d'animaux",
      addProductBtn: 'Add a product',
      editProduct: 'Modify a product',
      noProduct: 'No product selected',
      site: 'Site',
      productSelected: 'Selected products',
      otherQtySourceInfoLabel: 'Other source infos',
      animalInfos: 'Animals Infos',
      animalQuantityMax: 'You can not entered more than 10000000 animals',
      productQuantityMax: 'You can not entered more than 1000000 kg',
      productQuantityMin: 'You can not entered more than -1000000 kg',
      speciesPrecision: 'Specify',
      animalMinLimitDays: 'Minimum days',
      animalMaxLimitDays: 'Maximum days',
      animalMinLimitKg: 'Minimum kg',
      animalMaxLimitKg: 'Maximum kg',
      animalMaxLimitKgGreatherThanMin: 'Maximum must be greather than minimum',
      maxQty: 'You can not entered more than 1000',
    },
    stepMedication: {
      title: '*Médicaments commerciaux',
      concentrationLabel: '*Concentration kg/t',
      qtyKgLabel: '*Quantité en kg',
      calculatedKgLabel: 'kg quantity',
      addActiveIngredient: '*Ajouter un ingrédient actif',
      addActiveIngredientBtnLabel: '*Ingrédient actif',
      editActiveIngredient: '*Modifier un ingrédient actif',
      medications: '*Médicamenta commerciaux',
      medication: '*Médicament commercial',
      ingredients: '*Ingrédients',
      ingredient: 'Active ingredient',
      btnAddMedication: '*Médicament commercial',
      addMedication: '*Ajouter un médicament commercial',
      editMedication: '*Modifier un médicament commercial',
      maxQtyKg: 'You can not entered more than 1000000 kg',
      maxConcentration: 'You can not entered more than 1000000',
      minConcentration: 'You can not entered les than 0.001',
    },
    stepVeterinaire: {
      title: 'Prescribing veterinarians',
      vetName: 'Name',
      vetLicenseNumber: 'License number',
      prescriptionDate: "*Date d'émission de la prescription",
      addVeterinaire: 'Add an unsaved veterinarian',
      editVeterinaire: 'Edit a veterinarian',
      veterinaires: 'Veterinary doctors',
    },
    stepDeclaration: {
      title: 'Declaration',
      date: 'Date',
      permit: 'Permit',
      recipient: 'Recipient',
      sites: 'Sites',
      product: 'Products',
      medicationIngredient: 'Medication/Ingredient',
      declarationSaved: 'A declaration saved successfully',
      declarationSavedPlural: '{number} declarations saved successfully',
      declarationUpdated: 'Declaration modified successfully',
      declarationUpdateServerValidationFailed: 'The declaration is in error',
      serverValidationFailed: 'A declaration is in error',
      serverValidationFailedPlural: 'declarations are in error',
      veterinaire: 'Veterinarian',
    },
  },
  declarationStepper_cantDeclareWarning: `*Vous n'avez aucun permis valide pour déclarer.`,
  declarationStepper_declarationType: 'Declaration type',
  declarationStepper_declarationType_SALE_SUPPLY: 'Sale or supply',
  declarationStepper_declarationType_RETURN: 'Return',
  declarationStepper_declarationDateLabel_SALE_SUPPLY: 'Sale or supply date',
  declarationStepper_declarationDateLabel_RETURN: 'Return date',
  declarationStepper_stepMedication_addMedicationHelpTooltip_text:
    "Pour remplacer le médicament commercial par un ingrédient actif, il faut d'abord vider la liste de médicaments commerciaux. Ensuite, il faut sélectionner «&nbsp;Ajouter&nbsp;un&nbsp;ingrédient&nbsp;actif&nbsp;» et fournir les informations requises.",
  declarationStepper_stepMedication_addActiveIngredientHelpTooltip_text:
    "Pour remplacer l'ingrédient actif par un médicament commercial, il faut d'abord vider la liste des ingrédients actifs. Ensuite, il faut sélectionner «&nbsp;Ajouter&nbsp;un&nbsp;médicament&nbsp;commercial&nbsp;» et fournir les informations requises.",
  userAuthCard: {
    changePassword: 'Change Password',
    darkMode: 'Dark Mode',
    lightMode: 'Light Mode',
    signOut: 'Sign Out',
    settings: 'Settings',
    application: 'Application',
  },
  userAuthCard_viewProfile: 'View my profile',
  userAuthCard_profile: 'Profile',
  userApiKey_titleLister: 'API keys list',
  userApiKey_excelName: 'APIKeys',
  userApiKey_labelApiKey: "Clé d'API",
  userApiKey_labelName: 'Name',
  userApiKey_labelExpiresAt: 'Expires Date(1 year maximum)',
  userApiKey_labelCreatedBy: 'Created By',
  userApiKey_labelUser: 'Username',
  userApiKey_addEntityTitle: 'Add  an API key',
  userApiKey_editEntityTitle: 'Edit an API key',
  userApiKey_messageEditSuccess: 'API key successfully modified',
  userApiKey_messageAddSuccess: 'API key successfully added',
  userApiKey_messageDeleteSuccess: 'La clé a été supprimé avec succès',
  userApiKey_messageDeleteFailure: 'La clé ne peut pas être supprimée du système',
  userApiKey_newKeyPopupMessage: `
      *Veuillez cliquer sur le bouton pour copier la clé dans votre presse-papier et la sauvegarder de façon sécuritaire (voûte, etc.).
      <br />
      <br />
      Cette clé devra être ajoutée à l'entête (header) HTTP <b>X-AUTH-TOKEN</b> de vos requêtes vers l'API d'AntibiotiQC par votre logiciel.
      <br />
      <br />
  `,
  userApiKey_newKeyPopupMessageWaring:
    "*Cette clé n'est visible qu'une seule fois. Si vous la perdez, vous devrez en créer une nouvelle.",
  userApiKey_btnClosePlainKey: 'Close',
  userApiKey_newKeyCopy: 'Copy key',
  userApiKey_newKeyCopied: 'Copied key',
  userApiKey_roleLabel: 'Key Type',
  userApiKey_role_importateur_permis: 'Permit Importer',
  userApiKey_role_detenteur_permis: 'Permit Owner',
  confirmAccount: {
    start: 'Activating in progress',
    success: 'Account is activated!',
    failure: 'User invalid/Token expired',
  },
  quantity: {
    exceed: 'Total drug quantity exceeds product quantity',
  },
  dirtyPrompt_title: 'Leave the form',
  dirtyPrompt_message: 'Are you sure qui you want to leave the form? Your datas will be lost.',
  dirtyPrompt_btnConfirm: 'Leave',
  dirtyPrompt_btnCancel: 'Stay',
  passwordRequirement_minChar: 'Include at least 14 characters',
  passwordRequirement_number: 'Include an number',
  passwordRequirement_lowercase: 'Include a lowercase',
  passwordRequirement_uppercase: 'Include an uppercase',
  passwordRequirement_specialChar: 'Include a special charchater',
  declaration_addDeclaration_disableNotice: 'Check here to stop displaying this message',
  declaration_addDeclaration_globalNotice: `
    Vous devez déclarer uniquement les transactions (ventes ou fournitures) d'aliments ou de prémélanges médicamenteux effectuées à des utilisateurs finaux, <b>ce qui inclut les administrations faites à vos propres animaux.</b>
    <br /><br />
    <b>Les transactions intermédiaires ne sont pas à déclarer</b> (exemple : vente d'un fabricant à un distributeur dans le but que celui-ci revende le produit à des utilisateurs finaux).
  `,
  declarations_addDeclaration_recipientStep_recipientListHelptooltip_text: `
  Le client est l'acheteur dans le cas d'une vente (transaction financière), ou le receveur dans le cas d'une fourniture (transaction non financière) de l'aliment ou du prémélange médicamenteux.
  `,
  declarations_addDeclaration_recipientForm_nameHelpTooltip_text:
    'Indiquez le nom de la personne physique ou morale (entreprise ou organisme) qui achète ou qui reçoit les aliments ou les prémélanges médicamenteux.',
  declarations_addDeclaration_recipientForm_addressHelpTooltip_text:
    "Indiquez l'adresse principale du client. Notez que celle-ci peut, dans certains cas, différer de l'adresse de ses sites où sont livrés les produits.",
  declarations_addDeclaration_sitesList_tooltipHelp_text: `
    Il s'agit des sites où sont localisés les animaux auxquels sont destinés les prémélanges ou les aliments médicamenteux vendus ou fournis. <b>Notez que vous pouvez sélectionner plusieurs sites.</b>`,
  declarations_addDeclaration_siteForm_siteNumberHelpTooltip_text: `
    Dans le cadre d'une vente ou d'une fourniture de <b>prémélange</b> médicamenteux, le client doit obligatoirement avoir un permis relatif aux prémélanges médicamenteux et aliments médicamenteux relié au site concerné par la vente ou la fourniture.
    <br />
    <br />
    Si vous n'avez pas cette information, il vous est possible de la chercher sur le site Web du gouvernement du Québec. <a target="_blank" href="https://web.mapaq.gouv.qc.ca/bh/etablissements-sous-permis">Cliquez ici</>
  `,
  declarations_addDeclaration_siteForm_addressHelpTooltip_text:
    "Adresse où sont localisés les animaux auxquels sont destinés les prémélanges ou les aliments médicamenteux vendus ou fournis. Notez que celle-ci peut différer de l'adresse principale du client.",
  declarations_addDeclaration_siteForm_addressSection: '*Adresse du site',
  declarations_addDeclaration_siteForm_sameAddressSwitch:
    '*Utiliser la même adresse que le client pour ce site',
  declarations_addDeclaration_siteForm_notice: `
    <b>Coordonnées des sites de production animale</b>
    <br />
    <br />
    Bovin, ovin et cervidé : Le numéro de traçabilité du site (SimpliTRACE : XX9999999) est suffisant.
    <br />
    <br />
    Porc, volaille : L'adresse complète du site est requise. Le numéro de traçabilité du site (PorcTRACÉ : XX9999999 ou numéro de poulailler : [Format du numéro]) est souhaité.
    <br />
    <br />
    Autres espèces : L'adresse complète du site est requise.
    `,
  declarations_addDeclaration_siteForm_siteNameLabel: 'Site name',
  declarations_dashboard_filters_title: 'Dashboard filters: {suffix}',
  declarations_dashboard_filters_yearFilter_title: `Year {year}`,
  declarations_dashboard_filters_permitNumberFilter_title: `Permit Nᴼ {permitNumber}`,
  dashboard_statistics_tileButton_label: 'Consult the list',
  dashboard_statistics_permitsMonthlyCountBreakdown_sectionTitle: `Percentage (%) of registrations`,
  navbar_applicationKeyManagement: 'API Keys',
  excelDownload_btnLabel: 'Excel Export',
  excelDownloadDetail_btnLabel: 'Detailed Excel Export',
  permitType_P: "*Préparation d'un aliment médicamenteux",
  permitType_M: "*Préparation d'un prémélange médicamenteux ou d'un aliment médicamenteux",
  permitType_S: "*Vente ou fourniture d'un prémélange médicamenteux ou d'un aliment médicamenteux",
  permitType_V:
    "*Vente, fourniture et préparation d'un prémélange médicamenteux ou d'un aliment médicamenteux",
  excelDownload_currentPage_btnLabel: 'Export current page',
  excelDownload_allPage_btnLabel: 'Export all pages',
  excelDownload_notification_title: 'Export successful',
  excelDownload_notification_message: 'export is completed',
  excelDownload_notification_inProgress: `Export in progress`,
  accountActivation_title: '*Activation de votre compte',
  accountActivation_instructions:
    '*Veuillez saisir un mot de passe pour vous connecter sur AntibiotiQC',
  accountActivation_passwordLabel: 'Password',
  accountActivation_passwordRepeat_label: 'Password confirm',
  accountActivation_attempt: '*Activation en cours',
  accountActivation_success: '*Votre compte a été activé avec succès',
  accountActivation_failed: '*Activation échouée',
  userProfile_section_title: 'Profile details',
  userProfile_section_fullName: 'Fullname',
  userProfile_section_userName: 'Username',
  userProfile_section_resetPassword: 'Change your password',
  excel: {
    declaration: {
      id: 'Nᴼ',
      declarationDate: 'Déclaration',
      declarationPermit_permitNumber: 'No de permis du déclarant',
      declarationType: 'Type',
      sitePermitNumber: 'No de permis du site de production',
      recipientName: 'Nom du client',
      siteNumber: 'No de traçabilité du site',
      siteName: 'Nom du site',
      product_nameFr: 'Produit',
      productQty: 'Quantité (kg) du produit',
      productionStage_nameFr: 'Stade de production',
      productionStageOtherInfo: 'Autre information sur la stade',
      animalQtySource: `Source du nombre d'animaux`,
      animalQtySourceOtherInfo: 'Autre information sur la source',
      animalQty: `Nombre d'animaux`,
      animalAgeMinLimit: 'Limite âge minimale',
      animalAgeMaxLimit: 'Limite âge maximale',
      prescriptionDate: `Date d'émission de la prescription`,
      vetLicenseNumber: `No de permis d'exercice du médecin vétérinaire`,
      vetName: 'Nom du médecin vétérinaire',
      recipientCoordinate_address_addressFirst: 'Adresse 1 du client',
      recipientCoordinate_address_addressSecond: 'Adresse 2 du client',
      recipientCoordinate_address_unitNumber: `Numéro d'unité du client`,
      recipientCoordinate_address_civicNumber: 'Numéro de rue du client',
      recipientCoordinate_address_streetName: 'Nom de rue du client',
      recipientCoordinate_address_ruralRoute: 'Route rurale du client',
      recipientCoordinate_address_poBox: 'Boîte postale du client',
      recipientCoordinate_address_stationInformation: 'Information sur la station du client',
      recipientCoordinate_city: 'Ville du client',
      recipientCoordinate_postalCode: 'Code postal du client',
      recipientCoordinate_countrySubdivision_nameFr: 'Province du client',
      siteCoordinate_address_addressFirst: 'Adresse 1 du site de production',
      siteCoordinate_address_addressSecond: 'Adresse 2 du site de production',
      siteCoordinate_address_unitNumber: `Numéro d'unité du site de production`,
      siteCoordinate_address_civicNumber: 'Numéro de rue du site de production',
      siteCoordinate_address_streetName: 'Nom de rue du site de production',
      siteCoordinate_address_ruralRoute: 'Route rurale du site de production',
      siteCoordinate_address_poBox: 'Boîte postale du site de production',
      siteCoordinate_address_stationInformation: 'Information sur la station du site de production',
      siteCoordinate_city: 'Ville du site de production',
      siteCoordinate_postalCode: 'Code postal du site de production',
      siteCoordinate_countrySubdivision_nameFr: 'Province du site de production',
      providerNumber: 'Référence interne',
      providerNote: 'Note interne',
      createdWebForm: 'Mode de transmission',
      createdAt: 'Transmission',
      declarationIngredient_activeIngredient_nameFr: `Nom de l'ingrédient actif #{increment}`,
      declarationIngredient_qty: `Quantité de l'ingrédient actif #{increment}`,
      declarationIngredient_concentration: `Concentration de l'ingrédient actif #{increment}`,
      declarationIngredient_concentrationUnit: `Unité de mesure de l'ingrédient actif #{increment}`,
      declarationMedication_medication_nameFr: `Nom du médicament #{increment}`,
      declarationMedication_qty: `Quantité du médicament #{increment}`,
      declarationMedication_concentration: `Concentration du médicament #{increment}`,
      declarationMedication_concentrationUnit: `Unité de mesure du médicament #{increment}`,
    },
    productType: {
      code: 'Code',
      nameFr: 'Description',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
    },
    permit: {
      operatorNumber: `No de l'exploitant`,
      entityNumber: `No de l'entité`,
      mapaqPermitNumber: `No de permis`,
      permitYear: `Année du permis`,
      issueDate: `Délivrance`,
      expiryDate: `Expiration`,
      expiryDateLong: `Date d'expiration du permis`,
      permitStatus: `Status MAPAQ`,
      operatorEmail: `Courriel de l'exploitant`,
      operatorName: `Nom de l'exploitant`,
      operatorContact: `Contact de l'exploitant`,
      entitySocialReason: `Raison sociale de l'entité`,
      entityCommonName: `Appellation commune`,
      entityUnitNumber: `No d'appartement de l'entité`,
      entityCivicNumber: `No civique de l'entité`,
      entityFractionNumber: `No de fraction de l'entité`,
      entityStreetName: `Nom de rue de l'entité`,
      entityCityNumber: `Numéro de municipalité de l'entité`,
      entityCityName: `Municipalité de l'entité`,
      entityPostalCode: `Code postal de l'entité`,
      entityManager: `Responsable de l'entité`,
      entityManagerPhoneNumber: `Numéro de téléphone du responsable`,
      entityManagerPhoneExtension: `Poste téléphonique du responsable`,
      inspectionSection: `Secteur d'inspection`,
      entityType: `Type d'entité`,
      entityLongitude: `Longitude`,
      entityLatitude: `Latitude`,
      directionNumber: `No de la direction en charge`,
      directionName: `Nom de la direction en charge`,
      regionAdministrativeNumber: `No de l'administration régionale`,
      regionAdministrativeDescription: `Description de l'administration régionale`,
      localAdministrationNumber: `No de l'administration locale`,
      localAdministrationName: `Nom du bureau d'inspection`,
      mrc: `MRC`,
      operatorUnitNumber: `No d'unité de l'exploitant`,
      operatorCivicNumber: `No civique de l'exploitant`,
      operatorFractionNumber: `No de fraction de l'exploitant`,
      operatorStreetName: `Nom de rue de l'exploitant`,
      operatorPostalCode: `Code postal de l'exploitant`,
      operatorCityNumber: `No de municipalité de l'exploitant`,
      operatorCityName: `Municipalité de l'exploitant`,
      operatorPhoneNumber: `No téléphone de l'exploitant`,
      operatorPhoneExtension: `Poste téléphonique de l'exploitant`,
      entitySiteNumber: `No de site de l'entité`,
      permitType: `Catégorie de permis`,
      permitTypeMapaq: `Catégorie de permis MAPAQ`,
      permitNumber: `Numéro officiel de permis`,
      countrySubdivision_nameFr: `Province`,
      active: `Status`,
      entityStatus: `Statut d'entité`,
      statusDate: `Date du statut`,
      createdAt: `Transmission`,
      unitNumber: "N° d'appartement",
      streetNumber: 'N° civique',
      streetName: 'Rue',
      municipality: 'Municipalité',
      postalCode: 'Code postal',
      permitCodes_code: `Code d'accès #{increment}`,
    },
    medicationType: {
      code: 'Code',
      nameFr: 'Description',
      atcVetCode: 'Code actuel',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
    },
    species: {
      code: 'Code',
      nameFr: 'Description',
      officialSite: 'Numéro de site règlementé',
      canHaveSite: 'Peut avoir un site',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
    },
    productionStage: {
      productCode: `Code du produit #{increment}`,
      productName: `Nom du produit #{increment}`,
    },
    medication: {
      code: 'Code',
      nameFr: 'Nom',
      shortNameFr: 'Nom court',
      ordonnanceQc: 'Ordonnance QC',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
      medicationActiveIngredients_activeIngredient_code: `Code de l'ingrédient actif #{increment}`,
      medicationActiveIngredients_activeIngredient_nameFr: `Nom de l'ingrédient actif #{increment}`,
      medicationActiveIngredients_concentration: `Concentration de l'ingrédient actif #{increment}`,
      medicationActiveIngredients_unit: `Unité de mesure de l'ingredient actif #{increment}`,
      medicationDins_dinNumber: `DIN #{increment}`,
      medicationDins_startDate: `Date de début du DIN #{increment}`,
      medicationDins_endDate: `Date de fin du DIN #{increment}`,
    },
    user: {
      status: `Statut de l'inscription`,
      active: `Statut de l'utilisateur`,
    },
    vetDeclaration: {
      createdAt: 'Transmission',
      declarationDate: 'Transaction',
      declarationType: 'Type de déclaration',
      omvqEstablishment_id: 'Établissement de déclaration',
      vetLicenseNumber: `Permis du médecin vétérinaire`,
      vetName: 'Médecin vétérinaire',
      providerNumber: 'Nᴼ de référence interne',
      providerNote: 'Note interne',
    },
  },
  notificationMessage: {
    emptyMessage: 'Pas de nouvelles notifications',
    title_0: 'Nouvelle fonctionnalité : Modèle de déclaration groupé',
    title_1: "Nouvelle fonctionnalité : Duplication d'un groupe de déclaration",
    title_2: 'Nouvelle fonctionnalité : Modification de site',
    message_0:
      'Dans la liste des déclarations, sélectionner les déclarations souhaitées et cliquer sur le bouton “Sauvegarder comme modèle” dans la barre de menu des déclarations. Les modèles sont accessibles dans le sous-menu “Modèles”.',
    message_1:
      'Dans la liste des déclarations, sélectionner les déclarations souhaitées et cliquer sur le bouton “Dupliquer” dans la barre de menu des déclarations.',
    message_2:
      'Fonctionnalité dans le menu de gauche, filtrer les déclarations pour mettre à jour les informations de site du résultat.',
  },
  omvqVeterinarian_subMenuLabel: 'Vétérinaires (OMVQ)',
  omvqVeterinarian_tableLister: 'Veterinarians',
  omvqVeterinarian_permitNumber: 'Permit Number',
  omvqVeterinarian_name: 'Name',
  omvqVeterinarian_email: 'Email',
  omvqVeterinarian_status: 'OMVQ Status',
  omvqVeterinarian_startDate: 'Start Date',
  omvqVeterinarian_endDate: 'End Date',
  omvqEstablishment_subMenuLabel: 'Établissements (OMVQ)',
  omvqEstablishment_tableLister: 'Establishments',
  omvqEstablishment_name: 'Name',
  omvqEstablishment_address: 'Address',
  omvqEstablishment_phone: 'Phone',
  omvqVeterinarian_linkType: 'Link',
  omvqEstablishmentCode_tableLister: 'Établissements',
  omvqEstablishmentCode_subMenuTitle: 'Établissements',
  omvqEstablishmentCode_messageEditSuccess: "Le code d'accès a été modifié avec succès",
  omvqEstablishmentCode_messageDeleteSuccess: "Le code d'accès a été supprimé avec succès",
  omvqEstablishmentCode_messageDeleteConfirmation:
    "Êtes-vous certain de vouloir supprimer ce code d'accès?",
  omvqEstablishmentCode_messageAddSuccess: "Le code d'accès a été ajouté avec succès",
  omvqEstablishmentCode_labelCode: "Code d'accès",
  omvqEstablishmentCode_labelCodeType: 'Type',
  omvqEstablishmentCode_labelUserAccount: 'Utilisateur',
  omvqEstablishmentCode_codeType_OWNER: 'Propriétaire',
  omvqEstablishmentCode_codeType_VET: 'Locataire',
  omvqEstablishmentCode_codeType_NOT_VET: 'Gestionnaire',
  omvqEstablishmentCode_codeType_API_KEY: "Clé d'API",
  omvqEstablishmentCode_chooseCodeType: 'Choisir le type de code',
  omvqEstablishmentCode_chooseApiKey: "Choisir la clé d'API",
  signupProfile_veterinarian: '*Vétérinaire',
  signupProfile_veterinarianProfile:
    "*Si vous êtes un vétérinaire veuillez cliquer sur le bouton. Vous aurez besoin de votre courriel inscrit à l'OMVQ.",
  signupProfile_permitOwner: '*Détenteur de permis',
  signupProfile_permitOwnerProfile:
    "*Si vous êtes un détenteur de permis veuillez cliquer sur le bouton. Vous aurez besoin de votre numéro de permis et d'un code d'accès.",
  signupProfile_registerBtn: 'Sign up',
  signupProfile_whichProfil: '*Quelle description correspond à votre profil?',
  sw_notification_versionUpdate: `A new version is available.`,
  sw_notification_reload: `Reload`,
  navbar_system: 'Système',
  navbar_permitHolderSection: 'Détenteurs de permis',
  navbar_veterinarianSection: 'Vétérinaires',
  useTerm_subMenuLabel: `Conditions d'utilisation`,
  useTerm_titleLister: `Conditions d'utilisation`,
  useTerm_startPeriod: 'Date de début',
  useTerm_endPeriod: 'Date de fin',
  useTerm_termType: 'Type',
  useTerm_termType_UTILISATEUR: 'Utilisateur',
  useTerm_descriptionFr: `Conditions d'utilisation FR`,
  useTerm_descriptionEn: `Conditions d'utilisation EN`,
  useTerm_messageActivatedSuccess: `Les conditions d'utilisation ont été activés avec succès`,
  useTerm_messageDeactivatedSuccess: `Les conditions d'utilisation ont été désactivés avec succès`,
  useTerm_messageEditSuccess: `Le conditions d'utilisation a été modifié avec succès`,
  useTerm_messageDeleteSuccess: `Le conditions d'utilisation a été supprimé avec succès`,
  useTerm_titleFormAdd: `Ajouter un conditions d'utilisation`,
  useTerm_titleFormEdit: `Modifier un conditions d'utilisation`,
  useTerm_messageAddSuccess: `Le conditions d'utilisation a été ajouté avec succès`,
  useTerm_read: `Lire les conditions`,
  useTerm_accept_button: 'Accepter',
  useTerm_cancel_button: 'Fermer',
  useTerm_accept_notification: `Vous avez accepté les conditions d'utilisation`,
  useTerm_cancel_notification: `Vous devez accepter les conditions d'utilisation pour vous connecter`,
  regulationDateLimit_subMenuLabel: `Date limite de transmission`,
  regulationDateLimit_titleLister: `Date limite de transmission`,
  regulationDateLimit_titleLister_GLOBAL: `Configuration globale`,
  regulationDateLimit_titleLister_ANNUAL: `Configuration annuelle`,
  regulationDateLimit_titleLister_INDIVIDUAL: `Configuration individuelle`,
  regulationDateLimit_year: `Année de déclaration`,
  regulationDateLimit_month: `Mois limite année suivante`,
  regulationDateLimit_day: `Jour limite année suivante`,
  regulationDateLimit_type: `Type`,
  regulationDateLimit_date: `Date limite`,
  regulationDateLimit_permitNumber: `N° de permis`,
  regulationDateLimit_type_GLOBAL: `Globale`,
  regulationDateLimit_type_ANNUAL: `Annuelle`,
  regulationDateLimit_type_INDIVIDUAL: `Individuelle`,
  regulationDateLimit_titleFormAdd: `Ajouter une date limite de transmission`,
  regulationDateLimit_titleFormEdit: `Modifier une date limite de transmission`,
  regulationDateLimit_messageAddSuccess: `La date limite de transmission a été ajoutée avec succès`,
  regulationDateLimit_messageEditSuccess: `La date limite de transmission a été modifiées avec succès`,
  regulationDateLimit_messageDeleteSuccess: `La date limite de transmission a été supprimées avec succès`,
  btn_edit_toolTip: 'Modifier',
  btn_delete_toolTip: 'Supprimer',
  btn_play_toolTip: 'Créer une nouvelle déclaration',
  statistics_premixNoPermitDeclarationsIndicator_title:
    'Déclarations de prémélanges médicamenteux sans permis',
  statistic_premixNoPermitDeclarationsIndicator_premixNoPermitDeclarations:
    'Déclarations de prémélange médicamenteux sans permis du site de production',
  statistic_premixNoPermitDeclarationsIndicator_premixWithPermitDeclarations:
    'Déclarations de prémélange médicamenteux avec permis du site de production',
  statistics_onTimeSubmittedDeclarationsRateView_title: 'Déclarations acceptées transmises à temps',
  statistic_onTimeSubmittedDeclarationsRateView_onTimeSubmittedDeclarations:
    'Déclaration acceptées à temps',
  statistic_onTimeSubmittedDeclarationsRateView_notOnTimeSubmittedDeclarations:
    'Déclaration acceptées en retard',
  statistics_duplicatedDeclarationsRateView_title: 'Déclarations en double',
  statistics_duplicatedDeclarationsRateView_duplicatedDeclarationsCount: 'Nombre de doublons',
  statistics_duplicatedDeclarationsRateView_apiTransmitted: 'Par API',
  statistics_duplicatedDeclarationsRateView_nonApiTransmitted: 'Manuellement',
  statistics_duplicatedDeclarationsSummary_permit_number: 'Numéro de permis',
  statistics_duplicatedDeclarationsSummary_sum: 'Nombre de doublons',
  statistic_yScaleLabel_percentage: 'Pourcentage (%)',
  statistic_yScaleLabel_number: 'Nombre de déclarations',
  statistics_permitsWithTransmittedDeclarationRateView_title: 'Déclarants',
  statistics_permitsWithTransmittedDeclarationRateView_description:
    'Présentation des détenteurs de permis inscrits et ayant transmis au moins une déclaration acceptée',
  google_protect_text: 'This site is protected by reCAPTCHA and the Google',
  google_privacy_policy: 'Privacy Policy',
  google_terms_of_service: 'Terms of Service',
  google_protect_and: 'and',
  google_protect_apply: 'apply',
  foreign_key_messageDeleteFailure: "Impossible de supprimer l'élément, car il est utilisé",
  activeMedicationDins_titleLister: 'DIN actifs',
  activeMedicationDins_dinNumber: `# {dinNumber}`,
  ingredient_type_titleLister: 'Types d’ingrédients',
  serviceUnavailable: 'The system is unreachable at the moment, please try again later',
  delete_fk_409_error_msg: 'Impossible action, the resource is used by the system',
  vetDeclaration_titleLister: `Déclarations vétérinaire`,
  vetDeclaration_labelDeclarationDate: 'Transaction',
  vetDeclaration_labelDeclarationCreatedAt: 'Transmission',
  vetDeclaration_labelDeclarationType: 'Type de transaction',
  vetDeclaration_labelDeclarationType_SALE_SUPPLY: 'Vente ou fourniture',
  vetDeclaration_labelDeclarationType_RETURN: 'Retour',
  vetDeclaration_omvqEstablishment: 'Établissement de déclaration',
  vetDeclaration_prescriber: 'Prescripteur',
  declarationSiteUpdateTitleLister: 'Site Update',
  declaration_declarationSiteUpdateListView_siteUpdateModal_title: 'Modification de site',
  declaration_declarationSiteUpdateListView_siteUpdateModal_description: `Vous pouvez mettre à jour les informations suivantes pour toutes les déclarations concernant un même site de production : le <b>numéro de traçabilité</b>, le <b>numéro de permis</b> ou le <b>nom</b>. 1) Filtrer les déclarations en cliquant sur l’icône entonnoir qui est située à gauche dans chaque colonne. 2) Valider les résultats et 3) cliquer sur le bouton “Modifier” pour appliquer la modification souhaitée.`,
  declaration_declarationSiteUpdateListView_siteUpdateModal_successNotification:
    'Modification de site a été modifié avec succès',
  declaration_declarationSiteUpdateListView_siteUpdateBtn: 'Modifier',
  declaration_declarationSiteUpdateListView_siteUpdateForm_siteNumber: 'Nᴼ de traçabilité du site',
  declaration_declarationSiteUpdateListView_siteUpdateForm_sitePermitNumber:
    'Nᴼ de permis relatif aux prémélanges médicamenteux et aliments médicamenteux du site',
  declaration_declarationSiteUpdateListView_siteUpdateForm_siteName: 'Nom du site',
  declaration_declarationSiteUpdateListView_siteUpdateForm_totalCount: `Vous allez modifier {dataCount} déclarations`,
  declaration_declarationSiteUpdateListView_siteUpdateForm_siteNumberFormatError:
    'Le format du numéro de site est incorrect',
  declaration_declarationSiteUpdateListView_siteUpdateForm_sitePermitNumberFormatError:
    'Le format du numéro de permis est incorrect',
  declaration_help_body: `<p><strong>Modèle de déclaration groupé</strong></p><p>Vous pouvez créer un modèle de déclaration comprenant plusieurs déclarations. 1) Sélectionner les déclarations souhaitées <strong>d'un même client et d'un même permis du déclarant</strong> en cochant la case située à l'extrémité gauche de chaque déclaration. 2) Cliquer sur le bouton “Sauvegarder comme modèle” dans la barre de menu des déclarations. 3) Pour utiliser le modèle créé, aller dans le sous-menu “Modèles”, dans la section à gauche de l’écran.</p><p><strong>Duplication d'un groupe de déclaration</strong></p><p>Vous pouvez dupliquer plusieurs déclarations ensemble. 1) Sélectionner les déclarations souhaitées <strong>d'un même client et d'un même permis du déclarant</strong> en cochant la case située à l'extrémité gauche de chaque déclaration. 2) Cliquer sur le bouton “Dupliquer” dans la barre de menu des déclarations.</p>`,
  declarations_saveModel_serverError:
    'Vous ne pouvez pas créer de modèle, les déclarations choisies sont invalides',
}

export default messages
