import { IDeclarationRead } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetDeclarationDuplicatesQuery<D extends IDeclarationRead[]>(
  params?: HydraQueryParams<D>
) {
  const { vars, options, key } = params || {}
  return useHydraQuery<D>(key ?? 'declarationDuplicates', '/api/declarations', {
    vars,
    options,
  })
}
