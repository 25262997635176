import { MedicationType } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetMedicationTypesQuery<D extends MedicationType[]>(
  params?: HydraQueryParams<D>
) {
  const { vars, options, key } = params || {}
  return useHydraQuery<D>(key ?? 'medication_types', '/api/medication_types', {
    vars,
    options,
  })
}
