import { getBaseApi, getApi } from '../../libs/api-client'
import { setToken } from '../LocalStorageService'

export function login(username: string, password: string): Promise<any> {
  return getBaseApi()
    .post<any>(
      '/api/authentication_token',
      {
        username,
        password,
      },
      {
        headers: {
          'Content-type': 'application/json',
          // 'X-AUTH-TOKEN': 'true',
        },
      }
    )
    .then(response => {
      setToken(response.data)
      return response.data
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export function createUser(user: any, url: string): Promise<any> {
  return getApi().post<any>(url, user)
}

export function forgetPasswordRequest(email: string): Promise<any> {
  return getApi().post<string>('/api/user_accounts/reset_password_request', {
    email,
    frontEndActivationBaseUrl: `${window.location.origin}/reset_password`,
  })
}

export function confirmAccount(user: any): Promise<any> {
  return getApi()
    .post<any>('/api/confirmation_accounts', user)
    .then(response => {
      setToken(response.data)
      return response
    })
}

// eslint-disable-next-line no-shadow
export function resetPassword(password: any): Promise<any> {
  return getApi()
    .post<any>('/api/reset_passwords', password)
    .then(response => {
      setToken(response.data)
      return response
    })
}

export function me(): Promise<any> {
  return getApi()
    .get<any>('/api/me')
    .then(response => response.data)
}
