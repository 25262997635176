import { Title, Container, Card, Group, Text, Button } from '@mantine/core'
import { useIntlLight } from '../../libs/i18n'
import { getApi } from '../../libs/api-client'

const DebugPage = () => {
  const { t } = useIntlLight()

  const handleCreateFrontendError = () => {
    throw new Error('FE error manually triggered from /debug route')
  }

  const handleCreateBackendError = async () => {
    await getApi().post('/api/tools/debug', {}).catch()
  }

  return (
    <Container size="xs">
      <Title
        m="sm"
        order={4}
        sx={theme => ({
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
          textAlign: 'center',
        })}
      >
        {t('debug_section_title')}
      </Title>
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="center">
            <Button radius="md" onClick={handleCreateFrontendError}>
              {t('debug_create_fe_error')}
            </Button>
          </Group>
        </Card.Section>
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="center">
            <Button radius="md" onClick={handleCreateBackendError}>
              {t('debug_create_be_error')}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </Container>
  )
}

export default DebugPage
