import React from 'react'
import { IntlProvider } from 'react-intl'
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core'
import { useHotkeys, useLocalStorage, useFavicon } from '@mantine/hooks'
import { NotificationsProvider } from '@mantine/notifications'
import dayjs from 'dayjs'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import appLocales from '../../lang'
import { AuthProvider } from '../AuthProvider'
import { GlobalModalProvider } from '../../libs/ui-atoms/providers/GlobalModalProvider'
import { AppSettingsProvider } from '../AppSettingsProvider'
import { flattenMessages } from '../../libs/i18n'

const AppProviders = ({ children }: { children: React.ReactNode }) => {
  useFavicon('/favicon.ico')

  const currentLanguage = 'fr'
  const { locale, messages } = appLocales[currentLanguage]

  dayjs.locale(currentLanguage)

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'agneau-color-scheme',
    defaultValue: 'light',
  })

  const toggleColorScheme = (color?: ColorScheme) => {
    setColorScheme(color || (colorScheme === 'dark' ? 'light' : 'dark'))
  }

  useHotkeys([['mod+J', () => toggleColorScheme()]])

  return (
    <AppSettingsProvider defaultSettings={{ currentLanguage }}>
      <IntlProvider locale={locale} messages={flattenMessages(messages)}>
        <NotificationsProvider position="top-center" zIndex={999999999999}>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
              theme={{
                colorScheme,
                dateFormat: 'YYYY-MM-DD',
                datesLocale: locale,
                loader: 'dots',
                /*
https://maketintsandshades.com/
Utiliser les 20%, 10% et 0% de shade (vers le noir)
Ensuite prendre les 10%, 20%, 30%, 40%, 50%, 60%, 70% du tint (vers le blanc)
 */
                colors: {
                  blue: [
                    '#c7dbed',
                    '#b4cfe7',
                    '#a1c3e1',
                    '#8eb7da',
                    '#7babd4',
                    '#699fce',
                    '#5693c8',
                    '#4387c2', // Couleur voulue pour les boutons
                    '#3c7aaf',
                    '#366c9b',
                  ],
                  green: [
                    '#d7ecd3',
                    '#c9e5c5',
                    '#bcdfb6',
                    '#aed9a7',
                    '#a1d299',
                    '#93cc8a',
                    '#86c57c',
                    '#78bf6d', // Couleur voulue pour les boutons
                    '#6cac62',
                    '#609957',
                  ],
                },
              }}
            >
              <AuthProvider>
                <GlobalModalProvider>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY ?? ''}
                    language={locale}
                    useRecaptchaNet
                    // useEnterprise="[optional_boolean_value]"
                    // scriptProps={{
                    //   async: false, // optional, default to false,
                    //   defer: false, // optional, default to false
                    //   appendTo: 'head', // optional, default to "head", can be "head" or "body",
                    //   nonce: undefined // optional, default undefined
                    // }}
                    container={{
                      // optional to render inside custom element
                      // element: "[required_id_or_htmlelement]",
                      parameters: {
                        // badge: '[inline|bottomright|bottomleft]', // optional, default undefined
                        theme: colorScheme, // optional, default undefined
                      },
                    }}
                  >
                    {children}
                  </GoogleReCaptchaProvider>
                </GlobalModalProvider>
              </AuthProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </NotificationsProvider>
      </IntlProvider>
    </AppSettingsProvider>
  )
}

export default AppProviders
