import React from 'react'
import {
  Accordion,
  Anchor,
  Button,
  Container,
  Group,
  Text,
  TypographyStylesProvider,
  useMantineTheme,
} from '@mantine/core'
import { SignOutIcon } from '@primer/octicons-react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  IconCurrencyDollar,
  IconDeviceDesktop,
  IconInfoCircle,
  IconSchool,
  IconSend,
} from '@tabler/icons'
import { IconMessageQuestion } from '@tabler/icons-react'
import useStyles from './Jumbotron.styles'
import LogoCenteredNotLogged from '../../../layouts/LogoCenteredNotLogged/LogoCenteredNotLogged'
import { SignupProfile } from '../../../libs/ui-molecules'

const JumbotronProd = () => {
  const { classes, cx } = useStyles()
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const loginOnClick = () => {
    navigate('/login')
  }

  return (
    <Container size={1000}>
      <Text>
        <TypographyStylesProvider>
          <LogoCenteredNotLogged />
          <Text className={classes.description}>{formatMessage({ id: 'home.section_one' })}</Text>
          <br />
          <Text className={classes.description}>
            {formatMessage({ id: 'home.section_two' })}
            &nbsp;(
            <Anchor
              onClick={() => {
                window.open(
                  'https://www.legisquebec.gouv.qc.ca/fr/document/rc/P-42,%20r.%2010%20',
                  '_blank',
                  'noopener,noreferrer'
                )
              }}
              sx={theme => ({
                paddingTop: 2,
                color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
                fontWeight: 700,
                fontSize: theme.fontSizes.md,
              })}
              align="center"
            >
              {formatMessage({ id: 'home.section_two_link' })}
            </Anchor>
            ).
          </Text>
        </TypographyStylesProvider>
      </Text>
      <Group className={classes.controls}>
        <Button
          data-testid="button-login"
          onClick={loginOnClick}
          size="xl"
          radius="md"
          className={cx(classes.control, classes.controlPrimary)}
          leftIcon={<SignOutIcon size={20} />}
          styles={{ leftIcon: { marginRight: 12 } }}
        >
          {formatMessage({ id: 'home.login_button' })}
        </Button>
        <SignupProfile>
          <Button
            size="xl"
            variant="outline"
            radius="md"
            className={cx(classes.control, classes.githubControl)}
            color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
          >
            {formatMessage({ id: 'home.register_button' })}
          </Button>
        </SignupProfile>
      </Group>

      <Text>
        <TypographyStylesProvider>
          <Accordion chevronPosition="right" variant="contained">
            <Accordion.Item value="api">
              <Accordion.Control
                icon={
                  <IconSend
                    style={{ color: 'var(--mantine-color-red-filled', width: 20, height: 20 }}
                  />
                }
              >
                {formatMessage({ id: 'home_section_dataTransfer_title' })}
              </Accordion.Control>
              <Accordion.Panel>
                <div className={classes.description}>
                  <p>{formatMessage({ id: 'home.introduction.two' })}</p>
                  <ol>
                    <li>{formatMessage({ id: 'home.introduction.three' })}</li>
                    <li>{formatMessage({ id: 'home.introduction.four' })}</li>
                  </ol>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://api-antibiotiqc.attestra.com/api/docs/"
                  >
                    {formatMessage({ id: 'home.moreInfo.one' })}
                  </a>
                  <br />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://antibiotiqc.attestra.com/docs/Reglement-donnees-a-transmettre.pdf"
                  >
                    {formatMessage({ id: 'home.moreInfo.two' })}
                  </a>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="training">
              <Accordion.Control
                icon={
                  <IconSchool
                    style={{ color: 'var(--mantine-color-red-filled', width: 20, height: 20 }}
                  />
                }
              >
                {formatMessage({ id: 'home_section_training_title' })}
              </Accordion.Control>
              <Accordion.Panel>
                <div className={classes.description}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({ id: 'home_section_training_content' }),
                    }}
                  />
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="finance">
              <Accordion.Control
                icon={
                  <IconCurrencyDollar
                    style={{ color: 'var(--mantine-color-red-filled', width: 20, height: 20 }}
                  />
                }
              >
                {formatMessage({ id: 'home_section_financingHelp_title' })}
              </Accordion.Control>
              <Accordion.Panel>
                <div className={classes.description}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({ id: 'home.section_three_dev' }),
                    }}
                  />
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="info">
              <Accordion.Control
                icon={
                  <IconInfoCircle
                    style={{ color: 'var(--mantine-color-red-filled', width: 20, height: 20 }}
                  />
                }
              >
                {formatMessage({ id: 'home_section_moreInfo_title' })}
              </Accordion.Control>
              <Accordion.Panel>
                <div className={classes.description}>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.quebec.ca/agriculture-environnement-et-ressources-naturelles/sante-animale/usage-antibiotiques/reglementation"
                      >
                        {formatMessage({ id: 'home.moreInfo.three' })}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.legisquebec.gouv.qc.ca/fr/document/rc/P-42,%20r.%2010%20/"
                      >
                        {formatMessage({ id: 'home.moreInfo.four' })}
                      </a>
                    </li>
                  </ul>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="soutien">
              <Accordion.Control
                icon={
                  <IconMessageQuestion
                    style={{ color: 'var(--mantine-color-red-filled', width: 20, height: 20 }}
                  />
                }
              >
                {formatMessage({ id: 'home_section_support_title' })}
              </Accordion.Control>
              <Accordion.Panel>
                <div className={classes.description}>
                  <ul>
                    <li>
                      {formatMessage(
                        { id: 'home.support.one' },
                        {
                          url: (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://attestra.atlassian.net/servicedesk/customer/portal/4/group/16"
                            >
                              soutien technique d&apos;Attestra
                            </a>
                          ),
                        }
                      )}
                    </li>
                    <li>
                      {formatMessage(
                        { id: 'home.support.three' },
                        {
                          url: (
                            <a href="mailto:aidefinanciereAntibiotiQC@attestra.com">
                              aidefinanciereAntibiotiQC@attestra.com
                            </a>
                          ),
                        }
                      )}
                    </li>
                    <li>
                      {formatMessage(
                        { id: 'home.support.two' },
                        {
                          url: (
                            <a href="mailto:AntibiotiQC@mapaq.gouv.qc.ca">
                              AntibiotiQC@mapaq.gouv.qc.ca
                            </a>
                          ),
                        }
                      )}
                    </li>
                  </ul>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="prerod">
              <Accordion.Control
                icon={
                  <IconDeviceDesktop
                    style={{ color: 'var(--mantine-color-red-filled', width: 20, height: 20 }}
                  />
                }
              >
                {formatMessage({ id: 'home_section_staging_title' })}
              </Accordion.Control>
              <Accordion.Panel>
                <div className={classes.description}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({ id: 'home_section_staging_content_prod' }),
                    }}
                  />
                  <a target="_blank" rel="noreferrer" href="https://monitorage-demo.attestra.com">
                    {formatMessage({ id: 'home_section_staging_clickHere' })}
                  </a>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </TypographyStylesProvider>
      </Text>
    </Container>
  )
}

export default JumbotronProd
