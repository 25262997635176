import React from 'react'
import { actionTypes } from '../../../../reducers/TableContext'

interface ICheckBoxProps {
  dispatch: React.Dispatch<any>
  state: any
  data: any[]
  isDataFiltered?: boolean
}

const CheckBox = ({ dispatch, state, data, isDataFiltered }: ICheckBoxProps) => {
  return data.length > 0 ? (
    <input
      type="checkbox"
      onChange={e => {
        const { checked } = e.target
        if (checked) {
          dispatch({ type: actionTypes.SET_CHECK_ALL, payload: data.map((row: any) => row['@id']) })
        } else {
          dispatch({
            type: actionTypes.UNCHECK_ALL,
            payload: isDataFiltered ? data.map((row: any) => row['@id']) : null,
          })
        }
      }}
      checked={
        isDataFiltered
          ? data.every((row: any) => state.rowChecked.includes(row['@id']))
          : state.rowChecked.length === data.length
      }
    />
  ) : null
}

export default CheckBox
