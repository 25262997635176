import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppShell } from './layouts/AppShell'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import AppProviders from './providers/AppProviders/AppProviders'

const App = () => (
  <BrowserRouter>
    <AppProviders>
      <AppShell />
    </AppProviders>
  </BrowserRouter>
)

export default App
