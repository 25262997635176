import React from 'react'
import { Group, Button } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

export interface FormGroupEditionButtonProps {
  onCancel: () => void
  labelSubmit?: string
  labelCancel?: string
  isButtonDisabled?: boolean
}

const FormGroupEditionButton = ({
  onCancel,
  labelSubmit,
  labelCancel,
  isButtonDisabled = false,
}: FormGroupEditionButtonProps) => {
  return (
    <Group position="center" mt="xl">
      <Button
        color="red"
        type="button"
        onClick={onCancel}
        data-testid="form-group-edition-button-cancel"
      >
        <FormattedMessage id={labelCancel ?? 'typeBase.labelBtnCancel'} defaultMessage="Cancel" />
      </Button>
      <Button
        color="blue"
        type="submit"
        data-testid="form-group-edition-button-save"
        disabled={isButtonDisabled}
      >
        <FormattedMessage id={labelSubmit ?? 'typeBase.labelBtnSave'} defaultMessage="Save" />
      </Button>
    </Group>
  )
}

export default FormGroupEditionButton
