const convertedDate = (date: string): Date => {
  const d = new Date(date)
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000)
  return d
}

const convertedDateDisplay = (date: string): string | null => {
  if (date) {
    const d = new Date(date)
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000)
    return d.toLocaleDateString('fr-CA')
  }
  return null
}

export { convertedDate, convertedDateDisplay }
