import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 0.0,
  release: process.env.REACT_APP_VERSION,
})

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
  serviceWorkerRegistration.unregister()
}

// reportWebVitals(console.log);
reportWebVitals()
