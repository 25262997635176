import { UseQueryOptions, useMutation, useQuery } from 'react-query'
import { getApi } from '../utils/request'
import { normalizeMemberI18nFields } from '../utils/normalizeMemberI18nFields'

export interface HydraMutationOptions {
  onSuccess?: (response: any) => void
  onError?: (error: unknown, variables: any, context: unknown) => void
  httpMethod?: 'POST' | 'PUT' | 'DELETE'
}

export interface HydraMutationParams {
  key?: string
  vars?: Record<string, any>
  options?: HydraMutationOptions
}

interface HydraMutationReturn {
  mutate: (data: any) => void
  isLoading?: boolean
}

export function useHydraMutation<D>(
  url: string,
  params?: HydraMutationParams
): HydraMutationReturn {
  const { options } = params || {}
  const httpMethod = options?.httpMethod || 'POST'

  return useMutation({
    mutationFn: data => {
      if (httpMethod === 'PUT') {
        const { id, ...rest } = data
        return getApi()
          .put(`${url}/${id}`, { ...rest })
          .then(response => response.data)
      }

      if (httpMethod === 'DELETE') {
        return getApi()
          .post(url, { ...data })
          .then(response => response.data)
      }

      return getApi()
        .post(url, { ...data })
        .then(response => response.data)
    },
    onSuccess: response => options?.onSuccess?.(response),
    onError: (error: unknown, variables: any, context: unknown) =>
      options?.onError?.(error, variables, context),
  })
}
