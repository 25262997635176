import React from 'react'
import { Badge } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

const BadgeActive = ({ active }: { active: boolean }) => {
  const labelKey = `typeBase.${active ? 'badgeLabelActive' : 'badgeLabelInactive'}`

  return (
    <Badge color={active ? 'lime' : 'red'} variant="filled">
      <FormattedMessage id={labelKey} />
    </Badge>
  )
}

export default BadgeActive
