import React, { useState } from 'react'
import { Input, Popover, PasswordInput } from '@mantine/core'
import { Control, Controller } from 'react-hook-form'
import { IconLock } from '@tabler/icons'
import CheckList from './CheckList'

export interface PasswordRequirementInputProps {
  name: string
  label: string
  error?: string
  required: boolean
  control: Control<any, any>
  hideCheckList?: boolean
}

const PasswordRequirementInput = ({
  name,
  label,
  error,
  required = true,
  control,
  hideCheckList,
}: PasswordRequirementInputProps) => {
  const [popoverOpened, setPopoverOpened] = useState(false)

  return (
    <Input.Wrapper label={label} error={error} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <Popover opened={popoverOpened} position="bottom" width="target" transition="pop">
              <Popover.Target>
                <div
                  onFocusCapture={() => setPopoverOpened(true)}
                  onBlurCapture={() => setPopoverOpened(false)}
                >
                  <PasswordInput
                    required
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    data-testid="input-password"
                    icon={<IconLock size="1rem" />}
                  />
                </div>
              </Popover.Target>
              {!hideCheckList && (
                <Popover.Dropdown>
                  <CheckList value={value ?? ''} />
                </Popover.Dropdown>
              )}
            </Popover>
          )
        }}
      />
    </Input.Wrapper>
  )
}

export default PasswordRequirementInput
