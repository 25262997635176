import React from 'react'
import { Button, Checkbox, Flex, Title } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useAppSettings } from '../../../../hooks/useAppSettings'
import { Notices } from '../../../../types'
import { useIntlLight } from '../../../i18n'
import useGlobalModal from '../../hooks/useGlobalModal'
import useNoticeStyles from './useNoticeStyles'

interface NoticeProps {
  text: string | React.ReactNode
  title?: string
  identifier: string
}

export default function Notice({ text, title, identifier }: NoticeProps) {
  const { disableNotice, enableNotice } = useAppSettings()
  const { t } = useIntlLight()
  const { close } = useGlobalModal()
  const { classes } = useNoticeStyles()

  return (
    <>
      <Title size={20}>{title}</Title>
      {React.isValidElement(text) && text}
      {typeof text === 'string' && (
        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      <Checkbox
        label={t('declaration_addDeclaration_disableNotice')}
        onChange={event => {
          const json = localStorage.getItem('me')
          const me = json ? JSON.parse(json) : undefined
          const isChecked = event.currentTarget.checked

          if (isChecked) {
            disableNotice(identifier, me?.username)
          } else {
            enableNotice(identifier, me?.username)
          }
        }}
      />
      <Flex className={classes.buttonWrapper}>
        <Button color="red" onClick={close}>
          {t('all_close')}
        </Button>
      </Flex>
    </>
  )
}
