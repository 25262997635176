import { createStyles } from '@mantine/core'

export default createStyles(() => ({
  logoWrapper: {
    width: 320,
    margin: '0 auto',
    marginBottom: 40,
  },
  logo: {
    width: '100%',
  },
}))
