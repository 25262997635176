import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  header: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  headerDev: {
    backgroundColor: theme.colors.orange[4],
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  logoQC: {
    height: 35,
  },
  logoWrapper: {
    width: 130,
    marginLeft: 15,
  },
  logo: {
    width: '100%',
  },
}))
