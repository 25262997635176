import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ActionIcon,
  Anchor,
  Button,
  Divider,
  Group,
  Indicator,
  Popover,
  useMantineTheme,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { FormattedMessage } from 'react-intl'
import { CalendarIcon } from '@modulz/radix-icons'
import { BiFilterAlt as FilterIcon } from 'react-icons/bi'
import { useIntlLight } from '../../../../../i18n'
import isDateFilterEnabled from '../../../../utils/isDateFilterEnabled'
import useDateFilterStyles from './useDateFilterStyles'
import { convertedDate } from '../../../../../dates'

const DateFilter = ({ columnName, state, dispatch }: any) => {
  const [opened, setOpened] = useState(false)
  // Pour ne pas que le popup ferme au click d'une date dans le calendar
  const [openedCal, setOpenedCal] = useState(false)
  const { t } = useIntlLight()
  const theme = useMantineTheme()
  const inputMinDateRef = useRef<HTMLInputElement>(null)
  const inputMaxDateRef = useRef<HTMLInputElement>(null)
  const [startMaxDate, setStartMaxDate] = useState<Date>()
  const [endMinDate, setEndMinDate] = useState<Date>()

  const filter = state.filters.find((_filter: any) => _filter.property === columnName) || {
    value: { startDate: '', endDate: '' },
  }

  const [stateFilter, setStateFilter] = useState(filter.value)

  const handleClose = () => {
    setOpened(false)
  }

  const handleClear = () => {
    dispatch({ type: 'SET_FILTER', payload: { property: columnName, value: '' } })
    setOpened(false)
  }

  const isAppliedDisable = !stateFilter.endDate && !stateFilter.startDate

  const { classes } = useDateFilterStyles({
    isFilterEnabled: isDateFilterEnabled(filter?.value),
  })

  const handleApply = useCallback(() => {
    dispatch({
      type: 'SET_FILTER',
      payload: { property: columnName, value: stateFilter },
    })
    setOpened(false)
  }, [dispatch, columnName, stateFilter])

  useEffect(() => {
    if (opened) {
      inputMinDateRef?.current?.focus()
    }
  }, [opened, inputMinDateRef])

  useEffect(() => {
    inputMinDateRef?.current?.addEventListener('keyup', event => {
      if (event.key !== 'Enter') return
      handleApply()
    })

    inputMaxDateRef?.current?.addEventListener('keyup', event => {
      if (event.key !== 'Enter') return
      handleApply()
    })
  }, [inputMinDateRef, inputMaxDateRef, handleApply])

  useEffect(() => {
    setEndMinDate(stateFilter.startDate ? new Date(stateFilter.startDate) : undefined)
  }, [stateFilter.startDate])

  useEffect(() => {
    setStartMaxDate(stateFilter.endDate ? new Date(stateFilter.endDate) : new Date())
  }, [stateFilter.endDate])

  return (
    <Popover
      opened={opened}
      onClose={handleClose}
      position="bottom"
      transition="scale-y"
      closeOnClickOutside={!openedCal}
    >
      <Popover.Target>
        <ActionIcon
          className={classes.filterIconButton}
          color={isDateFilterEnabled(filter?.value) ? 'blue' : 'gray'}
          onClick={() => setOpened(o => !o)}
          variant="filled"
        >
          <FilterIcon />
        </ActionIcon>
      </Popover.Target>
      {opened && (
        <Popover.Dropdown>
          <DatePicker
            label={<FormattedMessage id="systemBase.tableLister.filterDateMin" />}
            ref={inputMinDateRef}
            maxDate={startMaxDate}
            initialMonth={startMaxDate}
            allowFreeInput
            onDropdownOpen={() => setOpenedCal(true)}
            onDropdownClose={() => setOpenedCal(false)}
            defaultValue={stateFilter.startDate ? convertedDate(stateFilter.startDate) : null}
            icon={<CalendarIcon />}
            placeholder={t('systemBase.tableLister.filterDateSelect')}
            withinPortal
            value={stateFilter.startDate ? convertedDate(stateFilter.startDate) : null}
            onChange={val =>
              setStateFilter({ ...stateFilter, startDate: val ? val.toISOString() : null })
            }
            dayStyle={date => {
              const today = new Date()
              return date.toDateString() === today.toDateString()
                ? { backgroundColor: theme.colors.gray[1], color: theme.black }
                : {}
            }}
            renderDay={date => {
              const today = new Date()
              return (
                <Indicator
                  size={6}
                  color="red"
                  offset={8}
                  disabled={date.toDateString() !== today.toDateString()}
                >
                  <div>{date.getDate()}</div>
                </Indicator>
              )
            }}
          />
          <Divider />
          <DatePicker
            label={<FormattedMessage id="systemBase.tableLister.filterDateMax" />}
            allowFreeInput
            ref={inputMaxDateRef}
            minDate={endMinDate}
            maxDate={new Date()}
            initialMonth={endMinDate}
            onDropdownOpen={() => setOpenedCal(true)}
            onDropdownClose={() => setOpenedCal(false)}
            defaultValue={stateFilter.endDate ? convertedDate(stateFilter.endDate) : null}
            icon={<CalendarIcon />}
            placeholder={t('systemBase.tableLister.filterDateSelect')}
            withinPortal
            value={stateFilter.endDate ? convertedDate(stateFilter.endDate) : null}
            onChange={val =>
              setStateFilter({ ...stateFilter, endDate: val ? val.toISOString() : null })
            }
            dayStyle={date => {
              const today = new Date()
              return date.toDateString() === today.toDateString()
                ? { backgroundColor: theme.colors.gray[1], color: theme.black }
                : {}
            }}
            renderDay={date => {
              const today = new Date()
              return (
                <Indicator
                  size={6}
                  color="red"
                  offset={8}
                  disabled={date.toDateString() !== today.toDateString()}
                >
                  <div>{date.getDate()}</div>
                </Indicator>
              )
            }}
          />
          <Group position="apart" mt="sm">
            <Button color="red" onClick={handleClear} disabled={isAppliedDisable}>
              {t('all_clear')}
            </Button>
            <Button onClick={handleApply} disabled={isAppliedDisable}>
              {t('all_apply')}
            </Button>
          </Group>
        </Popover.Dropdown>
      )}
    </Popover>
  )
}

export default DateFilter
