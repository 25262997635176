import React from 'react'
import { Text, Center, Card, Button } from '@mantine/core'

export interface ProfileCardProps {
  icon: React.FC<any>
  text: string
  label: string
  button?: {
    label: string
    onClick: () => void
  }
}

const ProfileCard = ({ text, icon: Icon, label, button }: ProfileCardProps) => {
  return (
    <Card>
      <Center>
        <Icon size={50} />
      </Center>
      <Text fw={700} fz="lg" mt={5} align="center">
        {label}
      </Text>
      <Text c="dimmed" fz="sm" dangerouslySetInnerHTML={{ __html: text }} />
      <Center>
        {button && (
          <Button size="sm" mt="sm" onClick={button.onClick}>
            {button.label}
          </Button>
        )}
      </Center>
    </Card>
  )
}

export default ProfileCard
