import { ChevronLeft, ChevronRight, Icon as TablerIcon } from 'tabler-icons-react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Box, Collapse, Group } from '@mantine/core'
import useNavbarLinksGroupStyles from './useNavbarLinksGroupStyles'

interface LinksGroupProps {
  icon: TablerIcon
  label: string
  initiallyOpened?: boolean
  links?: { label: string; link: string; icon?: TablerIcon }[]
  link?: string
  handlers?(): any
  isSubMenuExpandable?: boolean
}

const LinksGroup = ({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  link,
  handlers,
  isSubMenuExpandable = true,
}: LinksGroupProps) => {
  const { classes, theme } = useNavbarLinksGroupStyles()
  const hasLinks = Array.isArray(links)
  const [opened, setOpened] = useState(initiallyOpened || false)
  const ChevronIcon = theme.dir === 'ltr' ? ChevronRight : ChevronLeft
  const items = (hasLinks ? links : []).map(newLink => {
    const MenuIcon = newLink.icon
    return (
      <NavLink
        className={({ isActive }) => `${classes.subNav} ${isActive ? classes.subNavActive : ''}`}
        to={newLink.link}
        key={newLink.label}
        onClick={handlers}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }} ml="0">
          {MenuIcon && <MenuIcon size={20} />}
          <Box ml="xs">
            <FormattedMessage id={newLink.label} />
          </Box>
        </Box>
      </NavLink>
    )
  })

  return (
    <>
      {isSubMenuExpandable ? (
        <NavLink
          to={link ?? ''}
          className={({ isActive }) =>
            `${classes.nav} ${isActive && link !== '#' ? classes.navActive : ''}`
          }
          onClick={() => setOpened(o => !o)}
        >
          <Group position="apart" spacing={0}>
            <Box sx={{ display: 'flex', alignItems: 'center' }} ml="md">
              <Icon size={20} />
              <Box ml="xs">
                <FormattedMessage id={label} />
              </Box>
            </Box>
            {hasLinks && (
              <ChevronIcon
                className={classes.chevron}
                size={14}
                style={{
                  transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
                }}
              />
            )}
          </Group>
        </NavLink>
      ) : (
        <Box className={classes.nav}>
          <Group position="apart" spacing={0}>
            <Box sx={{ display: 'flex', alignItems: 'center' }} ml="md">
              <Icon size={20} />
              <Box ml="xs">
                <FormattedMessage id={label} />
              </Box>
            </Box>
          </Group>
        </Box>
      )}
      {hasLinks && isSubMenuExpandable && <Collapse in={opened}>{items}</Collapse>}
      {hasLinks && !isSubMenuExpandable && items}
    </>
  )
}

export default LinksGroup
