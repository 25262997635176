import { getApi, ICountrySubdivision, ISpecies, IArrayResponse } from '../../libs/api-client'

export interface ISystemTypeBase {
  ['@id']: string
  id: number
  nameEn: string
  nameFr: string
  code: string
  active: boolean
  statusDate?: string
}

export interface ISystemProduct extends ISystemTypeBase {
  productType: string
}

export interface ISystemMedicationType extends ISystemTypeBase {
  atcVetCode: string
}

export interface ISystemActiveIngredient extends ISystemTypeBase {
  medicationType: any
  ordonnanceQc: boolean
}

export interface ISystemMedicationActiveIngredient {
  activeIngredient: string | null
  concentration: number | null
  unit: string | null
}

export interface ISystemMedicationActiveIngredientRead {
  ['@id']: string
  id: number
  activeIngredient: ISystemActiveIngredient
  concentration: number | null
  unit: string | null
}

export interface ISystemMedicationDin {
  dinNumber?: string
  startDate?: string | null
  endDate?: string | null
}

export interface ISystemMedication extends ISystemTypeBase {
  shortNameFr: string
  shortNameEn: string
  note?: string
  medicationActiveIngredients: ISystemMedicationActiveIngredient[]
  medicationDins: ISystemMedicationDin[]
}

export interface ISystemMedicationRead extends ISystemTypeBase {
  shortNameFr: string
  shortNameEn: string
  medicationActiveIngredients: ISystemMedicationActiveIngredientRead[]
  medicationDins: ISystemMedicationDin[]
}

export interface ISystemProductionStage extends ISystemTypeBase {
  products: string[]
  species: any
  unitLimit: string
  precision: boolean
  isUsed?: boolean
  canHaveSite?: boolean | null
  officialSite?: boolean | null
  vetValidation?: boolean | null
}

export interface IUpdateSystemTypeBase {
  data: any
  iri: string
}

export interface IOmvqVeterinarianSkeleton {
  ['@id']: string
  id: number
  permitNumber: string
  name: string
  email: string
  omvqStatus: string
  omvqStartDate?: Date | null
  omvqEndDate?: Date | null
  areaOfPractice?: any[]
  active: boolean
  statusDate: Date
  createdAt: Date
  updatedAt: Date
}

export interface IOmvqEstablishmentSkeleton {
  ['@id']: string
  id: number
  name: string
  address: string
  city: string
  province: string
  country: string
  postalCode: string
  phone: string
  active: boolean
  statusDate: Date
  createdAt: Date
  updatedAt: Date
}

export interface IOmvqEstablishmentVeterinarianSkeleton {
  ['@id']: string
  id: number
  linkType: string
}

export interface IOmvqEstablishmentVeterinarianVeterinarian
  extends IOmvqEstablishmentVeterinarianSkeleton {
  omvqVeterinarian: IOmvqVeterinarianSkeleton
}

export interface IOmvqEstablishmentVeterinarianEstablishment
  extends IOmvqEstablishmentVeterinarianSkeleton {
  omvqEstablishment: IOmvqEstablishmentSkeleton
}

export interface IOmvqVeterinarian extends IOmvqVeterinarianSkeleton {
  omvqEstablishmentVeterinarians: IOmvqEstablishmentVeterinarianEstablishment[]
  omvqVeterinarianHistories: {
    obtainedDate?: Date | null
    terminationDate?: Date | null
    revocationDate?: Date | null
  }[]
}

export interface IOmvqEstablishment extends IOmvqEstablishmentSkeleton {
  omvqEstablishmentVeterinarians: IOmvqEstablishmentVeterinarianVeterinarian[]
}

export function getAllEntities<T>(url: string): Promise<T[]> {
  return getApi()
    .get<IArrayResponse<T>>(url)
    .then(response => {
      return response.data['hydra:member']
    })
    .catch(error => {
      return error
    })
}

export function getEntity<T>(iri: string): Promise<T> {
  return getApi()
    .get<T>(iri)
    .then(response => response.data)
}

export function createEntity<T>(iri: string, data: T): Promise<any> {
  return getApi().post<T>(iri, data)
}

export function updateEntity<T>(data: T, iri: string) {
  return getApi().put(iri, data)
}

export function deleteEntity(iri: string) {
  return getApi().delete(iri)
}

export function getProductTypes(url: any): Promise<IArrayResponse<ISystemTypeBase>> {
  return getApi()
    .get<IArrayResponse<ISystemTypeBase>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getProductType(iri: string): Promise<ISystemTypeBase> {
  return getEntity<ISystemTypeBase>(iri)
}

export function createProductType(data: ISystemTypeBase): Promise<any> {
  return createEntity<ISystemTypeBase>('/api/product_types', data)
}

export function updateProductType(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function update(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getProducts(url: any): Promise<IArrayResponse<ISystemProduct>> {
  return getApi()
    .get<IArrayResponse<ISystemProduct>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getProduct(iri: string): Promise<ISystemProduct> {
  return getEntity<ISystemProduct>(iri)
}

export function createProduct(data: ISystemProduct): Promise<any> {
  return createEntity<ISystemProduct>('/api/products', data)
}

export function updateProduct(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getMedicationTypes(url: any): Promise<IArrayResponse<ISystemMedicationType>> {
  return getApi()
    .get<IArrayResponse<ISystemMedicationType>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getMedicationType(iri: string): Promise<ISystemMedicationType> {
  return getEntity<ISystemMedicationType>(iri)
}

export function createMedicationType(data: ISystemMedicationType): Promise<any> {
  return createEntity<ISystemTypeBase>('/api/medication_types', data)
}

export function updateMedicationType(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getAllMedication(url: any): Promise<IArrayResponse<ISystemMedicationRead>> {
  return getApi()
    .get<IArrayResponse<ISystemMedicationRead>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getMedication(iri: string): Promise<ISystemMedicationRead> {
  return getEntity<ISystemMedicationRead>(iri)
}

export function createMedication(data: ISystemMedication): Promise<any> {
  return createEntity<ISystemTypeBase>('/api/medications', data)
}

export function updateMedication(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getActiveIngredients(url: any): Promise<IArrayResponse<ISystemActiveIngredient>> {
  return getApi()
    .get<IArrayResponse<ISystemActiveIngredient>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getActiveIngredient(iri: string): Promise<ISystemActiveIngredient> {
  return getEntity<ISystemActiveIngredient>(iri)
}

export function createActiveIngredient(data: ISystemActiveIngredient): Promise<any> {
  return createEntity<ISystemActiveIngredient>('/api/active_ingredients', data)
}

export function updateActiveIngredient(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getAllSpecies(url: any): Promise<IArrayResponse<ISpecies>> {
  return getApi()
    .get<IArrayResponse<ISpecies>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getSpecies(iri: string): Promise<ISpecies> {
  return getEntity<ISpecies>(iri)
}

export function createSpecies(data: ISpecies): Promise<any> {
  return createEntity<ISpecies>('/api/species', data)
}

export function updateSpecies(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getAllProductionStage(url: any): Promise<IArrayResponse<ISystemProductionStage>> {
  return getApi()
    .get<IArrayResponse<ISystemProductionStage>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getProductionStage(iri: string): Promise<ISystemProductionStage> {
  return getEntity<ISystemProductionStage>(iri)
}

export function createProductionStage(data: ISystemProductionStage): Promise<any> {
  return createEntity<ISystemProductionStage>('/api/production_stages', data)
}

export function updateProductionStage(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getCountrySubdivisions(url: any): Promise<IArrayResponse<ICountrySubdivision>> {
  return getApi()
    .get<IArrayResponse<ICountrySubdivision>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getCountrySubdivision(iri: string): Promise<ICountrySubdivision> {
  return getEntity<ICountrySubdivision>(iri)
}

export function createCountrySubdivision(data: ICountrySubdivision): Promise<any> {
  return createEntity<ICountrySubdivision>('/api/permit_types', data)
}

export function updateCountrySubdivision(data: IUpdateSystemTypeBase): Promise<any> {
  return updateEntity<ISystemTypeBase>(data.data, data.iri)
}

export function getAllVeterinarians(url: any): Promise<IArrayResponse<IOmvqVeterinarian>> {
  return getApi()
    .get<IArrayResponse<IOmvqVeterinarian>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export function getAllEstablishments(url: any): Promise<IArrayResponse<IOmvqEstablishment>> {
  return getApi()
    .get<IArrayResponse<IOmvqEstablishment>>(url.queryKey[1])
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}
