import { MoonStars, Sun } from 'tabler-icons-react'
import {
  ActionIcon,
  Box,
  Burger,
  Header as BaseHeader,
  MediaQuery,
  useMantineColorScheme,
  useMantineTheme,
  Group,
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import AuthMenu from './authMenu'
import useAuth from '../../hooks/useAuth'
import useHeaderStyles from './useHeaderStyles'
import { NotificationMenu } from '../../libs/ui-molecules'

interface HeaderProps {
  opened: boolean
  handlers(): any
  disableBurger?: boolean
  showLogo?: boolean
}

const Header = ({ opened, handlers, disableBurger = false, showLogo = true }: HeaderProps) => {
  const theme = useMantineTheme()
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const { loggedIn } = useAuth()
  const { classes } = useHeaderStyles()
  const { formatMessage } = useIntl()
  const key = `env_${process.env.REACT_APP_ENV}`

  return (
    <BaseHeader
      className={process.env.REACT_APP_ENV !== 'prod' ? classes.headerDev : classes.header}
      height={75}
      p="md"
      withBorder={false}
    >
      <Box>
        {showLogo && (
          <Box className={classes.logoWrapper}>
            <Link to="/">
              <img className={classes.logo} src="/image/Logo_AntibiotiQC.png" alt="" />
            </Link>
          </Box>
        )}
        {!disableBurger ? (
          <MediaQuery largerThan="md" styles={{ display: 'none' }}>
            <Burger
              opened={opened}
              onClick={handlers}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </MediaQuery>
        ) : null}
      </Box>
      {process.env.REACT_APP_ENV !== 'prod' && (
        <Box>
          <b>{formatMessage({ id: key })}</b>
          {formatMessage({ id: 'header_otherEnv' })}
        </Box>
      )}
      <Box>
        <Group sx={{ flexWrap: 'nowrap', columnGap: '20px' }}>
          <NotificationMenu />
          <AuthMenu />
          {!loggedIn && (
            <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
              {colorScheme === 'dark' ? <Sun size={16} /> : <MoonStars size={16} />}
            </ActionIcon>
          )}
        </Group>
      </Box>
    </BaseHeader>
  )
}

export default Header
