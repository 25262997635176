import React, { useMemo, useState, useEffect } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { showNotification } from '@mantine/notifications'
import { useNavigate } from 'react-router-dom'
import {
  confirmAccount,
  createUser,
  forgetPasswordRequest,
  login as singIn,
  me as iam,
  resetPassword,
} from '../../services/request/auth'
import { IUserAccount } from '../../libs/api-client'
import {
  clearToken,
  getAccessToken,
  getMe,
  getRefreshToken,
  isToken,
  setMe,
  setToken,
} from '../../services/LocalStorageService'
import { ROLE } from '../../types'

interface IAuthContextInterface {
  token: string | null
  refresh_token: string | null
  user: IUserAccount | null
}

export interface IUser {
  id: string
  email: string
  username: string
  name: string
  role: string
  acceptedUseTerm: string | null
  userConsents: {
    authorized: boolean
    id: string
    consentType: string
  }[]
}

export interface IValueAuthInterface {
  token: string | null
  loggedIn: boolean
  refresh_token: string | null
  user: IUser | undefined
  setUser: (value: React.SetStateAction<IUser | undefined>) => void
  isRoleDetenteurPermis: boolean
  isRoleVeterinarian: boolean
  isRoleSupport: boolean
  isRoleMapaq: boolean
  isRoleMapaqRo: boolean
  isRoleAdmin: boolean
  isRolePermitholderOrAbove: (role: ROLE) => boolean
  isRoleMapaqOrAbove: (role: ROLE) => boolean
  isRoleMapaqRoOrAbove: (role: ROLE) => boolean
  isRoleSupportOrAbove: (role: ROLE) => boolean
  login(username: string, password: string): Promise<any>
  createUser(user: any, url: string): Promise<any>
  resetPassword(reset: any): Promise<any>
  forgetPassword(email: string): Promise<any>
  confirm(confirm: any): Promise<any>
  me(): void
  logout(): void
  hasConsent(consent: string): boolean
}

const authInitialState: IAuthContextInterface = {
  token: null,
  refresh_token: null,
  user: null,
}

interface AuthSchemeProviderProps {
  children: React.ReactNode
}

/** https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/ * */
// @ts-ignore
export const AuthContext = React.createContext<IValueAuthInterface>()

export const isRolePermitholderOrAbove = (role: ROLE) =>
  [ROLE.ROLE_DETENTEUR_PERMIS, ROLE.ROLE_MAPAQ, ROLE.ROLE_SUPPORT, ROLE.ROLE_ADMIN].includes(role)
export const isRoleMapaqOrAbove = (role: ROLE) =>
  [ROLE.ROLE_MAPAQ, ROLE.ROLE_SUPPORT, ROLE.ROLE_ADMIN].includes(role)
export const isRoleMapaqRoOrAbove = (role: ROLE) =>
  [ROLE.ROLE_MAPAQ_RO, ROLE.ROLE_MAPAQ, ROLE.ROLE_SUPPORT, ROLE.ROLE_ADMIN].includes(role)
export const isRoleSupportOrAbove = (role: ROLE) =>
  [ROLE.ROLE_SUPPORT, ROLE.ROLE_ADMIN].includes(role)

const AuthProvider = ({ children }: AuthSchemeProviderProps) => {
  const navigate = useNavigate()

  const [user, setUser] = useState<IUser | undefined>(getMe)

  useEffect(() => {
    if (user) setMe(user)
  }, [user])

  const value = useMemo<IValueAuthInterface>(
    () => ({
      token: getAccessToken(),
      loggedIn: isToken(),
      refresh_token: getRefreshToken(),
      user,
      setUser,
      isRoleDetenteurPermis: user?.role === ROLE.ROLE_DETENTEUR_PERMIS,
      isRoleMapaq: user?.role === ROLE.ROLE_MAPAQ,
      isRoleMapaqRo: user?.role === ROLE.ROLE_MAPAQ_RO,
      isRoleAdmin: user?.role === ROLE.ROLE_ADMIN,
      isRoleVeterinarian: user?.role === ROLE.ROLE_VET,
      isRoleSupport: user?.role === ROLE.ROLE_SUPPORT,
      isRolePermitholderOrAbove,
      isRoleMapaqOrAbove,
      isRoleMapaqRoOrAbove,
      isRoleSupportOrAbove,
      login: (username: string, password: string) =>
        singIn(username, password).then(data =>
          iam().then(result => {
            setMe(result)
            setUser(result)
          })
        ),
      createUser: (newUser, url) => createUser(newUser, url).then(response => response),
      forgetPassword: email => forgetPasswordRequest(email),
      confirm: confirm =>
        confirmAccount(confirm).then(result => {
          setToken(result.data)
          iam()
            .then(res => {
              setMe(res)
              setUser(res)
            })
            .finally(() => {
              return result
            })
        }),
      resetPassword: reset =>
        resetPassword(reset).then(result => {
          iam().then(res => {
            setUser(res)
          })
          return result
        }),
      me: () => {
        iam().then(data => {
          setUser(data)
        })
      },
      logout: () => {
        clearToken()
        navigate('/login')
        setUser(undefined)
      },
      hasConsent: (consent: string) => {
        return Boolean(
          user?.userConsents?.find(
            (userConsent: { consentType: string }) => userConsent?.consentType === consent
          )
        )
      },
    }),
    [navigate, user, setUser]
  )

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: any | unknown) => {
            if (error?.message === '401') {
              showNotification({
                autoClose: false,
                title: 'Error 401',
                message: 'Your token are expire! 😌',
                color: 'red',
              })
              value.logout()
            } else {
              showNotification({
                autoClose: false,
                title: 'Error',
                message: `${error?.message}`,
                color: 'red',
              })
            }
          },
        }),
      })
  )

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </QueryClientProvider>
  )
}

export default AuthProvider
