import { DeclarationType } from '../../types'

interface Resource {
  ['@id']: string
  ['@type']: string
}

interface IDeclarationIngredient {
  activeIngredient: string
  concentration?: number
  concentrationUnit?: string
  qty?: number
  ordonnanceQc?: boolean
}

interface IDeclarationMedication {
  medication: string
  concentration?: number
  concentrationUnit?: string
  qty?: number
  ordonnanceQc?: boolean
}

interface ISystemProduct {
  ['@id']: string
  id: number
  nameEn: string
  nameFr: string
  code: string
  active: boolean
  statusDate?: string
  productType: string
}

export interface Statistic extends Resource {
  name: string
  values: {
    date: string
    count: {
      key: string
      value: number
    }[]
  }[]
}

export interface PermitType extends Resource {
  id: number
  code: string
  nameFr: string
  nameEn: string
  name?: string
  active: boolean
  createdAt: string
  isUsed: number
  statusDate: string
  updatedAt: string
}

export interface ICountrySubdivision extends Resource {
  id: number
  code: string
  nameFr: string
  nameEn: string
  name?: string
  subdivisionFr: string
  subdivisionEn: string
  countryCode: string
  countryNameFr: string
  countryNameEn: string
  countryName?: string
  active: boolean
  createdAt: string
  isUsed: number
  statusDate: string
  updatedAt: string
}

export interface IPermit extends Resource {
  active: boolean
  countrySubdivision: string
  createdAt: string
  operatorEmail?: string
  id?: number
  isUsed: number
  permitNumber: string
  permitType: string
  statusDate: string
  updatedAt: string
  operatorNumber: number
  entityNumber: number
  mapaqPermitNumber: number
  permitYear: number
  issueDate?: Date
  expiryDate?: Date
  permitStatus?: string
  operatorName?: string
  operatorContact?: string
  entitySocialReason: string
  entityCommonName?: string
  entityUnitNumber?: number
  entityCivicNumber: number
  entityFractionNumber: number
  entityStreetName: string
  entityCityNumber?: number
  entityCityName: string
  entityPostalCode: string
  entityManager?: string
  entityManagerPhoneNumber?: string
  entityManagerPhoneExtension?: number
  inspectionSection?: number
  entityType?: number
  entityLongitude?: number
  entityLatitude?: number
  directionNumber?: number
  directionName?: string
  regionAdministrativeNumber?: number
  regionAdministrativeDescription?: string
  localAdministrationNumber?: number
  localAdministrationName?: string
  mrc: string
  operatorUnitNumber?: string
  operatorCivicNumber?: number
  operatorFractionNumber?: string
  operatorStreetName?: string
  operatorPostalCode?: string
  operatorCityNumber?: number
  operatorCityName?: string
  operatorPhoneNumber?: string
  operatorPhoneExtension?: number
  entitySiteNumber?: string
}

export interface Declaration extends Resource {
  animalAgeMaxLimit: number
  animalAgeMinLimit: number
  animalQty: number
  animalQtySource: string
  createdAt: string
  declarationDate: string
  declarationPermit: IPermit
}

export interface Product extends Resource {
  id: number
  code: string
  name?: string
  nameFr: string
  nameEn: string
  productType: string
  isUsed: number
  active: boolean
  statusDate: string
  createdAt: string
  updatedAt: string
}

export interface ISpecies extends Resource {
  id: number
  code: string
  name?: string
  nameFr: string
  nameEn: string
  officialSite: boolean
  canHaveSite: boolean
  vetValidation: boolean
  isUsed: number
  active: boolean
  statusDate: string
  createdAt: string
  updatedAt: string
}

export interface Medication extends Resource {
  id: number
  code: string
  nameFr: string
  nameEn: string
  name?: string
  shortNameFr: string
  shortNameEn: string
  shortName?: string
  active: boolean
  statusDate: string
  ordonnanceQc: boolean
  createdAt: string
  updatedAt: string
}

export interface ActiveIngredient extends Resource {
  id: number
  code: string
  nameFr: string
  nameEn: string
  name?: string
  ordonnanceQc: boolean
  medicationType: string
  isUsed: number
  active: boolean
  statusDate: string
  createdAt: string
  updatedAt: string
}

export interface MonthlyRegisteredPermitStat extends Resource {
  month: string
  totalPermitsCount: number
  registeredPermitsCount: number
  percentage: number
}

export interface ProductType extends Resource {
  id?: number
  code?: string
  nameFr?: string
  nameEn?: string
  name?: string
  isUsed?: number
  active?: boolean
  statusDate?: string
  createdAt?: string
  updatedAt?: string
}

export interface ICoordinate extends Resource {
  address: {
    addressFirst?: string
    addressSecond?: string
    unitNumber?: string
    civicNumber?: string
    streetName?: string
    poBox?: string
    ruralRoute?: string
    stationInformation?: string
  }
  city: string
  postalCode: string
  countrySubdivision?: string | null
}

interface IMedication extends Resource {
  code: string
}

interface IActiveIngredient extends Resource {
  code: string
}

export interface IDeclarationRead {
  ['@id']: string
  id: number
  declarationDate: string
  declarationPermit: IPermit
  declarationType: `${DeclarationType}`
  sitePermitNumber?: string
  recipientName: string
  siteNumber?: string
  siteName?: string
  productQty: number
  productionStage: string
  productionStageOtherInfo: string
  animalQtySource: string
  animalQtySourceOtherInfo?: string | null
  animalQty: number
  animalAgeMinLimit?: number
  animalAgeMaxLimit?: number
  prescriptionDate?: string | null
  vetLicenseNumber?: string | null
  vetName?: string | null
  recipientCoordinate: ICoordinate
  siteCoordinate: ICoordinate
  product: string | ISystemProduct
  providerNumber?: string | null
  providerNote?: string | null
  declarationIngredient: IDeclarationIngredient[]
  declarationMedication: IDeclarationMedication[]
}

interface IDeclarationTemplateMedication extends Resource {
  medication: IMedication | string
  concentration: number
  qty: number
  concentrationUnit: string
}

interface IDeclarationTemplateActiveIngredients extends Resource {
  activeIngredient: IActiveIngredient | string
  concentration: number
  qty: number
  concentrationUnit: string
}

export interface IDeclarationTemplate extends Resource {
  name: string | null
  description: string | null
  declarationDate: string
  permit: IPermit
  type: string
  sitePermitNumber: string
  recipientName: string
  siteNumber: string
  siteName: string
  product: Product
  productQty: number
  productionStage: string
  productionStageOtherInfo: string
  animalQtySource: string
  animalQtySourceOtherInfo: string
  animalQty: number
  animalAgeMinLimit: number
  animalAgeMaxLimit: number
  prescriptionDate: string
  vetLicenseNumber: string
  vetName: string
  recipientCoordinate: ICoordinate
  siteCoordinate: ICoordinate
  declarationTemplateActiveIngredients: IDeclarationTemplateActiveIngredients[]
  declarationTemplateMedications: IDeclarationTemplateMedication[]
  providerNumber: string
  providerNote: string
  createdAt: string
  updatedAt: string
}

export interface IDeclarationTemplateLotRead extends Resource {
  nameFr: string
  nameEn: string | null
  descFr: string
  descEn: string | null
  declarationTemplates: IDeclarationTemplate[]
}

export interface PermitsWithTransmitionPercentages extends Resource {
  month: string
  nbPermitsWithOneTransmission: number
  nbRegisteredPermits: number
  percentage: number
}

export interface IUserConsent extends Resource {
  consentType: string
  authorized: boolean
}

export interface OmvqVeterinarian extends Resource {
  email: string
}

export interface OmvqEstablishmentVeterinarians extends Resource {
  linkType: string
  omvqVeterinarian: OmvqVeterinarian
}

export interface IOmvqEstablishment extends Resource {
  omvqEstablishmentVeterinarians: OmvqEstablishmentVeterinarians[]
}

export interface IVetDeclarationRead extends Resource {
  id: number
  declarationDate: string | null
  omvqEstablishment: string
  declarationType: `${DeclarationType}`
  vetLicenseNumber: string
  vetName: string
  providerNumber: string | null
  providerNote: string | null
}

export interface IUserAccount {
  ['@id']?: string
  email: string
  username: string
  name: string
  lastName: string
  language: string
  active: boolean
  status: string
  password: string
  roles: string[]
  userConsents: {
    authorized: boolean
    id: string
    consentType: string
  }[]
}

export interface IUserResponse extends IUserAccount {
  ['@context']?: string
  ['@id']: string
  ['@type']: string
  ['hydra:member']: IUserAccount[]
  ['hydra:totalItems']: number
  ['hydra:view']: any
}

export interface IHydraView {
  ['@id']: string
  ['hydra:first']?: string
  ['hydra:previous']?: string
  ['hydra:next']?: string
  ['hydra:last']?: string
}

export interface IArrayResponse<T> {
  ['@context']: string
  ['@id']: string
  ['@type']: string
  ['hydra:member']: T[] | []
  ['hydra:view']: IHydraView
  ['hydra:totalItems']: number
  ['hydra:search']?: []
}

export interface IResponse {
  ['@context']: string
  ['@id']: string
  ['@type']: string
}

export interface MedicationType extends Resource {
  active: boolean
  atcVetCode?: string
  code?: string
  createdAt?: string
  isUsed?: number
  nameEn?: string
  nameFr?: string
  statusDate?: string
  updatedAt?: string
}

export enum CanadaProvinceCodes {
  QC = 'CA-QC',
}
