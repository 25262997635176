import React from 'react'
import { extractServerErrors, extractViolations } from '../../services/FormViolations'
import { useIntlLight } from '../../libs/i18n'

export interface UseFormErrors {
  values: any
  errors: any
  serverErrors: any
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
function mergeDeep(...objects: any) {
  const isObject = (obj: any) => obj && typeof obj === 'object' && obj.constructor !== Date

  return objects.reduce((prev: any, obj: any) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        // eslint-disable-next-line no-param-reassign
        prev[key] = pVal.concat(...oVal)
      } else if (isObject(pVal) && isObject(oVal)) {
        // eslint-disable-next-line no-param-reassign
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        // eslint-disable-next-line no-param-reassign
        prev[key] = oVal
      }
    })

    return prev
  }, {})
}

function useFormServerErrors<T>({ values, errors, serverErrors }: UseFormErrors) {
  const { trans } = useIntlLight()

  const extractFormErrors = (myFormErrors: any): any => {
    const newObj: any = {}
    Object.keys(myFormErrors).forEach(key => {
      if (
        myFormErrors[key] instanceof Object &&
        !myFormErrors[key].message &&
        myFormErrors[key].constructor !== Date
      ) {
        newObj[key] = extractFormErrors(myFormErrors[key])
      } else if (myFormErrors[key] && myFormErrors[key].message) {
        newObj[key] = trans(myFormErrors[key].message)
      }
    })
    return newObj
  }

  const tmpErrors = extractFormErrors(errors)
  const tmpServerErrors = serverErrors
    ? extractServerErrors(values, extractViolations(serverErrors))
    : {}
  const formErrors = mergeDeep(tmpErrors, tmpServerErrors)
  return { formErrors }
}

export default useFormServerErrors
