import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  root: { height: '100%', display: 'block' },
  trClickable: {
    cursor: 'crosshair',
  },
  thContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tableContainer: {
    display: 'block',
    overflow: 'auto',
    '& > table': {
      '& > thead': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[1],
        zIndex: 1,
      },
      '& > thead th:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
      },
      '& > tbody tr:nth-of-type(odd)': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
      },
      '& > tbody tr:-of-type(even)': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
      },
      '& > tbody tr:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      },
      '& > tbody tr.row-checked': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.pink[1],
      },
    },
  },
  th: {
    padding: '0 !important',
  },
  stickHeader: { top: 0, position: 'sticky' },
  sortableHeader: { '&:hover': { backgroundColor: theme.colors.gray[2] } },
  disableSortIcon: { color: theme.colors.gray[5] },
  sortDirectionIcon: { transition: 'transform 200ms ease' },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  checkboxCell: {
    marginLeft: '400 px',
  },
  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}))
