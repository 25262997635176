// fr_CA.ts

const messages = {
  // Révision linguistique 2023-09-05 DÉBUT
  'default.welcome': 'Bonjour!',
  Unknown: 'Non défini',
  chooseSelectLabel: 'Choisissez',
  loading: 'Chargement',
  yes: 'Oui',
  no: 'Non',
  debug_section_title: 'Section de Debug',
  debug_create_fe_error: 'Créer une erreur Front End',
  debug_create_be_error: 'Créer une erreur Back End',
  deletion: 'Suppression',
  deletionGenericMessage: "Voulez-vous vraiment supprimer l'item?",
  deletionGenericMessageMultiple: 'Voulez-vous vraiment supprimer les items?',
  or: 'ou',
  pageLoaderLabel: 'Chargement en cours',
  clickHere: 'Cliquez ici',
  dialogDeleteWithHash: 'Veuillez copier le code dans le champ ci-dessous',
  declarationStepper_stepVeterinary_emptyRecentMostUsedListLabel:
    'Aucun médecin vétérinaire trouvé',
  dashboard_statistics_totalTransmittedDeclarations_title:
    '{count,plural,one{Déclaration transmise}other{Déclarations transmises}}',
  dashboard_statistics_totalPendingDeclarations_title:
    '{count,plural,one{Déclaration}other{Déclarations}} en attente',
  dashboard_statistics_totalTransmittedDeclarationsWithoutPermit_title:
    '{count,plural,one{Déclaration transmise}other{Déclarations transmises}} sans n° de permis',
  dashboard_statistics_totalTransmittedDeclarationsWithoutTraceabilityNumber_title:
    '{count,plural,one{Déclaration transmise}other{Déclarations transmises}} sans n° de traçabilité du site',
  search: 'Rechercher',
  declarations_productionSite_sitePermitNumber: 'N° de permis {sitePermitNumber}',
  declarations_productionSite_siteNumber: 'N° de site {siteNumber}',
  declarations_productionSite_verification_start:
    'Vous avez déjà entré des coordonnées pour le {prefix} :',
  declarations_productionSite_verification_end: 'Voulez-vous utiliser ces coordonnées?',
  declaration_stepper_toolTip_stepSite_siteNumber:
    'La plateforme AntibiotiQC est configurée pour valider le numéro de traçabilité du site pour les bovins, les ovins et les cervidés qui sont visés par le Règlement sur l’identification et la traçabilité de certains animaux, découlant de la Loi sur la protection sanitaire des animaux. Dans l’environnement de test préproduction, pour ces espèces seuls les numéros suivants sont acceptés : QC0000000, QC1111111, QC2222222, QC3333333, QC4444444, QC5555555, QC6666666, QC7777777, QC8888888 et QC9999999. ',
  declarations_veterinaire_licence_notValid:
    "Le numéro de permis d'exercice doit être composé de 4 chiffres",
  declarationEmbeddedTable_createdBy: 'Créé par',
  declarations_dropdownMenu_duplicate: 'Dupliquer une déclaration',
  declarations_menu_button_duplicate: 'Dupliquer',
  declarations_dropdownMenu_duplicate_hint:
    ' Veuillez choisir le même client et permis déclarant pour dupliquer une déclaration.',
  declarations_dropdownMenu_delete: 'Supprimer',
  declarations_dropdownMenu_saveModel: 'Sauvegarder comme modèle',
  declarations_dropdownMenu_saveModel_hint:
    ' Veuillez choisir le même client et permis déclarant pour créer un modèle.',
  declarations_dropdownMenu_duplicate_saveModel_hint:
    'Veuillez choisir le même client et permis déclarant pour créer un modèle ou dupliquer des déclarations.',
  all_choose: 'Choisir',
  all_none: 'Aucun(e)',
  all_save: 'Sauvegarder',
  all_close: 'Fermer',
  address: {
    lineOne: 'Ligne adresse 1',
    lineTwo: 'Ligne adresse 2',
    address: 'Adresse',
    city: 'Ville',
    province: 'Province',
    state: 'État',
    postalCode: 'Code postal',
    country: 'Pays',
    unitNumber: 'App.',
    civicNumber: 'N° rue',
    streetName: 'Nom rue',
    poBox: 'Boîte postale',
    stationInformation: 'Succursale',
    ruralRoute: 'Route rurale',
    dividerOthersFields: "Autres champs d'adresse",
    globalValidation:
      'Vous devez remplir une de ces conditions:  ligne adresse 1, Boîte postale, Route rurale ou numéro et nom de rue',
  },
  systemBase: {
    back: 'Annuler',
    languages: {
      fr: 'Français',
      en: 'English',
    },
    prevStep: 'Précédent',
    showNotification: {
      systemMessageLabel: 'Message du système',
      serverValidationFailed: 'Erreur de validation au serveur',
    },
    tableLister: {
      deleteSelection: 'Supprimer',
      deleteText: 'Les éléments sélectionnés seront supprimés, voulez-vous continuer…',
      deleteSelectionMessage: 'Êtes-vous certain de vouloir supprimer les items sélectionnés?',
      labelActions: 'Actions',
      labelDesactiver: 'Désactiver',
      labelDesactiverConfirmed: 'Êtes-vous certain de vouloir désactiver?',
      labelActiver: 'Activer',
      labelActiverConfirmed: 'Êtes-vous certain de vouloir activer?',
      labelMenuPopupSearch: 'Recherche',
      labelMenuPopupApp: 'Application',
      labelMenuPopupItemSelected: 'Items sélectionnés',
      filterDateSelect: 'Entrez une date',
      filterDateMax: 'Date limite maximale',
      filterDateMin: 'Date limite minimale',
    },
  },
  home: {
    title: 'AntibiotiQC',
    introduction: {
      two: "La transmission, par les détenteurs de permis des registres de ventes et fournitures des prémélanges et aliments médicamenteux, devra se faire en utilisant l'une ou l’autre des méthodes suivantes :",
      three: "Par le biais d'une interface API (Application Programming Interface)",
      four: `Au moyen d'un formulaire Web de saisie manuelle.`,
    },
    section_one:
      'AntibiotiQC est la plateforme de monitorage de l’utilisation des antibiotiques en santé animale au Québec du ministère de l’Agriculture, des Pêcheries et de l’Alimentation (MAPAQ).',
    section_two:
      'Développée par Attestra la plateforme permet, entre autres, aux détenteurs de permis relatifs aux prémélanges et aliments médicamenteux de transmettre les registres des ventes et des fournitures de ces produits dans le cadre du Règlement sur les prémélanges médicamenteux et aliments médicamenteux destinés aux animaux',
    section_three:
      "Pour obtenir un compte utilisateur dans l'environnement de test préproduction, contactez {email}. Cet environnement vous permet de réaliser des tests en prenant soin d’indiquer des données dépersonnalisées (ex. : noms de clients fictifs). Il peut être temporairement inaccessible.",
    section_two_link: 'Chapitre P-42, r.10',
    section_three_dev:
      'Un <a target="_blank" rel="noreferrer" href="https://attestra.com/solutions-technologiques/monitorage-des-antibiotiques/programme-daide-financiere/"><b>programme d\'aide financière</b></a> est disponible pour les détenteurs de permis souhaitant procéder aux ajustements requis de leur système informatique pour la transmission de leurs registres de vente et fourniture des prémélanges médicamenteux et aliments médicamenteux.',
    section_four_dev: `Vous pouvez consulter la documentation pour la transmission par API en cliquant sur <b>Documentation API</b>. Si vous le souhaitez, vous pouvez {url} pour obtenir les informations nécessaires à la création d'un compte utilisateur dans l'environnement de test préproduction. Vous pourrez ensuite utiliser les fonctions <b>Inscrivez-vous</b> et <b>Connectez-vous</b>.`,
    section_four_dev_linkText: `contacter le Soutien technique d'Attestra`,
    section_four_prod: `Vous pouvez consulter la documentation pour la transmission par API en cliquant sur <b>Documentation API</b>.`,
    section_five_dev: `Le développement de la plateforme se poursuivra jusqu'à sa mise en service, prévue pour le début de l'année 2024. Par conséquent, nous vous recommandons de rafraîchir les pages Web en appuyant simultanément sur les touches Ctrl et F5 lors de vos visites.`,
    moreInfo: {
      intro: "Pour plus d'informations :",
      one: 'Pour consulter la documentation pour la transmission par API',
      two: 'Pour en savoir plus sur les données à transmettre au MAPAQ',
      three: `Règlementation sur l’utilisation des antibiotiques chez les animaux`,
      four: `Règlement sur les prémélanges médicamenteux et les aliments médicamenteux destinés aux animaux (Chapitre P-42, r.10)`,
    },
    support: {
      intro: 'Soutien aux utilisateurs :',
      one: `Pour les questions d'ordre technique, communiquez avec l'équipe du {url}.`,
      two: `Pour les questions relatives à l'application règlementaire ou pour toute autre question, communiquez avec l'équipe du monitorage au MAPAQ {url}.`,
      three: `Pour les questions relatives au programme d'aide financière destiné aux détenteurs de permis, communiquez avec l'équipe d'Attestra responsable de la mise en œuvre du programme {url}.`,
    },
    copyright: '© Gouvernement du Québec ({year}) - Reproduit sous licence',
    login_button: 'Se connecter',
    register_button: "S'inscrire",
    doc_url: 'Documentation API',
    builtBy: '<b>AntibiotiQC</b> est développée par Attestra.',
  },
  signup: {
    title: 'Inscription!',
  },
  signupConfirm: {
    welcome:
      "Bienvenue sur la plateforme AntibiotiQC.  Veuillez procéder à la confirmation de votre identité en saisissant le code reçu à l'adresse courriel d'inscription dans le champ prévu à cet effet ci-dessous.",
    enterCode: 'Saisir le code',
  },
  signup_recaptchaFailed: "L'inscription a échoué",
  login: {
    title: 'Bienvenue sur AntibiotiQC',
    divider_label: 'Ou poursuivez en utilisant votre adresse courriel',
    name_label: 'Nom',
    name_placeholder: 'Votre nom',
    email_label: 'Courriel',
    password_label: 'Votre mot de passe',
    password_placeholder: 'Mot de passe',
    password_confirmation_label: 'Confirmation du mot de passe',
    password_noMatch: 'Le mot de passe saisi ne correspond pas',
    email_placeholder: 'votre@courriel.com',
    forget_password_label: 'Mot de passe oublié ou inscription incomplète?',
    button: 'Connexion',
    dont_have_account_label: 'Créer un nouveau compte',
    or: 'ou',
    loginAttempt: 'Tentative de connexion',
    loginSuccess: 'Connexion réussie',
    wrongLoginCredentials:
      "La combinaison entre le nom d'utilisateur et le mot de passe est invalide",
  },
  register: {
    title: 'Inscription',
    dividerPersonalInfos: 'Informations du compte',
    dividerPermitInfos: "Code d'accès du permis",
    dividerTerms: "Vous devez accepter les conditions d'utilisation",
    button: "S'inscrire",
    terms_label: "J'accepte les conditions d'utilisation",
  },
  formErrorBase: {
    required: 'Ce champ est obligatoire',
    validNumber: 'Veuillez saisir un nombre',
    positiveNumber: 'Veuillez saisir un nombre positif',
    integer: 'Veuillez saisir un nombre entier',
    maxLength255: 'Ce champ est limité à un maximum de 255 caractères',
    maxLength50: 'Ce champ est limité à un maximum de 50 caractères',
    maxLength25: 'Ce champ est limité à un maximum de 25 caractères',
    maxLength15: 'Ce champ est limité à un maximum de 15 caractères',
    postalCode: 'Le code postal doit être entré selon le format suivant : X0X 0X0',
    postalCode_CA: 'Le code postal doit être entré selon le format suivant : X0X 0X0',
    postalCode_US:
      'Le code postal doit être entré selon un des deux formats suivants : 12345 ou 12345-6789',
    postalCode_null: 'Impossible de valider le code postal, le pays est absent',
    postalCode_undefined: 'Impossible de valider le code postal, le pays est absent',
    email: "L'adresse courriel doit être valide",
    phone: 'Le numéro de téléphone doit être valide',
    passwordMin: 'Le mot de passe doit contenir un minimum de 14 caractères',
    acceptTerms: "Vous devez cocher la case afin d'accepter les termes et conditions",
  },
  formErrorBase_endDateGreatherThanStartDate:
    'La date de fin doit être postérieure à la date de début',
  typeBase: {
    labelName: 'Nom',
    labelNameFr: 'Nom français',
    labelNameEn: 'Nom anglais',
    labelCode: 'Code',
    labelActive: 'Actif',
    labelBtnSave: 'Enregistrer',
    allTransmit: 'Transmettre',
    labelBtnAdd: 'Ajouter',
    productType: 'Type de produit',
    medicationType: 'Type de médicament',
    species: 'Espèces',
    product: 'Produits',
    badgeLabelActive: 'Actif',
    badgeLabelInactive: 'Inactif',
    cancelConfirmDirtyForm: 'Les informations non sauvegardées seront perdues',
    labelBtnCancel: 'Annuler',
    labelBtnCancelConfirm: 'Êtes-vous certain de vouloir supprimer? ',
    labelBtnConfirm: 'Confirmer',
    LabelBtnResetFilter: 'Réinitialiser les filtres',
    LabelBtnResetSort: 'Réinitialiser les tris',
    LabelBtnResetDashboardFilter: 'Réinitialiser les filtres du tableau de bord',
    messageActivatedSuccess: 'Les items ont été activés avec succès',
    messageDeactivatedSuccess: 'Les items ont été désactivés avec succès',
    messageEditSuccess: "L'item a été modifié avec succès",
    note: 'Note',
  },
  typeBase_labelActive: 'Statut',
  typeBase_badgeLabelActive: 'Actif',
  typeBase_badgeLabelInactive: 'Inactif',
  typeBase_statusDate: 'Date du statut',
  typeBase_createdAt: 'Date de création',
  productType: {
    titleLister: 'Types de produits',
    titleFormAdd: 'Ajouter un type de produit',
    titleFormEdit: 'Modifier un type de produit',
    messageAddSuccess: 'Le type de produit a été ajouté avec succès',
    messageEditSuccess: 'Le type de produit a été modifié avec succès',
    messageDeleteSuccess: 'Le type de produit a été supprimé avec succès',
    messageActivatedSuccess: 'Les types de produits ont été activés avec succès',
    messageDeactivatedSuccess: 'Les types de produits ont été désactivés avec succès',
  },
  product: {
    titleLister: 'Produits',
    titleFormAdd: 'Ajouter un produit',
    titleFormEdit: 'Modifier un produit',
    messageAddSuccess: 'Le produit a été ajouté avec succès',
    messageEditSuccess: 'Le produit a été modifié avec succès',
    messageDeleteSuccess: 'Le produit a été supprimé avec succès',
    messageActivatedSuccess: 'Les produits ont été activés avec succès',
    messageDeactivatedSuccess: 'Les produits ont été désactivés avec succès',
  },
  medicationType: {
    labelAtcVetCode: 'Code actuel',
    titleLister: 'Types de médicaments',
    titleFormAdd: 'Ajouter un type de médicament',
    titleFormEdit: 'Modifier un type de médicament',
    messageAddSuccess: 'Le type de médicament a été ajouté avec succès',
    messageEditSuccess: 'Le type de médicament a été modifié avec succès',
    messageDeleteSuccess: 'Le type de médicament a été supprimé avec succès',
    messageActivatedSuccess: 'Les types de médicament ont été activés avec succès',
    messageDeactivatedSuccess: 'Les types de médicament ont été désactivés avec succès',
    tableListerListIngredient: 'Liste des ingrédients',
    tableListerAddIngredient: 'Ajouter un ingrédient',
    tableListerHeaderTypeIngredient: 'Ingrédient actif',
    tableListerHeaderConcentration: 'Concentration',
    tableListerHeaderUnit: 'Unité',
    tableListerRemoveIngredient: "Retirer l'ingrédient",
  },
  medicationType_tableListerHeaderDinNumber: "Numéro d'identification de médicament (DIN)",
  medicationType_tableListerHeaderStartDate: 'Date de début',
  medicationType_tableListerHeaderEndDate: 'Date de fin',
  medicationType_tableListerRemoveDin: "Retirer un numéro d'identification de médicament (DIN)",
  medicationType_tableListerAddDin: "Ajouter un numéro d'identification de médicament (DIN)",
  medicationType_tableListerListDin: "Liste des numéros d'identification de médicament (DIN)",
  medicationType_excelName: 'TypesMédicaments',
  species: {
    labelOfficialSite: 'Numéro de site règlementé',
    labelCanHaveSite: 'Peut avoir un site',
    titleLister: 'Espèces',
    titleFormAdd: 'Ajouter une espèce',
    titleFormEdit: 'Modifier une espèce',
    messageAddSuccess: "L'espèce a été ajoutée avec succès",
    messageEditSuccess: "L'espèce a été modifiée avec succès",
    messageDeleteSuccess: "L'espèce a été supprimée avec succès",
    messageActivatedSuccess: 'Les espèces ont été activées avec succès',
    messageDeactivatedSuccess: 'Les espèces ont été désactivées avec succès',
  },
  medication: {
    labelShortName: 'Nom court',
    labelShortNameFr: 'Nom français court',
    labelShortNameEn: 'Nom anglais court',
    titleLister: 'Médicaments commerciaux',
    titleFormAdd: 'Ajouter un médicament commercial',
    titleFormEdit: 'Modifier un médicament commercial',
    medicationActiveIngredientsUnitPpm: 'ppm',
    medicationActiveIngredientsUnitG_Kg: 'g/kg',
    messageAddSuccess: 'Le médicament commercial a été ajouté avec succès',
    messageEditSuccess: 'Le médicament commercial a été modifié avec succès',
    messageDeleteSuccess: 'Le médicament commercial a été supprimé avec succès',
    messageActivatedSuccess: 'Les médicaments commerciaux ont été activés avec succès',
    messageDeactivatedSuccess: 'Les médicaments commerciaux ont été désactivés avec succès',
    labelOrdonnanceQc: 'Ordonnance (QC)',
  },
  medicationFormError_dimNumberFormat:
    "Le numéro d'identification de médicament (DIN) ne doit contenir que des chiffres",
  productionStage: {
    labelUnitLimit: 'Unité d’âge',
    labelPrecision: 'Précision obligatoire',
    titleLister: 'Stades de production',
    titleFormAdd: 'Ajouter un stade de production',
    titleFormEdit: 'Modifier un stade de production',
    messageAddSuccess: 'Le stade de production a été ajouté avec succès',
    messageEditSuccess: 'Le stade de production a été modifié avec succès',
    messageDeleteSuccess: 'Le stade de production a été supprimé avec succès',
    messageActivatedSuccess: 'Les stades de production ont été activés avec succès',
    messageDeactivatedSuccess: 'Les stades de production ont été désactivés avec succès',
    unitLimit: {
      kg: 'kg',
      days: 'Jours',
    },
  },
  productionStage_excelName: 'StadeDeProduction',
  productionStage_canHaveSite_formLabel:
    "Peut avoir un site (si ce champ est laissé vide, c'est la valeur de l'espèce qui sera utilisée)",
  productionStage_canHaveSite_listerLabel: 'Peut avoir un site',
  productionStage_officialSite_formLabel:
    "N° de site règlementé (si ce champ est laissé vide, c'est la valeur de l'espèce qui sera utilisée)",
  productionStage_officialSite_listerLabel: 'N° de site règlementé',
  activeIngredient: {
    titleLister: 'Ingrédients actifs',
    titleFormAdd: 'Ajouter un ingrédient actif',
    titleFormEdit: 'Modifier un ingrédient actif',
    messageAddSuccess: "L'ingrédient actif a été ajouté avec succès",
    messageEditSuccess: "L'ingrédient actif a été modifié avec succès",
    messageDeleteSuccess: "L'ingrédient actif a été supprimé avec succès",
    messageActivatedSuccess: 'Les ingrédients actifs ont été activés avec succès',
    messageDeactivatedSuccess: 'Les ingrédients actifs ont été désactivés avec succès',
    labelOrdonnanceQc: 'Ordonnance (QC)',
  },
  activeIngredient_excelName: 'IngrédientsActifs',
  permitNumber_validation_badFormat:
    'Le numéro de permis doit être dans le format suivant : [1 à 10 chiffres]-[1 à 5 chiffres]-[1 à 3 chiffres]',
  permit: {
    title: 'Permis',
    titleLister: 'Liste des permis',
    titleFormAdd: 'Ajouter un permis',
    titleFormEdit: 'Modifier un permis',
    messageAddSuccess: 'Le permis a été ajouté avec succès',
    messageEditSuccess: 'Le permis a été modifié avec succès',
    messageDeleteSuccess: 'Le permis a été supprimé avec succès',
    messageActivatedSuccess: 'Les permis ont été activés avec succès',
    messageDeactivatedSuccess: 'Les permis ont été désactivés avec succès',
    labelEmail: 'Courriel',
    labelPhone: 'Téléphone',
    labelSocialReason: 'Raison sociale',
    labelSiteName: "Nom abrégé de l'exploitant",
    labelPermitNumber: 'N° de permis',
    systemMessageTitleSearchNoResult: 'Aucun résultat',
    systemMessageTitleSearchResultFound: 'Résultats trouvés',
    systemMessageSearchNoResult: 'Aucun permis trouvé',
    autocompletePlaceholder: 'Rechercher un permis',
  },
  permit_permitTypeLabel: 'Catégorie de permis',
  permit_expiryDate: `Date d'expiration du permis`,
  permit_importFormCsv_formTitle: 'Importation de la liste BAK',
  permit_importFormCsv_importTypeLabel: "Type d'importation",
  permit_importFormCsv_importTypePublic: 'Liste publique',
  permit_importFormCsv_importFileLabel: 'Fichier CSV',
  permit_importFormCsv_btnLabel: 'Importer fichier CSV',
  permit_importFormCsv_sendingFile: 'Importation en cours',
  permit_importFormCsv_fileTreated: 'Importation terminée',
  permit_importFormCsv_serverError: "L'importation a échoué",
  permit_importFormCsv_permitInserted:
    '{count} {count,plural,one{permis inséré}other{permis insérés}}',
  permit_importFormCsv_permitUpdated:
    '{count} {count,plural,one{permis modifié}other{permis modifiés}}',
  permit_importFormCsv_permitError: '{count} permis en erreur',
  permit_importFormCsv_permitEmptyEntry:
    'Le fichier CSV contient des lignes vides, ce qui signifie que seules certaines données ont été importées',
  permit_importFormCsv_importTypeBak: 'Liste BAK',
  permit_importFormCsv_importFileBakLabel: 'Fichier EXCEL',
  permitCode_titleLister: "Code d'accès",
  permitCode_titleFormAdd: "Ajouter un code d'accès",
  permitCode_titleFormEdit: "Modifier un code d'accès",
  permitCode_messageAddSuccess: "Le code d'accès a été ajouté avec succès",
  permitCode_messageEditSuccess: "Le code d'accès a été modifié avec succès",
  permitCode_messageDeleteSuccess: "Le code d'accès a été supprimé avec succès",
  permitCode_messageDeleteConfirmation: "Êtes-vous certain de vouloir supprimer ce code d'accès?",
  permitCode_messageActivatedSuccess: "Les codes d'accès ont été activés avec succès",
  permitCode_messageDeactivatedSuccess: "Les codes d'accès ont été désactivés avec succès",
  permitCode_labelCodeType: 'Type',
  permitCode_labelCode: "Code d'accès",
  permitCode_labelPermit: 'Permis',
  permitCode_labelUsedDate: "Date d'activation",
  permitCode_labelCreatedDate: 'Date de création',
  permitCode_labelUserAccount: 'Utilisateur',
  permitCode_codeType_ADMIN: 'Gestionnaire de permis',
  permitCode_codeType_USER: 'Délégué',
  permitCode_codeType_delegate: 'Délégué',
  permitCode_codeType_API_KEY: "Clé d'API",
  permitCode_chooseCodeType: 'Choisir le type de code',
  permitCode_chooseApiKey: "Choisir la clé d'API",
  permitCodeActivation: {
    navTitle: 'Lier un permis à mon profil',
    labelPermitNumber: 'N° de permis',
    labelCode: "Code d'accès",
    formTitle: 'Veuillez entrer les informations qui vous ont été envoyées',
    messageActivationError: "Ce code n'est pas valide",
    messageActivationSuccess: 'Vous avez maintenant accès au permis',
  },
  declaration: {
    titleLister: 'Déclarations',
    labelDeclarationDate: 'Transaction',
    labelDeclarationCreatedAt: 'Transmission',
    labelDeclarationRecipientName: 'Client',
    labelPermit: 'Permis du déclarant',
    siteProduction: 'Site de production',
    messageDeleteSuccess: 'La déclaration a été supprimée avec succès',
    labelDeclarationProduct: 'Produit',
    editTitle: 'Modifier une déclaration',
  },
  declaration_labelDeclarationType: 'Type de déclaration',
  declaration_labelDeclarationType_SALE_SUPPLY: 'Vente ou fourniture',
  declaration_labelDeclarationType_RETURN: 'Retour',
  declaration_createdWebForm_web: 'Formulaire Web',
  declaration_createdWebForm_api: 'API',
  pendingDeclaration: {
    titleLister: 'Déclarations en attente',
    labelDeclarationLastModified: 'Sauvegarde',
    labelDeclarationRecipientName: 'Client',
    labelPermit: 'Permis',
    messageAddSuccess: "L'état a été ajouté avec succès",
    messageEditSuccess: "L'état a été modifié avec succès",
    messageDeleteSuccess: "L'état a été supprimé avec succès",
  },
  dashboard_title: 'Tableau de bord',
  dashboard_roleDetenteurPermisTitle: 'Détenteur de permis',
  dashboard_roleAdminMapaqTitle: 'Administratif',
  dashboard_roleVeterinarianTitle: 'Vétérinaire',
  navLink: {
    userLabel: 'Utilisateurs',
    securityLabel: 'Sécurité',
    pagesLabel: 'Pages',
    listerLabel: 'Lister',
    addLabel: 'Ajouter',
    manageTypeLabel: 'Catégories',
    change_password: 'Changer le mot de passe',
    recovery_codes: 'Récupérer les codes',
    label_404: '404',
  },
  table: {
    reset_filter_button: 'Réinitialiser le filtre',
    header: {
      active: 'Actif',
      action: 'Actions',
    },
  },
  table_noResult: 'Aucun résultat trouvé',
  user: {
    titleLister: 'Utilisateurs',
    titleFormAdd: 'Ajouter un utilisateur',
    titleFormEdit: 'Modifier un utilisateur',
    messageAddSuccess: "L'utilisateur a été ajouté avec succès",
    messageEditSuccess: "L'utilisateur a été modifié avec succès",
    messageDeleteSuccess: "L'utilisateur a été supprimé avec succès",
    messageActivatedSuccess: 'Les utilisateurs ont été activés avec succès',
    messageDeactivatedSuccess: 'Les utilisateurs ont été désactivés avec succès',
    labelName: 'Nom',
    labelEmail: 'Courriel',
    labelPhone: 'Téléphone',
    labelLanguage: 'Langue',
    labelRole: 'Rôle',
    errorRole: 'Vous devez entrer au moins 1 rôle',
    roles: {
      ROLE_USER: 'Utilisateur',
      ROLE_ADMIN: 'Administrateur',
      ROLE_SUPPORT: 'Support technique',
      ROLE_MAPAQ: 'MAPAQ (Pilotage)',
      ROLE_MAPAQ_RO: 'MAPAQ (Lecture seule)',
      ROLE_DETENTEUR_PERMIS: 'Détenteur de permis',
      ROLE_VET: 'Vétérinaire',
    },
    labelConsentCommunication: 'Communication Attestra/MAPAQ',
    consentCommunication: {
      authorized: 'Autorisée',
      nonAuthorized: 'Non autorisée',
    },
    labelStatus: 'Statut',
    status: {
      INACTIVE: 'Inactif',
      ACTIVE: 'Actif',
      PENDING_ACTIVATION: 'En attente',
    },
    table: {
      titleLister: 'Utilisateurs',
      header: {
        email: 'Courriel',
        name: 'Nom',
      },
    },
  },
  pagination: {
    rows_per_page: 'Lignes par page',
    page: 'Page',
    pageOf: 'de',
    total_items: 'Total des éléments',
  },
  forgotPasswordRequest_title: 'Demande de réinitialisation de mot de passe',
  forgotPasswordRequest_instructions:
    "Veuillez saisir votre adresse courriel afin de réinitialiser votre mot de passe ou compléter votre inscription si vous n'avez pas pu entrer votre code d'activation reçu par courriel. Vous recevrez un message contenant un lien qui expire dans 10 minutes. Veuillez suivre ce lien pour confirmer votre identité, réinitialiser votre mot de passe et activer votre accès à la plateforme AntibiotiQC .",
  forgotPasswordRequest_emailLabel: 'Courriel',
  forgotPasswordRequest_submitRequest: 'Envoyer',
  forgotPasswordRequest_emailSent: 'Un courriel vous a été envoyé',
  forgotPasswordRequest_resetAttempt: 'Demande de réinitialisation en cours',
  forgotPasswordRequest_resetAttemptFailed: 'Échec de la demande',
  passwordForgotRequestInstructions_instructions:
    'Vous recevrez un courriel de réinitialisation de mot de passe. Veuillez suivre attentivement les instructions qui y sont fournies.',
  passwordForgotRequestInstructions_goHome: "Retournez à l'accueil",
  passwordForgotRequestInstructions_goLogin: 'Allez à la page de connexion',
  passwordForgotRequestInstructions_title: 'Demande de réinitialisation de mot de passe envoyée',
  passwordReset_title: 'Réinitialisation de votre mot de passe',
  passwordReset_attempt: 'Mot de passe en cours de réinitialisation',
  passwordReset_success: 'Mot de passe réinitialisé',
  passwordReset_failed: 'Échec de la réinitialisation',
  forgotPassword_passwordLabel: 'Nouveau mot de passe',
  forgotPassword_submitReset: 'Réinitialiser',
  declarationStepper: {
    id: 'Nᴼ',
    recipientStepLabel: 'Client',
    recipientStepDesc: 'Acheteur ou receveur',
    siteStepLabel: 'Site de production',
    siteStepDesc: 'Les sites de production animale',
    productStepLabel: 'Produit',
    productStepDesc: 'Type de produits',
    medicationStepLabel: 'Médicament',
    medicationStepDesc: 'Médicament',
    speciesStepLabel: 'Espèce',
    speciesStepDesc: 'Espèce',
    declarationStepLabel: 'Déclaration',
    declarationStepDesc: 'Déclaration',
    veterinaireStepLabel: 'Médecin vétérinaire',
    veterinaireStepDesc: 'Médecin vétérinaire',
    nextStepBtn: 'Suivant',
    btnEdit: 'Modifier',
    btnDelete: 'Supprimer',
    recentLabel: 'Récents',
    mostUsedLabel: 'Fréquents',
    declarationDateLabel: 'Date de vente ou fourniture',
    declarationPermitLabel: 'Permis de déclaration',
    quantity: 'Quantité',
    concentration: 'Concentration',
    lastUsedDate: 'Dernière utilisation',
    nbUsed: "Nombre d'utilisations",
    stepperStateSaved: 'État sauvegardé',
    concentrationUnit: 'Unité',
    concentrationUnitPPM: 'ppm',
    concentrationUnitMg_Kg: 'mg/kg',
    concentrationUnitG_T: 'g/tonne',
    concentrationUnitKG_T: 'kg/tonne',
    animalQtySourceType: {
      PRESCRIPTION: 'Prescription',
      CLIENT_REGISTRY: 'Registre client',
      OTHER: 'Autre',
    },
    providerSection: 'Information supplémentaire sur la vente ou fourniture',
    providerNumber: 'Référence interne',
    providerNote: 'Note interne',
    stepRecipient: {
      title: 'Client',
      recipientSelected: 'Client(s) sélectionné(s)',
      recipients: 'Liste des clients',
      addRecipient: 'Ajouter un client non mémorisé',
      editRecipient: 'Modifier un client mémorisé',
      recipientNameLabel: 'Nom',
      emptyRecentMostUsedListLabel: 'Aucun client trouvé',
    },
    stepSite: {
      title: 'Site receveur du produit médicamenteux',
      sitesSelected: 'Sites sélectionnés',
      sites: 'Liste des sites de production animale',
      addSite: 'Ajouter un site non mémorisé',
      editSite: 'Modifier le site',
      siteNumberLabel: 'Nᴼ de traçabilité du site',
      emptyRecentMostUsedListLabel: 'Aucun site trouvé',
      sitePermitNumberLabel:
        'Nᴼ de permis relatif aux prémélanges médicamenteux et aliments médicamenteux du site',
      sitePermitNumberFormatError: 'Le format du numéro de permis est incorrect',
    },
    stepProduct: {
      title: 'Détails des produits médicamenteux',
      qtyKgLabel: 'Quantité (kg)',
      productLabel: 'Produit',
      addProduct: 'Ajouter un produit',
      productionStageLabel: 'Espèce',
      animalQtyLabel: "Nombre d'animaux",
      animalQtyLabelShort: `Nombre d'animaux`,
      animalQtySourceLabel: 'Source',
      sourceInfos: "Source pour le nombre d'animaux",
      addProductBtn: 'Ajouter un produit',
      editProduct: 'Modifier un produit',
      noProduct: 'Aucun produit ajouté',
      site: 'Site',
      productSelected: 'Produits sélectionnés',
      otherQtySourceInfoLabel: 'Précisez la source',
      animalInfos: 'Informations sur les animaux',
      animalQuantityMax: 'Vous ne pouvez pas entrer plus de 10000000 animaux',
      productQuantityMax: 'Vous ne pouvez pas entrer plus de 1000000 kg',
      productQuantityMin: 'Vous ne pouvez pas entrer moins de -1000000 kg',
      speciesPrecision: 'Précisez',
      animalMinLimitDays: 'Nombre de jours minimum',
      animalMaxLimitDays: 'Nombre de jours maximum',
      animalMinLimitKg: 'Minimum kg',
      animalMaxLimitKg: 'Maximum kg',
      animalMaxLimitKgGreatherThanMin: 'Le maximum doit être supérieur au minimum',
      maxQty: 'Vous ne pouvez pas entrer plus de 1000',
    },
    stepMedication: {
      title: 'Médicaments commerciaux',
      concentrationLabel: 'Concentration kg/tonne',
      qtyKgLabel: 'Quantité en kg',
      calculatedKgLabel: 'Quantité en kg',
      addActiveIngredient: 'Ajouter un ingrédient actif',
      addActiveIngredientBtnLabel: 'Ingrédient actif',
      editActiveIngredient: 'Modifier un ingrédient actif',
      medications: 'Médicaments commerciaux',
      medication: 'Médicament commercial',
      ingredients: 'Ingrédients',
      ingredient: 'Ingrédient actif',
      btnAddMedication: 'Médicament commercial',
      addMedication: 'Ajouter un médicament commercial',
      editMedication: 'Modifier un médicament commercial',
      maxQtyKg: 'Vous ne pouvez pas entrer plus de 1000000 kg',
      maxConcentration: 'Vous ne pouvez pas entrer plus de 1000000',
      minConcentration: 'Vous ne pouvez pas entrer moins de 0.001',
    },
    stepVeterinaire: {
      title: 'Médecins vétérinaires prescripteurs',
      vetName: 'Nom',
      vetLicenseNumber: "Numéro de permis d'exercice",
      prescriptionDate: "Date d'émission de la prescription",
      addVeterinaire: 'Ajouter un médecin vétérinaire non mémorisé',
      editVeterinaire: 'Modifier un médecin vétérinaire',
      veterinaires: 'Médecins vétérinaires',
    },
    stepDeclaration: {
      title: 'Déclaration',
      date: 'Date',
      permit: 'Permis',
      recipient: 'Client',
      sites: 'Sites',
      product: 'Produits',
      medicationIngredient: 'Médicament/Ingrédient',
      declarationSaved: 'La nouvelle déclaration est transmise avec succès',
      declarationSavedPlural: 'Les {number} nouvelles déclarations sont transmises avec succès',
      declarationUpdated: 'La modification de déclaration est transmise avec succès',
      declarationUpdateServerValidationFailed:
        'La modification de déclaration contient des erreurs, veuillez les corriger',
      serverValidationFailed:
        'La transmission de déclaration contient des erreurs, veuillez les corriger',
      serverValidationFailedPlural:
        'Les transmissions de déclaration contiennent des erreurs, veuillez les corriger',
      veterinaire: 'Médecin vétérinaire',
    },
  },
  declarationStepper_cantDeclareWarning: `Vous n'avez aucun permis valide pour déclarer.`,
  declarationStepper_declarationType: 'Type de déclaration',
  declarationStepper_declarationType_SALE_SUPPLY: 'Vente ou fourniture',
  declarationStepper_declarationType_RETURN: 'Retour',
  declarationStepper_declarationDateLabel_SALE_SUPPLY: 'Date de vente ou fourniture',
  declarationStepper_declarationDateLabel_RETURN: 'Date de retour',
  declarationStepper_declarationDate_Edit: 'Modifier une date de transaction',
  declarationStepper_declarationDate_Add: 'Ajouter une date de transaction',
  declarationStepper_declarationType_Edit: 'Modifier type de déclaration',
  declarationStepper_declarationType_Add: 'Ajouter type de déclaration',
  declarationStepper_stepMedication_addMedicationHelpTooltip_text:
    "Pour remplacer le médicament commercial par un ingrédient actif, il faut d'abord vider la liste de médicaments commerciaux. Ensuite, il faut sélectionner «&nbsp;Ajouter&nbsp;un&nbsp;ingrédient&nbsp;actif&nbsp;» et fournir les informations requises.",
  declarationStepper_stepMedication_addActiveIngredientHelpTooltip_text:
    "Pour remplacer l'ingrédient actif par un médicament commercial, il faut d'abord vider la liste des ingrédients actifs. Ensuite, il faut sélectionner «&nbsp;Ajouter&nbsp;un&nbsp;médicament&nbsp;commercial&nbsp;» et fournir les informations requises.",
  userAuthCard: {
    changePassword: 'Modifier mot de passe',
    darkMode: 'Mode sombre',
    lightMode: 'Mode clair',
    signOut: 'Se déconnecter',
    settings: 'Préférences',
    application: 'Application',
  },
  userAuthCard_viewProfile: 'Visualiser mon profil',
  userAuthCard_profile: 'Profil',
  userApiKey_titleLister: "Clés d'API",
  userApiKey_excelName: 'ClésAPI',
  userApiKey_labelApiKey: 'API Key',
  userApiKey_labelName: 'Nom',
  userApiKey_labelExpiresAt: "Date d'expiration (1 an maximum)",
  userApiKey_labelCreatedBy: 'Créé par',
  userApiKey_labelUser: 'Nom utilisateur',
  userApiKey_addEntityTitle: "Ajouter une clé d'API",
  userApiKey_editEntityTitle: "Modifier une clé d'API",
  userApiKey_messageEditSuccess: 'La clé a été modifiée avec succès',
  userApiKey_messageAddSuccess: 'La clé a été ajoutée avec succès',
  userApiKey_messageDeleteSuccess: 'La clé a été supprimé avec succès',
  userApiKey_messageDeleteFailure: 'La clé ne peut pas être supprimée du système',
  userApiKey_newKeyPopupMessage:
    "Veuillez cliquer sur le bouton pour copier la clé dans votre presse-papier et la sauvegarder de façon sécuritaire (voûte, etc.).<br /><br />Cette clé devra être ajoutée à l'entête (header) HTTP <b>X-AUTH-TOKEN</b> de vos requêtes vers l'API d'AntibiotiQC par votre logiciel.<br /><br />",
  userApiKey_newKeyPopupMessageWaring:
    "Cette clé n'est visible qu'une seule fois. Si vous la perdez, vous devrez en créer une nouvelle.",
  userApiKey_btnClosePlainKey: 'Fermer',
  userApiKey_newKeyCopy: 'Copier la clé',
  userApiKey_newKeyCopied: 'Clé copiée',
  userApiKey_roleLabel: 'Type de clé',
  userApiKey_role_importateur_permis: 'Importateur de permis',
  userApiKey_role_detenteur_permis: 'Détenteur de permis',
  confirmAccount: {
    start: 'Activation en cours',
    success: 'Le compte est activé!',
    failure: 'Utilisateur non trouvé/Jeton expiré',
  },
  quantity: {
    exceed: 'La quantité totale des médicaments dépasse la quantité de produit',
  },
  dirtyPrompt_title: 'Quitter le formulaire',
  dirtyPrompt_message:
    'Voulez-vous vraiment quitter le formulaire? Vos données non sauvegardées seront perdues.',
  dirtyPrompt_btnConfirm: 'Quitter',
  dirtyPrompt_btnCancel: 'Rester',
  passwordRequirement_minChar: 'Inclure au moins 14 caractères',
  passwordRequirement_number: 'Inclure un nombre',
  passwordRequirement_lowercase: 'Inclure une lettre minuscule',
  passwordRequirement_uppercase: 'Inclure une lettre majuscule',
  passwordRequirement_specialChar: 'Inclure un caractère spécial',
  declaration_addDeclaration_disableNotice: 'Cliquez ici pour ne plus afficher ce message',
  declaration_addDeclaration_globalNotice_title: 'Information sur les déclarations à fournir',
  declaration_addDeclaration_globalNotice:
    "Vous devez déclarer uniquement les transactions (ventes ou fournitures) d'aliments ou de prémélanges médicamenteux effectuées à des utilisateurs finaux, <b>ce qui inclut les administrations faites à vos propres animaux</b>.<br /><br /><b>Les transactions intermédiaires ne sont pas à déclarer</b> (par exemple, la vente d'un fabricant à un distributeur dans le but  que ce dernier revende le produit à des utilisateurs finaux).",
  declaration_addDeclaration_globalNotice_note: `Tous les guides et les tutoriels visant à faciliter l'utilisation de la plateforme AntibiotiQC sont disponibles sur le site Web d'Attestra.`,
  declarations_addDeclaration_recipientStep_recipientListHelptooltip_text:
    "Le client est l'acheteur dans le cas d'une vente (transaction financière), ou le receveur dans le cas d'une fourniture (transaction non financière) de l'aliment ou du prémélange médicamenteux.",
  declarations_addDeclaration_recipientForm_nameHelpTooltip_text:
    "Indiquez le nom de la personne physique ou morale (entreprise ou organisme) qui effectue l'achat ou la réception des aliments ou des prémélanges médicamenteux.",
  declarations_addDeclaration_recipientForm_addressHelpTooltip_text:
    "Indiquez l'adresse principale du client. Veuillez noter que cette adresse peut, dans certains cas, différer de celle des sites de livraison des produits.",
  declarations_addDeclaration_sitesList_tooltipHelp_text:
    "Il s'agit des sites où se trouvent les animaux auxquels sont destinés les prémélanges ou les aliments médicamenteux vendus ou fournis. <b>Notez que vous pouvez sélectionner plusieurs sites.</b>",
  declarations_addDeclaration_siteForm_siteNumberHelpTooltip_text:
    'Pour toute vente ou fourniture de <b>prémélange</b> médicamenteux, le client doit obligatoirement posséder un permis relatif aux prémélanges médicamenteux et aliments médicamenteux, en lien avec le site concerné par ladite vente ou fourniture.<br /><br />Si vous n\'avez pas cette information, il vous est possible de la rechercher sur le site Web du gouvernement du Québec. <a target="_blank" href="https://web.mapaq.gouv.qc.ca/bh/etablissements-sous-permis" style="color: #3c7aaf">Cliquez ici</>',
  declarations_addDeclaration_siteForm_addressHelpTooltip_text:
    "Adresse où sont localisés les animaux auxquels sont destinés les prémélanges ou les aliments médicamenteux vendus ou fournis. Notez que celle-ci peut différer de l'adresse principale du client.",
  declarations_addDeclaration_siteForm_addressSection: 'Adresse du site',
  declarations_addDeclaration_siteForm_sameAddressSwitch:
    'Utiliser la même adresse que le client pour ce site',
  declarations_addDeclaration_siteForm_notice: `
    <b>Bovin, ovin et cervidé :</b> Le numéro de traçabilité du site (SimpliTRACE : XX9999999) est suffisant.
    <br />
    <br />
    <b>Porc, volaille :</b> L'adresse complète du site est requise. Le numéro de traçabilité du site (PorcTRACÉ : XX9999999 ou numéro de poulailler) est souhaité.
    <br />
    <br />
    <b>Autres espèces :</b> L'adresse complète du site est requise.
    `,
  declarations_addDeclaration_siteForm_notice_title: 'Coordonnées des sites de production animale',
  declarations_addDeclaration_siteForm_siteNameLabel: 'Nom du site',
  declarations_dashboard_filters_title: 'Filtre de tableau de bord: {suffix}',
  declarations_dashboard_filters_yearFilter_title: `Année {year}`,
  declarations_dashboard_filters_permitNumberFilter_title: `Nᴼ de permis {permitNumber}`,
  dashboard_statistics_tileButton_label: 'Consulter la liste',
  all_clear: 'Vider',
  all_apply: 'Appliquer',
  all_enter_text: 'Entrer une valeur',
  dashboard_statistics_totalTransmittedDeclarationsWithoutPermit_description:
    "Il est nécessaire d'ajouter un numéro de permis relatif aux prémélanges médicamenteux et aliments médicamenteux à {count} {count,plural,one{déclaration transmise}other{déclarations transmises}}. Un prémélange médicamenteux ne peut être vendu ou fourni qu'à un détenteur de permis relatif aux prémélanges médicamenteux et aliments médicamenteux.",
  dashboard_statistics_totalTransmittedDeclarationsWithoutTraceabilityNumber_description:
    "Vous avez transmis {count} {count,plural,one{déclaration pour laquelle}other{déclarations pour lesquelles}} un numéro de traçabilité du site (SimpliTRACE, PorcTRACÉ ou un numéro de poulailler) aurait pu être renseigné. Cette donnée est nécessaire pour un retour d'information efficace à vos clients. SVP modifiez les déclarations concernées.",
  all_goToAntibiotiQC: 'Aller sur AntibiotiQC',
  auth_forgotPassword_reset_instructions:
    'Veuillez saisir un nouveau mot de passe et vous connecter sur AntibiotiQC',
  navbar_applicationKeyManagement: "Clés d'API",
  auth_resetPasswordForm_passwordRepeat_label: 'Confirmation du mot de passe',
  dashboard_statistics_filtersForm_yearSelect_label: 'Année de déclaration',
  dashboard_statistics_filtersForm_yearSelect_placeholder: "Choisir l'année",
  dashboard_statistics_filtersForm_permit_label: 'Nᴼ de permis',
  all_filter: 'Filtrer',
  dashboard_statistics_filtersForm_permitSelect_placeholder: 'Choisir le permis',
  declarations_addDeclaration_concentrationHelpTooltip_text:
    "Cette concentration correspond à l'incorporation de «&nbsp;{elementName}&nbsp;» dans le produit «&nbsp;{productName}&nbsp;»",
  declarations_addDeclaration_quantityHelpTooltip_text:
    "Attention à ne pas confondre cette valeur avec la quantité de «&nbsp;{productName}&nbsp;» inscrite à l'étape «&nbsp;Produit&nbsp;».",
  all_drug: 'médicament',
  all_theActiveIngredient: "l'ingrédient actif",
  declarations_addDeclaration_productForm_sourceHelpTooltip_text: `
  <p>Le nombre d'animaux pourrait figurer dans l'une des sources d'informations suivantes : </p>
  <ul>
    <li>Registre client (nombre d'animaux entrés dans le lot dans le cas d'élevage «&nbsp;tout-plein tout-vide&nbsp;» dont vous suivez les performances zootechniques);</li>
    <li>Prescription vétérinaire;</li>
    <li>Autre (à préciser).</li>
  </ul>
  `,
  dashboard_statistics_permitsMonthlyCountBreakdown_permitCode: 'Catégorie de permis - {code}',
  dashboard_statistics_permitsMonthlyCountBreakdown_all: 'Tous les permis',
  dashboard_PermitStatisticFilterForm_period_label: 'Période',
  dashboard_PermitStatisticFilterForm_period_placeholder: 'Choisir une période',
  dashboard_statistics_permitsMonthlyCountBreakdown_registeredPermits: 'Permis inscrits',
  dashboard_statistics_permitsMonthlyCountBreakdown_totalPermits: 'Total',
  declarations_addDeclaration_productForm_sitePermitNumber_warning_text_line1:
    "Vous déclarez la vente ou la fourniture d'un prémélange médicamenteux à {clientName} au site suivant : ",
  declarations_addDeclaration_productForm_sitePermitNumber_warning_text_line2_3_4: `
    <br /><br />
    Dans le cadre d'une vente ou d'une fourniture de prémélange médicamenteux, le client doit obligatoirement avoir un permis relatif aux prémélanges médicamenteux et aliments médicamenteux spécifique au site concerné par la vente ou la fourniture.
    <br /><br />
    Vous n'avez pas encore saisi ce numéro de permis.
  `,
  declarations_addDeclaration_productForm_sitePermitNumber_label:
    'Nᴼ de permis relatif aux prémélanges médicamenteux et aliments médicamenteux du site',
  declarations_addDeclaration_productForm_sitePermitNumber_divider_label:
    'Numéro de permis du site manquant',
  declaration_addNewDeclaration_transmission_errorFound:
    "Vous avez une ou plusieurs déclarations incomplètes, cliquez sur l'îcone {icon} pour plus de détails",
  declaration_addNewDeclaration_transmission_errorsDetails: `
    Votre déclaration ne peut pas être transmise tant qu'elle sera incomplète. Veuillez corriger :
    {list}
    Votre déclaration est sauvegardée dans les déclarations en attente si vous devez la compléter ultérieurement.
    <br />
    Afin de vous conformer au Règlement, vous êtes tenu de transmettre vos déclarations de ventes et de fournitures au plus tard le 31 mars {transmissionLimitYear}.
  `,
  sitePermitNumber: 'N° de permis',
  siteNumber: 'N° de site',
  vetLicenseNumber: 'N° de license vétérinaire',
  excelDownload_btnLabel: 'Exporter en Excel',
  excelDownloadDetail_btnLabel: 'Exporter les détails en Excel',
  permitType_P: "Préparation d'un aliment médicamenteux",
  permitType_M: "Préparation d'un prémélange médicamenteux ou d'un aliment médicamenteux",
  permitType_S: "Vente ou fourniture d'un prémélange médicamenteux ou d'un aliment médicamenteux",
  permitType_V:
    "Vente, fourniture et préparation d'un prémélange médicamenteux ou d'un aliment médicamenteux",
  excelDownload_currentPage_btnLabel: 'La page actuelle de la liste',
  excelDownload_allPage_btnLabel: 'Toutes les pages de la liste (≈15 000 résultats maximum)',
  excelDownload_allData_btnLabel: 'Les données complètes (CSV)',
  excelDownload_notification_title: 'Exportation exécutée avec succès',
  excelDownload_notification_message: `L'exportation est terminée`,
  excelDownload_notification_inProgress: `L'exportation est en cours`,
  declaration_addNewDeclaration_transmission_errors_title: 'Déclaration incomplète',
  errors_notFound_title: 'Vous avez trouvé une place secrète!',
  errors_notFound_description: "Malheureusement, la page que vous cherchez n'existe pas.",
  errors_notFound_link: "Retour à l'accueil",
  accountActivation_title: 'Activation de votre compte',
  accountActivation_instructions:
    'Veuillez saisir un mot de passe pour vous connecter sur AntibiotiQC',
  accountActivation_passwordLabel: 'Mot de passe',
  accountActivation_passwordRepeat_label: 'Confirmation du mot de passe',
  accountActivation_attempt: 'Activation en cours',
  accountActivation_success: 'Votre compte a été activé avec succès',
  accountActivation_failed: 'Activation échouée',
  userProfile_section_title: 'Détails du profil',
  userProfile_section_fullName: `Nom de l'utilisateur`,
  userProfile_section_userName: `Courriel de l'utilisateur`,
  userProfile_section_resetPassword: 'Demande de modification de mot de passe',
  userProfile_section_send: 'Envoyer',
  menu_menuItem_declarationModels_label: 'Modèles',
  declarationAdmin_declarationTemplateTable_title: 'Modèles de déclaration',
  declarationAdmin_declarationTemplateTable_name_column: 'Nom',
  declarationAdmin_declarationTemplateTable_description_column: 'Description',
  declarationAdmin_declarationTemplateTable_permitNumber_column: 'Permis du déclarant',
  declarationAdmin_declarationTemplateTable_createdAt_column: 'Transmission',
  declarationAdmin_declarationTemplateTable_declarationDate_column: 'Transaction',
  declarationAdmin_declarationTemplateTable_recipientName_column: 'Client',
  declarationAdmin_declarationTemplateTable_productionSite_column: 'Site de production',
  declarationAdmin_declarationTemplateTable_product_column: 'Produit',
  declarationAdmin_declarationTemplateTable_siteName_column: 'Nom du site',
  declarationAdmin_declarationTemplateTable_providerNumber_column: 'Nᴼ de référence interne',
  declarationAdmin_declarationTemplateTable_actions_column: 'Actions',
  declaration_createDeclarationTemplateForm_textInput_name_label: 'Nom du modèle',
  declaration_createDeclarationTemplateForm_textInput_name_placeholder:
    'Entrer le nom du modèle de déclaration',
  declaration_createDeclarationTemplateForm_textInput_description_label: 'Description',
  declaration_createDeclarationTemplateForm_textInput_description_placeholder:
    'Entrer la description du modèle de déclaration',
  declaration_createDeclarationTemplateForm_saveButton_label: 'Sauvegarder',
  declaration_createDeclarationTemplateForm_cancelButton_label: 'Annuler',
  declaration_createDeclarationListView_createTemplateDeclarationModal_title:
    'Créer un modèle de déclaration',
  all_systemMessage: 'Message du système',
  declaration_createDeclarationListView_createTemplateDeclarationModal_successNotification:
    'Le modèle de déclaration a été créé avec succès.',
  declaration_declarationCreateView_createDeclaration_success:
    'La déclaration a été créée avec succès',
  declaration_declarationCreateView_createDeclaration_failed:
    'La création de la déclaration a échoué',
  declaration_declarationCreateView_title_createDeclaration: 'Ajouter une déclaration',
  // Révision linguistique 2023-09-05 FIN
  all_yes: 'Oui',
  all_no: 'Non',
  excel: {
    declaration: {
      id: 'Nᴼ',
      declarationDate: 'Transaction',
      declarationPermit_permitNumber: 'Permis du déclarant',
      declarationType: 'Type de déclaration',
      sitePermitNumber: 'Permis du client',
      recipientName: 'Client',
      siteNumber: 'N° de traçabilité du site',
      siteName: 'Nom du site',
      product_nameFr: 'Produit',
      productQty: 'Quantité (kg) du produit',
      productionStage_nameFr: 'Stade de production',
      productionStageOtherInfo: 'Précision sur le stade de production',
      animalQtySource: `Source du nombre d'animaux`,
      animalQtySourceOtherInfo: "Précision sur la source du nombre d'animaux",
      animalQty: `Nombre d'animaux`,
      animalAgeMinLimit: 'Âge minimal',
      animalAgeMaxLimit: 'Âge maximal',
      prescriptionDate: `Émission de la prescription`,
      vetLicenseNumber: `Permis du médecin vétérinaire`,
      vetName: 'Médecin vétérinaire',
      recipientCoordinate_address_addressFirst: 'Adresse du client',
      recipientCoordinate_address_addressSecond: 'Adresse 2 du client',
      recipientCoordinate_address_unitNumber: `Numéro d'unité du client`,
      recipientCoordinate_address_civicNumber: 'Numéro de rue du client',
      recipientCoordinate_address_streetName: 'Nom de rue du client',
      recipientCoordinate_address_ruralRoute: 'Route rurale du client',
      recipientCoordinate_address_poBox: 'Boîte postale du client',
      recipientCoordinate_address_stationInformation: 'Information sur la station du client',
      recipientCoordinate_city: 'Ville du client',
      recipientCoordinate_postalCode: 'Code postal du client',
      recipientCoordinate_countrySubdivision_nameFr: 'Province du client',
      siteCoordinate_address_addressFirst: 'Adresse du site de production',
      siteCoordinate_address_addressSecond: 'Adresse 2 du site de production',
      siteCoordinate_address_unitNumber: `Numéro d'unité du site de production`,
      siteCoordinate_address_civicNumber: 'Numéro de rue du site de production',
      siteCoordinate_address_streetName: 'Nom de rue du site de production',
      siteCoordinate_address_ruralRoute: 'Route rurale du site de production',
      siteCoordinate_address_poBox: 'Boîte postale du site de production',
      siteCoordinate_address_stationInformation: 'Information sur la station du site de production',
      siteCoordinate_city: 'Ville du site de production',
      siteCoordinate_postalCode: 'Code postal du site de production',
      siteCoordinate_countrySubdivision_nameFr: 'Province du site de production',
      providerNumber: 'Référence interne',
      providerNote: 'Note interne',
      createdWebForm: 'Mode de transmission',
      createdAt: 'Transmission',
      declarationIngredient_activeIngredient_nameFr: `Ingrédient actif #{increment}`,
      declarationIngredient_qty: `Quantité (kg) de l'ingrédient actif #{increment}`,
      declarationIngredient_concentration: `Concentration de l'ingrédient actif #{increment}`,
      declarationIngredient_concentrationUnit: `Unité de mesure de l'ingrédient actif #{increment}`,
      declarationMedication_medication_nameFr: `Médicament commercial #{increment}`,
      declarationMedication_qty: `Quantité (kg)  du médicament commercial #{increment}`,
      declarationMedication_concentration: `Concentration du médicament commercial #{increment}`,
      declarationMedication_concentrationUnit: `Unité de mesure du médicament commercial #{increment}`,
      declarationIngredient_activeIngredient_nameFr_mapaq: `ingredient_actif_name_fr_{increment}`,
      declarationIngredient_qty_mapaq: `declaration_ingredient_qty_{increment}`,
      declarationIngredient_concentration_mapaq: `declaration_ingredient_concentration_{increment}`,
      declarationIngredient_concentrationUnit_mapaq: `declaration_ingredient_concentration_unit_{increment}`,
      declarationMedication_medication_nameFr_mapaq: `medication_name_fr_{increment}`,
      declarationMedication_qty_mapaq: `declaration_medication_qty_{increment}`,
      declarationMedication_concentration_mapaq: `declaration_medication_concentration_{increment}`,
      declarationMedication_concentrationUnit_mapaq: `declaration_medication_concentration_unit_{increment}`,
    },
    productType: {
      code: 'Code',
      nameFr: 'Description',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
    },
    permit: {
      siteNumber: 'N° de traçabilité du site',
      operatorNumber: `N° de l'exploitant`,
      entityNumber: `N° de l'entité`,
      permitNumber: `N° de permis`,
      permitYear: `Année du permis`,
      issueDate: `Date de délivrance MAPAQ`,
      expiryDate: `Date d'expiration MAPAQ`,
      expiryDateLong: `Date d'expiration du permis`,
      permitStatus: `Statut du permis MAPAQ`,
      operatorEmail: `Courriel de l'exploitant`,
      operatorName: `Nom de l'exploitant`,
      operatorContact: `Contact de l'exploitant`,
      entitySocialReason: `Raison sociale de l'entité`,
      entityCommonName: `Appellation commune`,
      entityUnitNumber: `N° d'appartement de l'entité`,
      entityCivicNumber: `N° civique de l'entité`,
      entityFractionNumber: `N° de fraction de l'entité`,
      entityStreetName: `Nom de rue de l'entité`,
      entityCityNumber: `N° de municipalité de l'entité`,
      entityCityName: `Nom de la municipalité de l'entité`,
      entityPostalCode: `Code postal de l'entité`,
      entityManager: `Responsable de l'entité`,
      entityManagerPhoneNumber: `N° téléphone du responsable entité`,
      entityManagerPhoneExtension: `N° de poste du responsable entité`,
      inspectionSection: `Secteur d'inspection`,
      entityType: `Type d'entité`,
      entityLongitude: `Longitude`,
      entityLatitude: `Latitude`,
      directionNumber: `N° de la direction en charge`,
      directionName: `Nom de la direction en charge`,
      regionAdministrativeNumber: `N° de l'administration régionale`,
      regionAdministrativeDescription: `Description de l'administration régionale`,
      localAdministrationNumber: `N° de l'administration locale`,
      localAdministrationName: `Nom du bureau d'inspection`,
      mrc: `MRC`,
      operatorUnitNumber: `N° d'appartement de l'exploitant`,
      operatorCivicNumber: `N° civique de l'exploitant`,
      operatorFractionNumber: `N° de fraction de l'exploitant`,
      operatorStreetName: `Nom de rue de l'exploitant`,
      operatorPostalCode: `Code postal de l'exploitant`,
      operatorCityNumber: `N° de municipalité de l'exploitant`,
      operatorCityName: `Municipalité de l'exploitant`,
      operatorPhoneNumber: `N° téléphone de l'exploitant`,
      operatorPhoneExtension: `N° poste téléphonique de l'exploitant`,
      operatorManagerPhoneExtension: `No de poste responsable exploitant`,
      entityPhoneNumber: `No téléphone entité`,
      entityPhoneExtension: `No de poste entité`,
      entitySiteNumber: `N° de site de l'entité`,
      permitType: `Catégorie de permis`,
      permitTypeMapaq: `Catégorie de permis MAPAQ`,
      countrySubdivision_nameFr: `Province`,
      active: `Statut AntibiotiQC`,
      entityStatus: `Statut d'entité`,
      statusDate: `Date du statut`,
      createdAt: `Transmission`,
      unitNumber: "N° d'appartement",
      streetNumber: 'N° civique',
      streetName: 'Rue',
      municipality: 'Municipalité',
      postalCode: 'Code postal',
      permitCodes_code: `Code d'accès #{increment}`,
      permitNumberBak: 'N° de permis BAK',
    },
    medicationType: {
      code: 'Code',
      nameFr: 'Description',
      atcVetCode: 'Code actuel',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
    },
    species: {
      code: 'Code',
      nameFr: 'Description',
      officialSite: 'Numéro de site règlementé',
      canHaveSite: 'Peut avoir un site',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
    },
    productionStage: {
      productCode: `Code du produit #{increment}`,
      productName: `Nom du produit #{increment}`,
    },
    medication: {
      code: 'Code',
      nameFr: 'Nom',
      shortNameFr: 'Nom court',
      ordonnanceQc: 'Ordonnance QC',
      active: 'Status',
      statusDate: 'Date du statut',
      createdAt: 'Créé le',
      medicationActiveIngredients_activeIngredient_code: `Code de l'ingrédient actif #{increment}`,
      medicationActiveIngredients_activeIngredient_nameFr: `Nom de l'ingrédient actif #{increment}`,
      medicationActiveIngredients_concentration: `Concentration de l'ingrédient actif #{increment}`,
      medicationActiveIngredients_unit: `Unité de mesure de l'ingredient actif #{increment}`,
      medicationDins_dinNumber: `DIN #{increment}`,
      medicationDins_startDate: `Date de début du DIN #{increment}`,
      medicationDins_endDate: `Date de fin du DIN #{increment}`,
      ingredientType_name: `Type de médicament des ingrédients actifs`,
      medicationDinsActive_dinNumber: 'Numéro DIN',
    },
    user: {
      status: `Statut de l'inscription`,
      active: `Statut de l'utilisateur`,
    },
    vetDeclaration: {
      createdAt: 'Transmission',
      declarationDate: 'Transaction',
      declarationType: 'Type de déclaration',
      omvqEstablishment_id: 'Établissement de déclaration',
      vetLicenseNumber: `Permis du médecin vétérinaire`,
      vetName: 'Médecin vétérinaire',
      providerNumber: 'Nᴼ de référence interne',
      providerNote: 'Note interne',
    },
  },
  notificationMessage: {
    emptyMessage: 'Pas de nouvelles notifications',
    title_0: 'Nouvelle fonctionnalité : Modèle de déclaration groupé',
    title_1: "Nouvelle fonctionnalité : Duplication d'un groupe de déclaration",
    title_2: 'Nouvelle fonctionnalité : Modification de site',
    message_0:
      'Dans la liste des déclarations, sélectionner les déclarations souhaitées et cliquer sur le bouton “Sauvegarder comme modèle” dans la barre de menu des déclarations. Les modèles sont accessibles dans le sous-menu “Modèles”.',
    message_1:
      'Dans la liste des déclarations, sélectionner les déclarations souhaitées et cliquer sur le bouton “Dupliquer” dans la barre de menu des déclarations.',
    message_2:
      'Fonctionnalité dans le menu de gauche, filtrer les déclarations pour mettre à jour les informations de site du résultat.',
  },
  omvqVeterinarian_subMenuLabel: 'Vétérinaires (OMVQ)',
  omvqVeterinarian_tableLister: 'Vétérinaires',
  omvqVeterinarian_permitNumber: 'Numéro de permis',
  omvqVeterinarian_name: 'Nom',
  omvqVeterinarian_email: 'Courriel',
  omvqVeterinarian_omvqStatus: 'Statut OMVQ',
  omvqVeterinarian_omvqStartDate: 'Date de début',
  omvqVeterinarian_omvqEndDate: 'Date de fin',
  omvqEstablishment_subMenuLabel: 'Établissements (OMVQ)',
  omvqEstablishment_tableLister: 'Établissement vétérinaire',
  omvqEstablishment_name: 'Nom',
  omvqEstablishment_address: 'Adresse',
  omvqEstablishment_phone: 'Téléphone',
  omvqVeterinarian_linkType: 'Lien',
  omvqEstablishmentCode_tableLister: 'Établissements',
  omvqEstablishmentCode_subMenuTitle: 'Établissements',
  omvqEstablishmentCode_messageEditSuccess: "Le code d'accès a été modifié avec succès",
  omvqEstablishmentCode_messageDeleteSuccess: "Le code d'accès a été supprimé avec succès",
  omvqEstablishmentCode_messageDeleteConfirmation:
    "Êtes-vous certain de vouloir supprimer ce code d'accès?",
  omvqEstablishmentCode_messageAddSuccess: "Le code d'accès a été ajouté avec succès",
  omvqEstablishmentCode_labelCode: "Code d'accès",
  omvqEstablishmentCode_labelCodeType: 'Type',
  omvqEstablishmentCode_labelUserAccount: 'Utilisateur',
  omvqEstablishmentCode_codeType_OWNER: 'Propriétaire',
  omvqEstablishmentCode_codeType_VET: 'Locataire',
  omvqEstablishmentCode_codeType_NOT_VET: 'Gestionnaire',
  omvqEstablishmentCode_codeType_API_KEY: "Clé d'API",
  omvqEstablishmentCode_chooseCodeType: 'Choisir le type de code',
  omvqEstablishmentCode_chooseApiKey: "Choisir la clé d'API",
  signupProfile_veterinarian: 'Vétérinaire',
  signupProfile_veterinarianProfile: `Pour l’inscription d’un <b>médecin vétérinaire</b>, le courriel inscrit à l’OMVQ est requis. Pour l’inscription d’un <b>employé d’établissement vétérinaire</b>, autre qu’un médecin vétérinaire, le code d’accès fourni par votre établissement vétérinaire est requis.`,
  signupProfile_permitOwner: 'Détenteur de permis',
  signupProfile_permitOwnerProfile: `Pour l’inscription d’un <b>détenteur de permis</b> relatif aux prémélanges médicamenteux et aliments médicamenteux, le numéro de permis et le code d’accès reçus par la poste ou de la part d’un collègue sont requis.`,
  signupProfile_permitOwnerProfileDev: `Pour l’inscription d’un <b>détenteur de permis</b> relatif aux prémélanges médicamenteux et aliments médicamenteux, le numéro de permis et le code d’accès est requis.`,
  signupProfile_registerBtn: "S'inscrire",
  signupProfile_whichProfil: 'Quelle description correspond à votre profil?',
  dashboard_statistics_permitsWithTransmissionPercentageView_title:
    'Pourcentage (%) de détenteurs ayant transmis une déclaration',
  dashboard_statistics_permitsWithTransmissionPercentageTable_all: 'Tous les permis',
  statistic_permitsWithTransmittedDeclarationRateView_registeredPermits: "Nombre d'inscrits",
  statistics_apiTransmittedDeclarationsRateView_title: 'Mode de transmission',
  statistics_apiTransmittedDeclarationsRateView_description:
    'Présentation des déclarations en fonction de la date de transmission, selon le mode de transmission',
  statistics_apiTransmittedDeclarationsRateView_label_x: 'Date de transmission',
  statistics_acceptedTransmittedDeclarationsIndicator_title: 'Déclarations acceptées',
  statistics_acceptedTransmittedDeclarationsIndicator_generalDescription:
    'Indicateur visant à identifier les DP ayant des potentiels problèmes techniques (faible taux de succès)',
  statistics_acceptedTransmittedDeclarationsIndicator_description:
    'Description des déclarations acceptées',
  statistics_permitsWithTransmittedDeclarationRateView_title: 'Déclarants',
  statistics_permitsWithTransmittedDeclarationRateView_description:
    'Présentation des détenteurs de permis inscrits et ayant transmis au moins une déclaration acceptée',
  statistics_subscribebPermitsRateView_title: 'Inscriptions',
  statistics_subscribebPermitsRateView_description: 'Descriptif des inscriptions',
  statistic_permitsWithTransmittedDeclarationRateView_permitsWithTransmittedDeclaration:
    'Nombre ayant transmis une déclaration',
  statistic_permitsWithTransmittedDeclarationRateView_totalPermits: 'Permis inscrits',
  statistics_percentageTable_acceptedDeclarationRate: 'Nombre de permis',
  statistics_duplicatedDeclarationsRateView_title: 'Déclarations en double',
  statistics_duplicatedDeclarationsRateView_description: 'Description des déclarations en double',
  statistics_duplicatedDeclarationsRateView_duplicatedDeclarationsCount: 'Nombre de doublons',
  statistics_duplicatedDeclarationsRateView_apiTransmitted: 'Par API',
  statistics_duplicatedDeclarationsRateView_nonApiTransmitted: 'Manuellement',
  statistics_duplicatedDeclarationsSummary_permit_number: 'Numéro de permis',
  statistics_duplicatedDeclarationsSummary_sum: 'Nombre de doublons',
  sw_notification_versionUpdate: `Une nouvelle version est disponible!`,
  sw_notification_reload: `Recharger`,
  navbar_system: 'Système',
  navbar_permitHolderSection: 'Détenteurs de permis',
  navbar_veterinarianSection: 'Vétérinaires',
  useTerm_subMenuLabel: `Conditions d'utilisation`,
  useTerm_titleLister: `Conditions d'utilisation`,
  useTerm_startPeriod: 'Date de début',
  useTerm_endPeriod: 'Date de fin',
  useTerm_termType: 'Type',
  useTerm_termType_UTILISATEUR: 'Utilisateur',
  useTerm_descriptionFr: `Conditions d'utilisation FR`,
  useTerm_descriptionEn: `Conditions d'utilisation EN`,
  useTerm_messageActivatedSuccess: `Les conditions d'utilisation ont été activés avec succès`,
  useTerm_messageDeactivatedSuccess: `Les conditions d'utilisation ont été désactivés avec succès`,
  useTerm_messageEditSuccess: `Les conditions d'utilisations ont été modifiées avec succès`,
  useTerm_messageDeleteSuccess: `Les conditions d'utilisations ont été supprimées avec succès`,
  useTerm_read: `Lire les conditions`,
  useTerm_accept_button: 'Accepter',
  useTerm_cancel_button: 'Fermer',
  useTerm_accept_notification: `Vous avez accepté les conditions d'utilisation`,
  useTerm_cancel_notification: `Vous devez accepter les conditions d'utilisation pour vous connecter`,
  regulationDateLimit_subMenuLabel: `Date limite de transmission`,
  regulationDateLimit_titleLister: `Date limite de transmission`,
  regulationDateLimit_titleLister_GLOBAL: `Configuration globale`,
  regulationDateLimit_titleLister_ANNUAL: `Configuration annuelle`,
  regulationDateLimit_titleLister_INDIVIDUAL: `Configuration individuelle`,
  regulationDateLimit_year: `Année de déclaration`,
  regulationDateLimit_month: `Mois limite année suivante`,
  regulationDateLimit_day: `Jour limite année suivante`,
  regulationDateLimit_type: `Type`,
  regulationDateLimit_date: `Date limite`,
  regulationDateLimit_permitNumber: `N° de permis`,
  regulationDateLimit_type_GLOBAL: `Globale`,
  regulationDateLimit_type_ANNUAL: `Annuelle`,
  regulationDateLimit_type_INDIVIDUAL: `Individuelle`,
  regulationDateLimit_titleFormAdd: `Ajouter une date limite de transmission`,
  regulationDateLimit_titleFormEdit: `Modifier une date limite de transmission`,
  regulationDateLimit_messageAddSuccess: `La date limite de transmission a été ajoutée avec succès`,
  regulationDateLimit_messageEditSuccess: `La date limite de transmission a été modifiées avec succès`,
  regulationDateLimit_messageDeleteSuccess: `La date limite de transmission a été supprimées avec succès`,
  statistics_percentageTable_percentage: 'Pourcentage (%)',
  statistics_permitsWithTransmittedDeclarationRateView_yScale_label:
    '% de détenteurs de permis ayant transmis une déclaration',
  statistics_permitsWithTransmittedDeclarationRateView_xScale_label: `Date d'inscription`,
  statistics_subscribedPermitsRateFiltersForm_period_label: 'Période',
  statistics_subscribedPermitsRateFiltersForm_period_placeholder:
    'Choisissez une date de début et une date de fin',
  statistics_permitsWithTransmittedDeclarationRateView_percentage:
    '% de détenteurs de permis ayant transmis une déclaration',
  statistics_filtersForm_select_permitTypes_label: 'Catégorie de permis',
  statistics_subscribedPermitsRateLine_label: `% de détenteurs de permis inscrits`,
  statistics_subscribedPermitsRateLine_label_x: `Date d'inscription`,
  statistics_filtersForm_select_permitTypes_placeholder: 'Choisissez la catégorie de permis',
  statistics_filtersForm_datePicker_startDate_label: 'Date de début',
  statistics_filtersForm_datePicker_startDate_placeholder: 'Choissisez la date de début',
  statistics_filtersForm_datePicker_endDate_label: 'Date de fin',
  statistics_filtersForm_datePicker_endDate_placeholder: 'Choissisez la date de fin',
  statistics_filtersForm_datePicker_startAt_label: 'Date de début',
  statistics_filtersForm_datePicker_startAt_placeholder: 'Choissisez la date de début',
  statistics_filtersForm_datePicker_endAt_label: 'Date de fin',
  statistics_filtersForm_datePicker_endAt_placeholder: 'Choissisez la date de fin',
  statistics_noDataAvailableMessage_text: 'Pas de données disponibles',
  statistics_percentageTable_total: 'Total',
  statistic_subscribedPermitsRateLine_registeredPermits: "Nombre d'incrits",
  statistic_subscribedPermitsRateLine_allPermits: 'Nombre total',
  statistic_subscribedPermitsRateLine_registeredPermitsTypeS: 'Permis S inscrits',
  statistic_subscribedPermitsRateLine_permitsTypeS: 'Permis S total',
  statistic_subscribedPermitsRateLine_registeredPermitsTypeP: 'Permis P inscrits',
  statistic_subscribedPermitsRateLine_permitsTypeP: 'Permis S total',
  statistic_subscribedPermitsRateLine_registeredPermitsTypeV: 'Permis V inscrits',
  statistic_subscribedPermitsRateLine_permitsTypeV: 'Permis V total',
  statistic_subscribedPermitsRateLine_registeredPermitsTypeM: 'Permis M inscrits',
  statistic_subscribedPermitsRateLine_permitsTypeM: 'Permis M total',
  statistics_apiTransmittedDeclarationsRateView_percentage: '% du total',
  useTerm_titleFormAdd: `Ajouter un conditions d'utilisation`,
  useTerm_titleFormEdit: `Modifier un conditions d'utilisation`,
  useTerm_messageAddSuccess: `Les conditions d'utilisations ont été ajoutées avec succès`,
  all_name: 'Nom',
  all_address: 'Adresse',
  all_city: 'Ville',
  all_province: 'Province',
  all_country: 'Pays',
  all_postalCode: 'Code postal',
  all_phone: 'Numéro de téléphone',
  all_active: 'Actif',
  btn_edit_toolTip: 'Modifier',
  btn_delete_toolTip: 'Supprimer',
  btn_play_toolTip: 'Créer une nouvelle déclaration',
  btn_help: 'Aide',
  statistic_apiTransmittedDeclarationsRateView_nonApiTransmittedDeclarations:
    'Nombre de déclarations via formulaire Web',
  statistic_apiTransmittedDeclarationsRateView_apiTransmittedDeclarations:
    'Nombre de déclarations via API',
  statistic_apiTransmittedDeclarationsRateView_apiTransmittedDeclarations_percentage:
    '% de déclarations via API',
  statistic_apiTransmittedDeclarationsRateView_nonApiTransmittedDeclarations_percentage:
    '% de déclarations via formulaire Web',
  statistics_premixNoPermitDeclarationsIndicator_title: 'Déclarations de prémélanges médicamenteux',
  statistics_premixNoPermitDeclarationsIndicator_description:
    'Description des déclarations de prémélanges médicamenteux',
  statistic_premixNoPermitDeclarationsIndicator_premixNoPermitDeclarations:
    'Déclarations de prémélange médicamenteux sans permis du site de production',
  statistic_premixNoPermitDeclarationsIndicator_premixWithPermitDeclarations:
    'Déclarations de prémélange médicamenteux avec permis du site de production',
  statistics_onTimeSubmittedDeclarationsRateView_title:
    'Nombre de déclarations acceptées transmises à temps',
  statistics_onTimeSubmittedDeclarationsRateView_description:
    'Description de nombre de déclarations acceptées transmises à temps',
  statistic_onTimeSubmittedDeclarationsRateView_onTimeSubmittedDeclarations:
    'Déclaration acceptées à temps',
  statistic_onTimeSubmittedDeclarationsRateView_notOnTimeSubmittedDeclarations:
    'Déclaration acceptées en retard',
  statistics_declarationTransmissionRateView_title: 'Déclarations transmises à temps',
  statistics_declarationTransmissionRateView_description: `Présentation de l'année de registre des déclarations en fonction de la date de transmission`,
  statistics_declarationTransmissionRateView_label_x: 'Date de transmission',
  statistics_declarationTransmissionRateView_tooltip_allDeclarations: 'Nombre total en {year}',
  statistics_declarationTransmissionRateView_tooltip_percentage: '% de {year}',
  all_total: 'Total',
  statistics_filtersForm_select_source_label: 'Mode de création',
  statistics_filtersForm_select_source_placeholder: 'Choisissez la source',
  statistics_acceptedTransmittedDeclarationsIndicator_apiTransmitted: 'Par API',
  statistics_acceptedTransmittedDeclarationsIndicator_nonApiTransmitted: 'Manuellement',
  statistic_declarationsWithPremixInvalidRateView_declarationsWithPremixInvalid:
    'Déclarations de prémélange médicamenteux avec permis invalide',
  statistic_declarationsWithPremixInvalidRateView_declarationsWithPremixValid:
    'Déclarations de prémélange médicamenteux avec permis valide',
  statistics_declarationsWithPremixInvalidRateView_title:
    'Nombre de déclarations de prémélanges médicamenteux avec permis invalide',
  statistics_declarationsWithProductionSiteRateView_title:
    'Numéro de traçabilité du site de production',
  statistics_declarationsWithProductionSiteRateView_description:
    'Description de numéro de traçabilité du site de production',
  all_percentage: 'Pourcentage (%)',
  statistic_declarationsWithProductionSiteRateView_declarationsWithSiteNumber:
    'Nombre de déclaration avec un numéro de site',
  statistic_declarationsWithProductionSiteRateView_declarationsWithoutSiteNumber:
    'Nombre de déclaration sans numéro de site',
  statistic_declarationsWithProductionSiteRateView_allDeclarationsCount:
    'Nombre de déclaration totale',
  statistics_filtersForm_select_canHaveSite_label: 'Peut avoir un site?',
  statistics_declarationsWithProductionSiteRateView_canHaveSite_yes: 'Oui',
  statistics_declarationsWithProductionSiteRateView_canHaveSite_no: 'Non',
  statistics_filtersForm_select_canHaveSite_placeholder: "Choisissez s'il peut y avoir un site",
  productionStage_productionStageTable_precision_label: 'Précision obligatoire autre',
  all_siteNumber: 'N° de site',
  all_permitNumber: 'N° de permis',
  signup_i_have_a_access_code: "J'ai un code de gestionnaire d'établissement",
  signup_omvq_establishment_code_activation_code_label: "Code de gestionnaire d'établissement",
  signup_omvq_establishment_code_activation_code_placeholder:
    "Votre code de gestionnaire d'établissement",
  signup_i_don_t_have_a_access_code: "Je n'ai pas de code de gestionnaire d'établissement",
  userAccountVet_validation_notValidCode: 'Ce code est invalide',
  statistic_acceptedTransmittedDeclarationsIndicator_acceptedDeclarationRate: 'Nombre de permis',
  statistic_yScaleLabel_percentage: 'Pourcentage (%)',
  statistic_yScaleLabel_number: 'Nombre de déclarations',
  systemBase_showNotification_accessDenied: 'Vous ne pouvez pas exécuter cette action',
  home_section_dataTransfer_title: 'Transmission des données',
  home_section_training_title: 'Outils de formation',
  home_section_training_content: `Tous les guides et tutoriels visant à faciliter l’utilisation de la plateforme AntibiotiQC sont disponibles sur le <a target="_blank" rel="noreferrer" href="https://attestra.com/solutions-technologiques/monitorage-des-antibiotiques/guides-et-tutoriels/"><b>site Web d'Attestra</b></a>.`,
  home_section_financingHelp_title: "Programme d'aide financière",
  home_section_moreInfo_title: "Pour plus d'information",
  home_section_support_title: 'Soutien aux utilisateurs',
  home_section_staging_title: 'Environnement de test préproduction',
  home_section_staging_content:
    "Vous pouvez contacter le <a href='https://attestra.atlassian.net/servicedesk/customer/portal/4/group/16'>Soutien technique d'Attestra</a> pour obtenir les informations nécessaires à la création d'un compte utilisateur dans l'environnement de test préproduction. Cet environnement vous permet de réaliser des tests en prenant soin d’indiquer des données dépersonnalisées (ex. : noms de clients fictifs). <br/><br/>Prendre note que cet environnement peut être temporairement inaccessible, par exemple, lors du déploiement de nouvelles fonctionnalités. De plus, lors de périodes d’amélioration, la plateforme dans l’environnement de test préproduction peut présenter des différences mineures par rapport à la plateforme en production.",
  home_section_staging_content_prod: 'Cet environnement vous permet de réaliser des tests.',
  home_section_staging_clickHere: 'Pour accéder à l’environnement de test préproduction',
  header_launchDate:
    'Mise en service prévue au début janvier 2024. Environnement de test préproduction disponible.',
  header_otherEnv: ', vos données ne seront pas transférées dans la production.',
  declaration_declarationTemplate_deleteSuccessMessage:
    'Le modèle de déclaration a été supprimé avec succès',
  all_notification_batchDelete:
    '{count} {count,plural,one{élément a été supprimé avec succès}other{éléments ont été supprimés avec succès}}',
  all_notification_batchDeleteFailure:
    'Impossible de supprimer {count} {count,plural,one{élément car il est utilisé}other{éléments car ils sont utilisés}}',
  env_dev: 'Environnement de développement',
  env_staging: 'Environnement de test (préproduction)',
  google_protect_text: 'Ce site est protégé par la',
  google_privacy_policy: 'Politique de confidentialité',
  google_terms_of_service: "Conditions d'utilisation",
  google_protect_and: 'et les',
  google_protect_apply: 'de reCAPTCHA et de Google',
  footer_politique: 'Politique de confidentialité',
  footer_condition: "Conditions d'utilisation",
  foreign_key_messageDeleteFailure: "Impossible de supprimer l'élément, car il est utilisé",
  activeMedicationDins_titleLister: 'DIN',
  activeMedicationDins_dinNumber: `# {dinNumber}`,
  activeMedicationDins_dinNumber_expired: `# {dinNumber} (expiré)`,
  ingredient_type_titleLister: 'Types d’ingrédients',
  signup_signupPage_register_userConsent_MAPAQ_COMMS: `Acceptez-vous que le MAPAQ ou son mandataire Attestra utilisent votre adresse courriel pour vous transmettre de l’information concernant AntibiotiQC ou concernant le projet de monitorage de l’utilisation des antibiotiques en santé animale au Québec?`,
  signup_signupPage_register_userConsent_helpTooltip_MAPAQ_COMMS: `Le MAPAQ ou son mandataire Attestra pourraient utiliser votre adresse courriel pour vous transmette de l’information concernant AntibiotiQC ou concernant le projet de monitorage de l’utilisation des antibiotiques en santé animale. Il est à noter que pour tout ce qui concerne l’application du Règlement sur les prémélanges médicamenteux et les aliments médicamenteux destinés aux animaux (P-42, r.10) et la gestion des permis associés, ce sont les coordonnées fournies dans le dossier d’enregistrement du permis au MAPAQ qui doivent être utilisées pour les communications entre le MAPAQ et le détenteur de permis.`,
  userProfilePage_userConsentSwitch_notification_preference_confirmActive: `L'autorisation a été accordée avec succès`,
  userProfilePage_userConsentSwitch_notification_preference_confirmInactive: `L'autorisation a été retirée avec succès`,
  all_authorization: 'Autorisation',
  serviceUnavailable: 'Le système est inaccessible pour le moment, veuillez réessayer plus tard',
  serviceGenericError:
    'Erreur du système, si vous faites une extraction Excel, il est possible que votre requête soit trop volumineuse, veuillez raffiner votre recherche, sinon, veuillez contacter le support technique.',
  declarations_addDeclaration_siteForm_clearAddress: `Vider l'adresse`,
  declarations_addDeclaration_siteForm_clearAddress_text: `Vous êtes sur le point de vider le formulaire d'adresse. Voulez-vous continuer?`,
  declarations_addDeclaration_siteForm_clearAddress_title: `Vider le formulaire d'adresse`,
  userProfilePage_userConsentSwitch_notification_preference_error:
    "Impossible de changer l'autorisation. Veuillez essayer plus tard.",
  userProfile_authorization_required: `Veuillez choisir entre Oui ou Non pour continuer`,
  statistics_speciesMultiSelect_label: `Espèce`,
  statistics_speciesMultiSelect_placeholder: `Choisissez l'espèce`,
  statistic_declarationsWithProductionSiteRateView_yScaleLabel: `% de déclarations`,
  statistic_declarationsWithProductionSiteRateView_xScale_Label: 'Date de transaction',
  statistic_declarationsWithProductionSiteRateView_declarationsWithSiteNumber_percentage:
    '% de déclaration avec un numéro de site',
  statistic_declarationsWithProductionSiteRateView_declarationsWithSiteNumber_percentage_Species:
    '% de déclaration avec un numéro de site pour l\'espèce "{specie}"',
  statistic_declarationsWithProductionSiteRateView_declarationsWithoutSiteNumber_percentage:
    '% de déclaration sans numéro de site',
  statistic_declarationsWithProductionSiteRateView_declarationsWithoutSiteNumber_percentage_Species:
    '% de déclaration sans numéro de site pour l\'espèce "{specie}"',
  statistic_declarationsWithProductionSiteRateView_declarationsWithSiteNumber_total_Species:
    'Nombre de déclaration avec un numéro de site pour l\'espèce "{specie}"',
  statistic_declarationsWithProductionSiteRateView_declarationsWithoutSiteNumber_total_Species:
    'Nombre de déclaration sans numéro de site pour l\'espèce "{specie}"',
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithValidPermitCount_percentage: `% permis valide`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithInvalidPermitCount_percentage: `% numéro de permis erroné`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithExpiredPermitCount_percentage: `% permis expiré`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithMissingPermitCount_percentage: `% numéro de permis manquant`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithDeclarationPermitSameAsSitePermitCount_percentage: `% de permis identique au déclarant`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithValidPermitCount: `Nb permis valide`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithInvalidPermitCount: `Nb numéro de permis erroné`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithExpiredPermitCount: `Nb permis expiré`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithMissingPermitCount: `Nb numéro de permis manquant`,
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixWithDeclarationPermitSameAsSitePermitCount:
    'Nb de permis identique au déclarant',
  statistic_premixNoPermitDeclarationsIndicator_declarationsPremixCount: 'Total',
  statistic_premixNoPermitDeclarationsIndicator_declarationPercentage: '% de déclarations',
  delete_fk_409_error_msg: 'Action impossible, la ressource est utilisée dans le système',
  invalidPermitsDetailsRateView_permit_number: 'Numéro de permis',
  invalidPermitsDetailsRateView_permit_type: 'Catégorie de permis',
  invalidPermitsDetailsRateView_entity_common_name: 'Nom abrégé de l’exploitant',
  invalidPermitsDetailsRateView_entity_social_reason: 'Raison sociale',
  invalidPermitsDetailsRateView_nbr_success: 'Nombre de succès',
  invalidPermitsDetailsRateView_nbr_error: "Nombre d'erreur",
  invalidPermitsDetailsRateView_pourcentage_success: '% de succès',
  invalidPermitsDetailsRateView_transmission_mode: 'Mode de transmission',
  transmission_mode_nonApi: 'Manuellement',
  transmission_mode_api: 'Par API',
  vetDeclaration_titleLister: `Déclarations vétérinaire`,
  vetDeclaration_labelDeclarationDate: 'Transaction',
  vetDeclaration_labelDeclarationCreatedAt: 'Transmission',
  vetDeclaration_labelDeclarationType: 'Type de transaction',
  vetDeclaration_labelDeclarationType_SALE_SUPPLY: 'Vente ou fourniture',
  vetDeclaration_labelDeclarationType_RETURN: 'Retour',
  vetDeclaration_omvqEstablishment: 'Établissement de déclaration',
  vetDeclaration_prescriber: 'Prescripteur',
  declaration_productionSiteFormFilter_siteNumber: 'Numéro de site',
  declaration_productionSiteFormFilter_siteName: 'Nom du site',
  declaration_productionSiteFormFilter_address: 'Adresse',
  declaration_productionSiteFormFilter_city: 'Ville',
  declaration_productionSiteFormFilter_postalCode: 'Code postal',
  declaration_productionSiteFormFilter_country: 'Pays',
  declaration_productionSiteFormFilter_countrySubdivision: 'Province/État',
  declarationSiteUpdateTitleLister: 'Modification de site',
  declaration_declarationSiteUpdateListView_siteUpdateModal_title: 'Modification de site',
  declaration_declarationSiteUpdateListView_siteUpdateModal_description: `Vous pouvez mettre à jour les informations suivantes pour toutes les déclarations concernant un même site de production : le <b>numéro de traçabilité</b>, le <b>numéro de permis</b> ou le <b>nom</b>.<ol><li>Filtrer les déclarations en cliquant sur l’icône entonnoir qui est située à gauche dans chaque colonne.</li><li>Valider les résultats.</li><li>Cliquer sur le bouton “Modifier” pour appliquer la modification souhaitée.</li></ol>`,
  declaration_declarationSiteUpdateListView_siteUpdateModal_successNotification:
    'Modification de site a été modifié avec succès',
  declaration_declarationSiteUpdateListView_siteUpdateBtn: 'Modifier',
  declaration_declarationSiteUpdateListView_siteUpdateForm_siteNumber: 'Nᴼ de traçabilité du site',
  declaration_declarationSiteUpdateListView_siteUpdateForm_sitePermitNumber:
    'Nᴼ de permis relatif aux prémélanges médicamenteux et aliments médicamenteux du site',
  declaration_declarationSiteUpdateListView_siteUpdateForm_siteName: 'Nom du site',
  declaration_declarationSiteUpdateListView_siteUpdateForm_totalCount: `Vous allez modifier {dataCount} {dataCount,plural,one{déclaration}other{déclarations}}`,
  declaration_declarationSiteUpdateListView_siteUpdateForm_siteNumberFormatError:
    'Le format du numéro de site est incorrect',
  declaration_declarationSiteUpdateListView_siteUpdateForm_sitePermitNumberFormatError:
    'Le format du numéro de permis est incorrect',
  declaration_help_body: `<p><strong>Modèle de déclaration groupé</strong></p><p>Vous pouvez créer un modèle de déclaration comprenant plusieurs déclarations.<ol><li>Sélectionner les déclarations souhaitées <strong>d'un même client et d'un même permis du déclarant</strong> en cochant la case située à l'extrémité gauche de chaque déclaration.</li><li>Cliquer sur le bouton “Sauvegarder comme modèle” dans la barre de menu des déclarations.</li><li>Pour utiliser le modèle créé, aller dans le sous-menu “Modèles”, dans la section à gauche de l’écran.</li></ol></p><p><strong>Duplication d'un groupe de déclaration</strong></p><p>Vous pouvez dupliquer plusieurs déclarations ensemble.<ol><li>Sélectionner les déclarations souhaitées <strong>d'un même client et d'un même permis du déclarant</strong> en cochant la case située à l'extrémité gauche de chaque déclaration.</li><li>Cliquer sur le bouton “Dupliquer” dans la barre de menu des déclarations.</li></ol></p>`,
  declarations_saveModel_serverError:
    'Vous ne pouvez pas créer de modèle, les déclarations choisies sont invalides',
  productionStage_vetValidation_listerLabel: 'Validation vétérinaire',
  productionStage_vetValidation_formLabel: 'Validation vétérinaire',
  species_speciesForm_labelVetValidation: 'Validation vétérinaire',
  species_vetValidation_listerLabel: 'Validation vétérinaire',
  declaration_addNewDeclaration_duplicate_transmission_errorsDetails:
    'Votre déclaration n\'a pas été transmise à cause de la présence de doublons. Vous pouvez quand même transmettre votre déclaration en cliquant sur le bouton "Transmettre" ou cliquer sur le lien "Voir la liste des doublons" pour consulter la liste.',
  declaration_addNewDeclaration_duplicate_transmission_errors_title: 'Doublons détectés',
  declaration_addNewDeclaration_duplicate_transmission_button: 'Transmettre',
  declaration_addNewDeclaration_duplicate_list: 'Voir la liste des doublons',
  medication_medicationForm_help_endDate:
    'Date à laquelle le MAPAQ a été informé du retrait du DIN (ex. : Alerte courriel de l’ACIA).',
  statistics_duplicatedDeclarationsRateView_generalDescription:
    'Détection de déclarations potentiellement répétées',
}

export default messages
