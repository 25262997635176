import React, { MouseEvent, useContext } from 'react'
import { Edit, Eye, Trash, ClipboardPlus, PlayerSkipForward } from 'tabler-icons-react'
import { Anchor, Group } from '@mantine/core'
import { useIntlLight } from '../../../../i18n'
import useGlobalModal from '../../../hooks/useGlobalModal'
import { IconTooltip } from '../../IconTooltip'

export const TABLE_NAVIGATE_ACTION = {
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
  UPDATE: 'update',
}

const Action = ({
  isUpdate,
  isView,
  isDelete,
  deleteLabel,
  handlerNavigate,
  row,
  buttons,
}: {
  isUpdate?: boolean
  isView?: boolean
  isDelete: boolean
  deleteLabel?: string | React.ReactElement
  handlerNavigate: any
  row: any
  buttons?: {
    templateify?: {
      onClick: (declarationId: string) => void
    }
    createDeclarationFromTemplate?: {
      onClick: (templateId: string) => void
    }
    createDeclarationFromTemplateLot?: {
      onClick: (templateLotId: string) => void
    }
  }
}) => {
  const { t } = useIntlLight()
  const { open } = useGlobalModal()

  return (
    <Group position="center">
      {isView && (
        <Anchor
          role="button"
          onClick={() =>
            handlerNavigate({ action: TABLE_NAVIGATE_ACTION.VIEW, row: row?.original })
          }
        >
          <Eye size={18} />
        </Anchor>
      )}
      {isUpdate && (
        <Anchor
          role="button"
          onClick={() =>
            handlerNavigate({ action: TABLE_NAVIGATE_ACTION.EDIT, row: row?.original })
          }
        >
          <IconTooltip label={t('btn_edit_toolTip')} withArrow>
            <Edit size={18} />
          </IconTooltip>
        </Anchor>
      )}
      {buttons?.templateify && (
        <Anchor
          role="button"
          onClick={(e: MouseEvent<any>) => {
            e.preventDefault()
            e.stopPropagation()

            buttons?.templateify?.onClick(row?.original?.id)
          }}
        >
          <ClipboardPlus size={18} />
        </Anchor>
      )}
      {buttons?.createDeclarationFromTemplate && (
        <Anchor
          role="button"
          onClick={(e: MouseEvent<any>) => {
            e.preventDefault()
            e.stopPropagation()

            buttons?.createDeclarationFromTemplate?.onClick(row?.original?.id)
          }}
        >
          <IconTooltip label={t('btn_play_toolTip')} withArrow>
            <PlayerSkipForward size={18} />
          </IconTooltip>
        </Anchor>
      )}
      {buttons?.createDeclarationFromTemplateLot && (
        <Anchor
          role="button"
          onClick={(e: MouseEvent<any>) => {
            e.preventDefault()
            e.stopPropagation()

            buttons?.createDeclarationFromTemplateLot?.onClick(row?.original?.id)
          }}
        >
          <IconTooltip label={t('btn_play_toolTip')} withArrow>
            <PlayerSkipForward size={18} />
          </IconTooltip>
        </Anchor>
      )}
      {isDelete && (
        <Anchor
          role="button"
          onClick={(event: any) => {
            event.stopPropagation()
            open?.(deleteLabel || t('deletionGenericMessage'), {
              title: t('deletionGenericMessage'),
              onConfirm: () =>
                handlerNavigate({ action: TABLE_NAVIGATE_ACTION.DELETE, row: row?.original }),
            })
          }}
        >
          <IconTooltip label={t('btn_delete_toolTip')} withArrow>
            <Trash size={18} />
          </IconTooltip>
        </Anchor>
      )}
    </Group>
  )
}

export default Action
