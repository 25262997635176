import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueries, UseQueryOptions } from 'react-query'
import { getApi } from '../utils/request'
import { normalizeMemberI18nFields } from '../utils/normalizeMemberI18nFields'

export interface HydraQueryOptions<D> {
  onSuccess?: (result: { member: D; totalItems: number }) => void
  enabled?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnVarsChange?: boolean
}

export interface HydraQueryParams<D> {
  key?: string
  vars?: Record<string, any>
  options?: HydraQueryOptions<D>
  httpHeaders?: {
    Accept: 'text/csv' | 'text/json'
  }
}

export interface HydraQueriesOptions<D> {
  params: HydraQueryParams<D>
}

interface HydraQueryReturn<D> {
  data: {
    member: D
    totalItems: number
  }[]
  refetch: () => void
  isFetching: boolean
}

interface VarsCache {
  [key: string]: any
}

export function useHydraQueries<D>(
  queryKey: string,
  url: string,
  queriesOptions: HydraQueriesOptions<D>[]
): HydraQueryReturn<D> {
  const [varsCache, setVarsCache] = useState<VarsCache>({})
  const queriesResults = useQueries<UseQueryOptions<D>[]>(
    queriesOptions.map(({ params }) => {
      const { vars, options, httpHeaders, key } = params || {}
      const finalKey = key || queryKey
      return {
        queryKey: [finalKey],
        queryFn: () => {
          return getApi()
            .get(url, {
              params: {
                ...vars,
              },
              headers: {
                ...httpHeaders,
              },
            })
            .then(response => response.data)
            .catch(error => Promise.reject(error))
        },
        ...options,
        onSuccess: (data: any) => {
          const member = data?.['hydra:member'] || data
          const normalizedMember = member ? normalizeMemberI18nFields(member, 'fr') : undefined

          const preparedData = {
            member: normalizedMember as D,
            totalItems: member && !Array.isArray(member) ? 1 : data?.['hydra:totalItems'],
          }

          options?.onSuccess?.(preparedData)
        },
        retry: false,
      }
    })
  )

  const dataResults = queriesResults.map(result => {
    const { data } = result
    const member = (data as any)?.['hydra:member'] || data
    const normalizedMember = member ? normalizeMemberI18nFields(member, 'fr') : undefined
    return {
      member: normalizedMember as D,
      totalItems: member && !Array.isArray(member) ? 1 : (data as any)?.['hydra:totalItems'],
    }
  })

  const refetchAll = () =>
    queriesResults.forEach(({ refetch }) => {
      refetch()
    })

  const isFetching = queriesResults.some(result => result.isFetching)

  useEffect(() => {
    queriesOptions.forEach(({ params }, index) => {
      const { vars, options, key } = params || {}
      const finalKey = key || queryKey
      if (!options?.enabled && !(finalKey in varsCache)) {
        setVarsCache({
          ...varsCache,
          [finalKey]: vars,
        })
        queriesResults[index].refetch()
        return
      }

      if (
        options?.refetchOnVarsChange &&
        JSON.stringify(varsCache?.[finalKey]) !== JSON.stringify(vars)
      ) {
        setVarsCache({
          ...varsCache,
          [finalKey]: vars,
        })
        queriesResults[index].refetch()
      }
    })
  }, [queriesResults, queriesOptions, varsCache, queryKey])

  return {
    isFetching,
    refetch: refetchAll,
    data: dataResults,
  }
}
