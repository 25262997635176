import { IDeclarationTemplateLotRead } from '../types'
import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

export function useGetDeclarationTemplateLotQuery<D extends IDeclarationTemplateLotRead>(
  params?: HydraQueryParams<D>
) {
  const { vars, options, key } = params || {}
  const { id } = vars as { id: string }
  return useHydraQuery<D>(
    key ?? `getDeclarationTemplateLot${id}`,
    `/api/declaration_template_lots/${id}`,
    {
      vars,
      options,
    }
  )
}
