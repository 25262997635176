import { Loader, Stack, Title } from '@mantine/core'
import { useIntlLight } from '../../../i18n'
import useLoaderAbsoluteStyles from './useLoaderAbsoluteStyles'

const LoaderAbsolute = () => {
  const { t } = useIntlLight()
  const { classes } = useLoaderAbsoluteStyles()

  return (
    <div className={classes.loader}>
      <Stack align="center">
        <Title order={4}>{t('pageLoaderLabel')}</Title>
        <Loader size="xl" />
      </Stack>
    </div>
  )
}

export default LoaderAbsolute
