export const setToken = (tokenObj: any) => {
  localStorage.setItem('access_token', tokenObj.token)
  localStorage.setItem('refresh_token', tokenObj.refresh_token)
}

export const setMe = (me: any) => {
  localStorage.setItem('me', JSON.stringify(me))
}

export const getMe = () => {
  const value = localStorage.getItem('me')
  return value ? JSON.parse(value) : undefined
}

export const getAccessToken = () => {
  return localStorage.getItem('access_token')
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token')
}

export const isToken = (): boolean => {
  return !!localStorage.getItem('access_token')
}

export const clearToken = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('antibiotique-token-scheme')
  localStorage.removeItem('me')
  localStorage.removeItem('permit')
}

export const setLanguage = (language: string) => {
  localStorage.setItem('language', JSON.stringify(language))
}

export const getLanguage = (): string => {
  const value = localStorage.getItem('language')
  return value ? JSON.parse(value) : 'fr-CA'
}

export const setPermit = (permit: any) => {
  localStorage.setItem('permit', JSON.stringify(permit))
}

export const getPermit = () => {
  const value = localStorage.getItem('permit')
  return value ? JSON.parse(value) : undefined
}

export default { setToken, getAccessToken, getRefreshToken, clearToken, setMe, getMe, isToken }
