import React from 'react'
import { Anchor, Container, Modal, SimpleGrid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { IconCertificate2, IconPrescription } from '@tabler/icons'
import { ProfileCard, ProfileCardProps } from './ProfileCard'
import { useIntlLight } from '../../../i18n'

const SignupProfile = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const { t } = useIntlLight()
  const [disclosure, { open, close }] = useDisclosure(false)

  const profiles: ProfileCardProps[] = [
    {
      icon: IconPrescription,
      label: t('signupProfile_veterinarian'),
      text: t('signupProfile_veterinarianProfile'),
      button:
        process.env.REACT_APP_ENV !== 'prod'
          ? {
              label: t('signupProfile_registerBtn'),
              onClick: () => navigate('/signup/veterinarian'),
            }
          : undefined,
    },
    {
      icon: IconCertificate2,
      label: t('signupProfile_permitOwner'),
      text: t(
        process.env.REACT_APP_ENV !== 'prod'
          ? 'signupProfile_permitOwnerProfileDev'
          : 'signupProfile_permitOwnerProfile'
      ),
      button: {
        label: t('signupProfile_registerBtn'),
        onClick: () => navigate('/signup/permitOwner'),
      },
    },
  ]

  return (
    <>
      <Anchor my="md" onClick={open}>
        {children}
      </Anchor>
      <Modal opened={disclosure} onClose={close} padding="sm" size="xl">
        <Container mt={15} mb={15} size="md">
          <Title order={3} align="center" mb="lg">
            <FormattedMessage id="signupProfile_whichProfil" />
          </Title>
          <SimpleGrid
            cols={profiles.length}
            breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
            spacing={50}
          >
            {profiles.map(p => (
              <ProfileCard key={p.label} {...p} />
            ))}
          </SimpleGrid>
        </Container>
      </Modal>
    </>
  )
}

export default SignupProfile
