import { useIntl } from 'react-intl'

export function useTranslateResource() {
  const intl = useIntl()
  const translate = (resource: Record<any, string>, field: string) => {
    const lang = intl.locale === 'fr-CA' ? 'fr' : 'en'
    const capitalizedLang = lang.charAt(0).toUpperCase() + lang.slice(1)
    return resource[`${field}${capitalizedLang}`]
  }

  return {
    translate,
  }
}
