const DateGmt = {
  toDate: (date?: Date | null): string => {
    return DateGmt.strToDate(date?.toISOString())
  },
  toDatetime: (date?: Date | null): string => {
    return DateGmt.strToDatetime(date?.toISOString())
  },
  strToDate: (dateStr?: string | null): string => {
    if (!dateStr) return ''
    return dateStr?.split('T')[0]
  },
  strToDatetime: (dateStr?: string | null): string => {
    if (!dateStr) return ''
    return dateStr?.split('+')[0].replace('T', ' ')
  },
}

export default DateGmt
