import { createStyles } from '@mantine/core'

function resolveBackgroundColor(isFilterEnabled: boolean, theme: any) {
  if (!isFilterEnabled) {
    return theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'white'
  }

  return undefined
}

function resolveColor(isFilterEnabled: boolean, theme: any) {
  if (!isFilterEnabled) {
    return theme.colorScheme === 'dark' ? 'white' : theme.colors.gray[6]
  }

  return undefined
}

export default createStyles((theme, { isFilterEnabled }: { isFilterEnabled: boolean }) => ({
  filterIconButton: {
    marginRight: 5,
    color: resolveColor(isFilterEnabled, theme),
    backgroundColor: resolveBackgroundColor(isFilterEnabled, theme),
    '&:hover': {
      color: 'white',
    },
  },
}))
