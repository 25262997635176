import { HydraQueryParams, useHydraQuery } from './useHydraQuery'

interface Statistic {
  keys: string[]
  headers: string[][]
  values: number[][]
}

export function useGetStatisticsQuery<D extends Statistic[]>(params?: HydraQueryParams<D>) {
  const { vars, options, key, httpHeaders } = params || {}
  return useHydraQuery<D>('statistics', '/api/statistics', {
    vars,
    options,
    key,
    httpHeaders,
  })
}
